import { Component, OnInit } from '@angular/core';
import { RepeatService } from './repeat.service';

@Component({
    selector: 'app-repeats',
    templateUrl: './repeats.component.html'
})
export class RepeatsComponent implements OnInit {
    items = [];

    constructor(private repeatService: RepeatService) { }

    ngOnInit() {
        this.loadItems();
    }

    private loadItems() {
        this.repeatService.list()
            .subscribe((items) => {
                this.items = items;
            });
    }
}
