import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusPipe } from './order-status.pipe';

@Pipe({
    name: 'orderFilter'
})

export class OrderFilterPipe implements PipeTransform {

    transform(value: any[], term: string): any[] {

        if (typeof value === 'undefined') {
            return [];
        }
        if (!term) {
            return value;
        }
        function isMatch(field: string) {
            return field && field.toString().toLowerCase().indexOf(term) !== -1;
        }
        return value.filter(v => {
            const orderStatusPipe = new OrderStatusPipe();
            const status = orderStatusPipe.transform(v.status);
            return isMatch(v.name) ||
                isMatch(status) ||
                isMatch(v.orderId) ||
                isMatch(v.patientName) ||
                isMatch(v.doctorProposal);
        });

    }
}
