import {Observable, throwError} from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// @ts-ignore
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class OrderService {
  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: any
  ) { }

  getOrderItemsByStatus(
    searchQuery: string,
    status: string[],
    pageNumber: number,
    pageSize: number
  ) {
    let url = `${this.options.apiEndPoint}api/pharmacy/order-items?page=${pageNumber}&size=${pageSize}&sort=creationDate,desc`;

    if (status.length > 0) {
      url += `&status=${status.join()}`;
    }
    if (searchQuery) {
      url += `&q=${searchQuery}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  getPrescription(orderId: number) {
    return this.getDocument(orderId, 'prescription');
  }

  private handleError(error: any) {
    if (error.status === 401) {
      error.message = 'Unauthorized';
    } else {
      error.message = error.error.message;
    }
    return throwError(error);
  }

  getDocument(id: number, type: string) {
    const url = `${this.options.apiEndPoint}api/pharmacy/document/${id}?type=${type}`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  dateSort(o1, o2) {
    return o2 - o1;
  }

  public getAllPendingOrderItems(searchQuery: string, page = 0, size = 10): Observable<any> {
    const status = [
      'WAITING_FOR_PHARMACY_APPROVAL',
      'APPROVED_BY_PHARMACY',
      'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
      'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
      'AWAITING_PHARMACY_DISPENSE',
      'AWAITING_PHARMACY_DISPATCH',
      'AWAITING_PHARMACY_DELIVER',
      'WAITING_FOR_TRACKING_CODE'
    ];
    return this.getOrderItemsByStatus(searchQuery, status, page, size);
  }

  getAllCompletedOrderItems(searchQuery: string, page = 0, size = 10) {
    const status = ['DELIVERED', 'PAYMENT_FAILED'];
    return this.getOrderItemsByStatus(searchQuery, status, page, size);
  }

  getAllOrderItems(searchQuery: string, page = 0, size = 10) {
    const status = [
      'DELIVERED',
      'PAYMENT_FAILED',
      'AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY',
      'REJECTED_BY_PHARMACY',
      'APPROVED_BY_PHARMACY',
      'CANCELED_BY_PHARMACY',
    ];
    return this.getOrderItemsByStatus(searchQuery, status, page, size);
  }

  get isVerification() {
    return localStorage.getItem('is_verification') === String(true);
  }

  set isVerification(value: boolean) {
    if (value == null) {
      localStorage.removeItem('is_verification');
    } else {
      localStorage.setItem('is_verification', String(value));
    }
  }

  proposeOrderItem(id: number, pin: string, name: string, price: number) {
    return this.updateOrderItem(id, {
      pin: pin,
      name: name,
      value: price,
      action: 'price',
    });
  }

  approveOrderItem(
    id: number,
    pin: string,
    name: string,
    medicationCharges: any[]
  ) {
    if (medicationCharges.length > 0) {
      return this.updateOrderItem(id, {
        pin: pin,
        name: name,
        action: 'approve',
        medicationCharges: medicationCharges,
      });
    }
    return this.updateOrderItem(id, {
      pin: pin,
      name: name,
      action: 'approve',
    });
  }

  dispenseOrderItem(id: number, pin: string, name: string) {
    return this.updateOrderItem(id, {
      pin: pin,
      name: name,
      action: 'dispense',
    });
  }

  rejectOrderItem(
    id: number,
    pin: string,
    name: string,
    rejectReason: string,
    rejectReasonDetails: string,
    appointmentPrescriptionJsonString: any
  ) {
    return this.updateOrderItem(id, {
      pharmacyRejectReason: rejectReason,
      pharmacyRejectReasonDetails: rejectReasonDetails,
      appointmentPrescriptionJsonString,
      pin: pin,
      name: name,
      action: 'reject',
    });
  }

  dispatchOrderItem(
    id: number,
    pin: string,
    name: string,
    trackingNumber: string,
    method: string
  ) {
    if (this.isVerification) {
      return this.updateOrderItem(id, {
        pin: pin,
        name: name,
        delivered: true,
        action: 'approve',
      });
    }

    return this.updateOrderItem(id, {
      pin: pin,
      name: name,
      trackingNumber: trackingNumber,
      action: 'dispatch',
      deliveryMethod: method
    });
  }

  deliverOrderItem(
    id: number,
    eventDate: string,
    pin: string,
    name: string,
    trackingNumber: string
  ) {
    return this.updateOrderItem(id, {
      eventDate: eventDate,
      pin: pin,
      name: name,
      trackingNumber: trackingNumber,
      action: 'deliver',
    });
  }

  cancelOrderItem(id: number, pin: string, name: string) {
    return this.updateOrderItem(id, {
      pin: pin,
      name: name,
      action: 'cancel',
    });
  }

  getOrderItem(id: number) {
    const url = `${this.options.apiEndPoint}api/pharmacy/order-item/${id}`;
    return this.http.get<any>(url).pipe(
      map((data: any) => {
        try {
          data.prescribedTreatmentsParsed = JSON.parse(
            data.prescribedTreatments
          );
        } catch (e) { }
        return data;
      })
    );
  }

  downloadPrescription(id: number) {
    const url = `${this.options.apiEndPoint}api/pharmacy/document/${id}`;
    return this.http.get<Blob>(url).pipe(catchError(this.handleError));
  }

  private updateOrderItem(id: number, body: any = null) {
    const url = `${this.options.apiEndPoint}api/pharmacy/order-item/${id}`;
    return this.http.put<any>(url, body).pipe(catchError(this.handleError));
  }

  getDeliveryMethods() {
    const url = `${this.options.apiEndPoint}api/pharmacy/delivery-methods`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
}
