<div *ngIf="!!patient" class="patient-details" [class]="patientDetailsExpanded ? 'expanded' : ''">
  <div class="header">
    <span><b>#{{patient.id}}</b></span>
    <span>|</span>
    <span><b>Name</b>: {{patient.displayName}}</span>
    <span>|</span>
    <span><b>DOB</b>: {{patient.dateOfBirth | date: 'dd/MM/yyyy'}}</span>
    <span *ngIf="!!patient.settings?.NHS_NUMBER">|</span>
    <span *ngIf="!!patient.settings?.NHS_NUMBER">NHS: {{patient.settings?.NHS_NUMBER}}</span>
    <button (click)="patientDetailsExpanded = !patientDetailsExpanded;">
      <img class="handle" src="/assets/vectors/expand.svg" alt="">
    </button>
  </div>

  <div class="content">
    <div>
      <h4><u><b>Personal details</b></u></h4>
      <table>
        <tr>
          <th>Email</th>
          <th>:</th>
          <td>{{patient.email}}</td>
        </tr>
        <tr>
          <th>Phone</th>
          <th>:</th>
          <td>{{patient.phone}}</td>
        </tr>
        <tr>
          <th>DOB</th>
          <th>:</th>
          <td>{{patient.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
        </tr>
        <tr>
          <th>Gender</th>
          <th>:</th>
          <td>{{patient.gender}}</td>
        </tr>
        <tr>
          <th>Address</th>
          <th>:</th>
          <td>
            {{patient?.address?.line1}},
            <br *ngIf="!!patient?.address?.line2"> {{patient?.address?.line2 ? patient?.address?.line2 +',' : ''}}
            <br *ngIf="!!patient?.address?.city">{{patient?.address?.city ? patient?.address?.city +', ' : ''}}
            <br *ngIf="!!patient?.address?.county">{{patient?.address?.county ? patient?.address?.county +', ' : ''}}
            <br *ngIf="!!patient?.address?.postcode">{{patient?.address?.postcode ? patient?.address?.postcode : ''}}
          </td>
        </tr>
      </table>
    </div>
    <div>
      <h4><u><b>Medical details</b></u></h4>
      <table>
        <tr>
          <th>Externally identified</th>
          <th>:</th>
          <td *ngIf="!!patient.settings?.EXTERNALLY_IDENTIFIED_BY">
            Yes <b>({{patient.settings?.EXTERNALLY_IDENTIFIED_BY}})</b>
          </td>
          <td *ngIf="!patient.settings?.EXTERNALLY_IDENTIFIED_BY">No</td>
        </tr>
        <tr>
          <th>SCR Confirmed</th>
          <th>:</th>
          <td *ngIf="!!patient.settings?.SCR_CONFIRMED_BY">Yes <b>({{patient.settings?.SCR_CONFIRMED_BY}})</b></td>
          <td *ngIf="!patient.settings?.SCR_CONFIRMED_BY">No</td>
        </tr>
        <tr>
          <th>NHS verified</th>
          <th>:</th>
          <td>{{patient.nhsVerified ? 'Yes': 'No'}}</td>
        </tr>
        <tr>
          <th>NHS consent given</th>
          <th>:</th>
          <td>{{patient.nhsConsent ? 'Yes': 'No'}}</td>
        </tr>
        <tr>
          <th>GP Practice</th>
          <th>:</th>
          <td>{{patient.preferences.gpPractice}}</td>
        </tr>
        <tr *ngIf="!!GPAddress">
          <th>GP Address</th>
          <th>:</th>
          <td>
            {{GPAddress.line1}},
            <br *ngIf="!!GPAddress.line2"> {{GPAddress.line2 ? GPAddress.line2 +',' : ''}}
            <br *ngIf="!!GPAddress.city">{{GPAddress.city ? GPAddress.city +', ' : ''}}
            <br *ngIf="!!GPAddress.county">{{GPAddress.county ? GPAddress.county +', ' : ''}}
            <br *ngIf="!!GPAddress.postcode">{{GPAddress.postcode ? GPAddress.postcode : ''}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>