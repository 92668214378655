import {
  throwError,
  of,
  BehaviorSubject,
  ReplaySubject,
  Observable,
} from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, catchError, shareReplay } from 'rxjs/operators';

@Injectable()
export class AccountService {
  private _authorised$: BehaviorSubject<boolean>;
  private _info: Observable<any>;

  getDisplayAddress(address: any): string {
    let displayAddress = address.line1;
    if (address.line2 && /\S/.test(address.line2)) {
      displayAddress += ', ' + address.line2;
    }
    if (address.county && /\S/.test(address.county)) {
      displayAddress += ', ' + address.county;
    }
    if (address.city && /\S/.test(address.city)) {
      displayAddress += ', ' + address.city;
    }
    return displayAddress;
  }

  private cleanSpaces(s: string): string {
    return s?.trim().replace(/\s+/, ' ') || '';
  }

  constructor(
    private http: HttpClient,
    private parentRouter: Router,
    @Inject('global_options') private options: any
  ) {
    this._authorised$ = new BehaviorSubject(this.loggedIn());
  }

  get authorised$() {
    return this._authorised$.asObservable();
  }

  get loginType() {
    return localStorage.getItem('loginType') == 'PRESCRIPTION'
      ? 'PRESCRIPTION'
      : 'LOGIN';
  }

  set loginType(type: 'PRESCRIPTION' | 'LOGIN') {
    localStorage.setItem('loginType', type);
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(this.options.apiEndPoint + 'login', {
        username: username,
        password: password,
        type: this.options.portalType,
      })
      .pipe(catchError(this.handleError));
  }

  prescriptionLogin(
    authorizationCode: string,
    patientSurname: string,
    dateOfBirth: string,
    pharmacyId: string,
    pharmacyPin: string
  ) {
    return this.http
      .post<any>(this.options.apiEndPoint + 'prescription/verify', {
        authorizationCode: authorizationCode,
        patientSurname: patientSurname,
        dateOfBirth: dateOfBirth,
        pharmacyId: pharmacyId,
        pharmacyPin: pharmacyPin,
      })
      .pipe(catchError(this.handleError));
  }

  logout() {
    localStorage.removeItem(`${this.options.portalType}.id_token`);
    localStorage.removeItem(`patientData`);
    this._authorised$.next(false);
    this.parentRouter.navigate(['/login']);
  }

  blacklistToken(): Observable<{}> {
    const url = `${this.options.apiEndPoint}logout`;
    return this.http.post<any>(url, {}).pipe(
      map((res) => res || ({} as any)),
      catchError(this.handleError)
    );
  }

  loggedIn() {
    const token = localStorage.getItem('pharmacy.id_token');
    const isLoggedIn = token
      ? !new JwtHelperService().isTokenExpired(token)
      : false;
    return isLoggedIn;
  }

  forgotPassword(email: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<any>(
        `${this.options.apiEndPoint}forget-password`,
        JSON.stringify({
          username: email,
          type: this.options.portalType,
        }),
        { headers }
      )
      .pipe(catchError(this.handleError));
  }

  resetPin(email: string, token: string, pin: string) {
    return this.resetPassword(email, token, pin, 'PIN');
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    type = 'PASSWORD'
  ) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<any>(
        `${this.options.apiEndPoint}reset-password?auth-type=${type}`,
        JSON.stringify({
          username: email,
          token: token,
          newPassword: password,
          confirmNewPassword: password,
          type: this.options.portalType,
        }),
        { headers }
      )
      .pipe(catchError(this.handleError));
  }

  authenticate(token: string) {
    localStorage.setItem(`${this.options.portalType}.id_token`, token);
    this._authorised$.next(true);
  }

  info() {
    if (!this._info) {
      this._info = this.http
        .get<any>(`${this.options.apiEndPoint}info`)
        .pipe(shareReplay(0));
    }
    return this._info;
  }

  public getAddressesByPostcode(postcode: string): Promise<any[]> {
    return this.http
      .get<any>(
        `${this.options.apiEndPoint}api/public/get-address?postcode=${postcode}`
      )
      .pipe(
        map((res) => res || ({} as any)),
        map((result: any) => {
          return (<string[]>result.addresses).map((a: string) => {
            const address = a.split(',');
            const result = {
              line1: this.cleanSpaces(address[0]),
              line2: this.cleanSpaces(
                address[1] + ' ' + address[2] + ' ' + address[3]
              ),
              locality: this.cleanSpaces(address[4]),
              city: this.cleanSpaces(address[5]),
              county: this.cleanSpaces(address[6]),
            };
            result['displayAddress'] = this.getDisplayAddress(result);
            return result;
          });
        }),
        catchError((error: HttpErrorResponse) => [])
      )
      .toPromise();
  }

  public lookupPostcode(partial: string): Observable<string[]> {
    return this.http
      .get<any>(`https://api.postcodes.io/postcodes/${partial}/autocomplete`)
      .pipe(
        map((res) => (res ? res.result || [] : []) as string[]),
        catchError((e: HttpErrorResponse) => {
          console.error(e.error);
          return [];
        })
      );
  }

  public getGpsInfo(postcode: string) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'subscription-key',
      '02768fc86b4b478795b5618b2e26ce77'
    );
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        `https://api.nhs.uk/service-search/search-postcode-or-place?api-version=1&search=${postcode}`,
        {
          filter: "OrganisationTypeID eq 'GPB'",
          select:
            'OrganisationName,OrganisationTypeID,OrganisationID,OrganisationType,Address1,Address2,Address3,City,County,Postcode,Contacts',
          top: 10,
          skip: 0,
          count: true,
        },
        { headers: headers }
      )
      .pipe(
        map((res) => (res ? res.value || [] : []) as any[]),
        catchError(this.handleError)
      );
  }

  private handleError(response: any) {
    return throwError(response);
  }
}
