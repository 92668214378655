
import { throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FormularyService {

  constructor(private http: HttpClient,
              @Inject('global_options') private options: any) {}

  public getFormulary(query, page, size = 10) {
    let url = `${this.options.apiEndPoint}api/${this.options.portalType}/formulary?`;

    if (query) {
      url += `q=${query}`;
    }

    url += `&page=${page}&size=${size}`;
    return this.http.get<any>(url)
    .pipe(catchError(this.handleError));
  }

  public updatePrices(medications) {
    const _medications = [];
    medications.forEach(medication => {
      _medications.push({id: medication.id, unitPrice: medication.unitPrice});
    });
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/update-formulary-unit-prices`;
    const body = {
      medications: medications
    };
    return this.http.put<any>(url, body)
    .pipe(catchError(this.handleError));
  }

  private handleError(response: any) {
    return throwError(response);
  }

}
