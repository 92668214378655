<div class="container login">
	<div  class="central_panel">
		<div class="well well--white">
      <img class="site_logo" src="/assets/img/logo.png" alt="The Treat it" />
			<h1 class="brand-1">Forgot Password</h1>

			<div class="alert alert-danger" *ngIf="errorMessage">
				{{errorMessage}}
			</div>

			<div *ngIf="response">
				<div class="alert alert-success">
					Thank you, an email has been sent with further instructions on how to reset your password
				</div>
				<a class="btn btn-default" routerLink="/"><i class="fa fa-arrow-left"></i> Go back</a>
			</div>

			<form #form="ngForm" autocomplete="off" novalidate [ngClass]="{submitted: form.submitted}" (ngSubmit)="form.valid && submit()"
				*ngIf="!response">
				<div class="form-group">
					<label for="email">Email</label>
					<input id="email" name="email" type="text" class="form-control" [(ngModel)]="email" required />
				</div>

				<a routerLink="/" class="btn btn-default">Back</a>
				<button class="btn btn-primary" style="margin-left: 15px;" type="submit">Submit</button>
			</form>
		</div>
	</div>
</div>
