
import { throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RepeatService {

    constructor(private authHttp: HttpClient,
        @Inject('global_options') private options: any) { }

    list() {
        const url = `${this.options.apiEndPoint}api/pharmacy/repeat-prescriptions`;
        return this.authHttp.get<any>(url)
            .pipe(catchError(this.handleError));
    }

    accept(model: any) {
        const url = `${this.options.apiEndPoint}api/pharmacy/repeat-prescription?action=approve`;
        return this.authHttp.post<any>(url, model)
            .pipe(catchError(this.handleError));
    }

    private handleError(response: any) {
        return throwError(response);
    }
}
