import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from './account.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private accountService: AccountService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.accountService.loggedIn()) {
            return true;
        }
        else if (new JwtHelperService().isTokenExpired((<any>window).localToken) == false) {
            return true;
        } else {
            if (this.accountService.loginType === 'LOGIN') {
                return this.router.navigate(['/login']);
            } else {
                return this.router.navigate(['/prescriptions']);
            }
        }
    }
}
