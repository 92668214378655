<div class="container site_only" *ngIf="order">
    <div class="row order_title">
        <div class="col col-xs-12">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                        <h1 style="margin-top: 0px;">Order: {{order.id}} </h1>
                        <p><strong>Ordered on: {{order.creationDate | date}}</strong></p>
                    </div>
                    <div *ngIf="order.company" style="padding-left: 1em; padding-bottom: 10px;">
                        <img src="{{order.company.logoUrl}}" style="height: 65px;">
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-left: 2em;">
                    <app-order-actions [order]="order" [prescription]="prescription" (orderChanged)="reload()"
                        [patientId]="order.patient.id"></app-order-actions>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col col-md-4" *ngIf="patient && prescription && order">
            <div class="well well--black">
                <h3>{{prescription.pet ? 'Owner': 'Patient'}} Details</h3>
                <p>
                    <strong>Name:</strong> {{patient.displayName}} <br />
                    <strong>DOB:</strong> {{patient.dateOfBirth}} <br />
                </p>
                <p>
                    <strong>Address:</strong>
                    <app-address [address]="patient.address"></app-address>
                </p>
                <p>
                    <strong>Phone:</strong>&nbsp;<a href="tel:{{patient.phone}}">{{patient.phone}}</a><br>
                    <strong>Email:</strong>&nbsp;<a href="mailto:{{patient.email}}">{{patient.email}}</a>
                </p>
            </div>
            <div class="well well--white">
                <h3 class="brand-1">Order log history</h3>
                <p *ngIf="order.pharmacyRejectReason"><strong>Order was rejected by
                        Pharmacy</strong><br />{{order.pharmacyRejectReason}}</p>
                <p *ngIf="order.updateDate"><strong>{{order.updateDate | date}}</strong>
                    <br />
                    <span *ngIf="!!order.doctor">Order was updated by {{order.doctor.displayName}} (GMC Number:
                        {{order.doctor.gmc}})</span>
                </p>
                <p><strong>{{order.creationDate | date}}</strong><br /> Order was generated
                </p>
            </div>
        </div>
        <div class="col col-md-8">
            <div class="alert alert-{{order.status | orderState}}"><strong>{{order.status | orderStatus}}</strong>&nbsp;
                <p *ngIf="order.pharmacyRejectReason">Reason - {{order.pharmacyRejectReason}}</p>
                <p *ngIf="order.status == 'DELIVERED' && !(pharmacy.delivers && pharmacy.virtual)">
                    The electronic Prescription has been mailed to your registered email address.
                    You will need to enter your Store PIN to open the Prescription.
                    <strong>Please ensure that it matches the printed copy used for dispensing purposes before supplying
                        the item(s).</strong>
                </p>
            </div>
            <div class="well well--white">
                <h3 class="brand-1">Prescription</h3>
                <div class="row prescription">
                    <div class="col-md-6" *ngIf="isPharmacyConsultation; else doctorDetails">
                        <div class="well" *ngIf="!!prescribingPharmacy; else noPharmacyDetails">
                            <h4 class="brand-1">Pharmacy details</h4>
                            <strong>{{prescribingPharmacy.displayName}}</strong><br />
                            <app-address [address]="prescribingPharmacy.address"></app-address><br />
                            <strong>Tel:</strong>&nbsp;{{order.company ? order.company.phone : '024 7745 0733'}}<br />
                            <strong>GPhC Number:&nbsp;</strong>{{prescribingPharmacy.gphc}}
                        </div>
                        <ng-template #noPharmacyDetails>
                            <div class="well">
                                <h4 class="brand-1">Pharmacy details</h4>
                                <span>Could not find pharmacy details</span>
                            </div>
                        </ng-template>
                    </div>
                    <ng-template #doctorDetails>
                        <div class="col-md-6">
                            <div class="well" *ngIf="!!doctor; else noDoctorDetails">
                                <h4 class="brand-1">Doctor details</h4>
                                <strong>{{doctor.displayName}}</strong><br />
                                <span
                                    *ngIf="doctor.settings.QUALIFICATIONS">{{doctor.settings.QUALIFICATIONS}}<br /></span>
                                <app-address [address]="doctor.address"></app-address><br />
                                <strong>Tel:</strong>&nbsp;{{order.company ? order.company.phone : '024 7745
                                0733'}}<br />
                                <strong>{{doctor.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST' ? 'GPhC' :
                                    doctor.settings.DOCTOR_TYPE == 'VET' ? 'RCV' :
                                    'GMC'}}&nbsp;Number:&nbsp;</strong>{{doctor.gmc}}
                            </div>
                            <ng-template #noDoctorDetails>
                                <div class="well">
                                    <h4 class="brand-1">Doctor details</h4>
                                    <span>Could not find doctor details</span>
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>
                    <div class="col-md-6">
                        <div class="well">
                            <h4 class="brand-1">Prescribed medication</h4>
                            <div *ngIf="order.type === 'BOOKING' || order.type === 'PRESCRIPTION_ONLY'">
                                <div *ngFor="let treatment of prescription.medication">
                                    <strong>ID</strong> - {{treatment.formulary.id}}<br />
                                    <strong>Name</strong> - {{treatment.formulary.productName}}<br />
                                    <strong>Brand</strong> - {{treatment.formulary.brandName}}<br />
                                    <strong>Route</strong> - {{treatment.formulary.administrationRoute}}<br />
                                    <strong>Profile</strong> - {{treatment.formulary.medicineProfile}}<br />
                                    <strong>Unit Quantity</strong> - {{treatment.formulary.quantity}}<br />
                                    <strong>Quantity</strong> - {{treatment.quantity}}<br /><br />
                                    <strong>Sale Price</strong> - {{treatment.formulary.salePrice}}<br /><br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="pharmacy.virtual && pharmacy.delivers" class="col-md-12">
                        <div class="well well-small">
                            <h4 class="brand-1">Delivery Type</h4>
                            <strong>{{order.deliveryType == 'STANDARD'? 'Standard Delivery' : 'N/A' ||
                                order.deliveryType == 'EXPRESS'? 'Express Delivery - Next Day Delivery' :
                                'N/A'}}</strong>

                            <strong *ngIf="order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER"><br /><br />Tracking Number -
                                {{order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER}}</strong>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="prescription.orderItem.files[0].url">
                        <div class="well well-small">
                            <h4 class="brand-1">Uploaded Prescription</h4>
                            <img [src]="prescription.orderItem.files[0].url" alt="" (click)="prescriptionModal.show()"
                                style="cursor: pointer; width: 100%; height: 100%;">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="well well-small">
                            <h4 class="brand-1">Delivery Note</h4>
                            <div style="margin-top: 20px">Track Prescription: <span class="input-look">{{prescription?.trackingCode ? prescription.trackingCode : 'Pending from clinician'}}</span></div>
                            <div style="margin-top: 5px">Track Delivery to Patient: <span class="input-look">{{order?.workflow?.PHARMACY_ASSIGN_TRACKING_NUMBER ? order.workflow?.PHARMACY_ASSIGN_TRACKING_NUMBER : "Pending delivery"}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="print_only header-block">
    <img alt="The Treat it" [src]="logoUrl" class="site-logo">
    <div class="site-address">
        <address *ngIf="!order?.company">
            The Treat it (UK) Limited, <br />
            Coventry University Technology Park,<br />
            The TechnoCentre, <br />
            Puma Way, <br />
            CV1 2TT Coventry<br />
            Company Number: 09359853 <br />
            Tel: 024 7745 0733 <br />
            Email: Medical@theGPservice.co.uk
        </address>
        <address *ngIf="order?.company">
            {{order.company.name}}<br />
            <ng-container *ngIf="order.company.address.line1">{{order.company.address.line1}}<br /></ng-container>
            <ng-container *ngIf="order.company.address.line2">{{order.company.address.line2}}<br /></ng-container>
            {{order.company.address.city || ''}} {{order.company.address.county || ''}} {{order.company.address.postcode
            || ''}}<br />
            Tel: {{order.company.phone}}<br />
            Email: {{order.company.email}}
        </address>
    </div>
</div>

<div class="print_only" *ngIf="order && prescription" [ngClass]="{watermark: showWatermark}">
    <div class="row" style="margin-bottom: 1em;">
        <div class="col-xs-8">
            <h2 class="brand-1">Private prescription {{prescription.pet ? '(for Pet)': ''}}</h2>
            <h4 class="brand-1">ID: {{prescription.id}}</h4>
            <h5 class="h5" *ngIf="prescription.refNumber">
                <strong>Reference No:&nbsp;</strong>{{ prescription.refNumber }}
            </h5>
            <button (click)="print()" class="pull-right btn btn-default btn-print">Print</button>
        </div>
        <div class="col-xs-4">
            <h2 class="brand-1 text-right">Order: {{order.id}}</h2>
        </div>
    </div>
    <div class="widgets widgets--2" style="margin-bottom: 0.5em;">
        <div class="row">
            <div class="col-xs-{{prescription.pet ? '4' : '6'}} widget widget--white widget--grey_border widget--a_r"
                *ngIf="order.doctor">
                <div class="well well--white" style="height: 14em;">
                    <h1 class="h4 brand-1">Prescriber details</h1>
                    <p><strong>{{order.doctor.displayName}}</strong><br /></p>
                    <span
                        *ngIf="order.doctor.settings.QUALIFICATIONS">{{order.doctor.settings.QUALIFICATIONS}}<br /></span>
                    <span *ngIf="order.doctor.gmc"><strong>{{order.doctor.settings.DOCTOR_TYPE ==
                            'PRESCRIBING_PHARMACIST' ? 'GPhC' : order.doctor.settings.DOCTOR_TYPE == 'VET' ? 'RCV' :
                            'GMC'}}&nbsp;Number:</strong>&nbsp;{{order.doctor.gmc}}<br /></span>
                    <span><strong>Tel:</strong>&nbsp;{{order.company ? order.company.phone : '024 7745 0733'}}</span>
                </div>
            </div>
            <div *ngIf="prescription.pet" class="col-xs-4 widget widget--white widget--grey_border widget--a_r">
                <div class="well well--white" style="height: 14em;">
                    <h2 class="h4 brand-1">Pet details</h2>
                    <p><strong>{{prescription.pet.name}}</strong></p>
                    <strong>Species:</strong>&nbsp;{{prescription.pet.species}}<br />
                    <app-address [address]="prescription.pet.address"></app-address>
                </div>
            </div>
            <div class="col-xs-{{prescription.pet ? '4' : '6'}} widget widget--white widget--grey_border widget--a_r">
                <div class="well well--white" style="height: 14em;">
                    <h2 class="h4 brand-1 ">{{prescription.pet ? 'Owner' : 'Patient'}} details</h2>
                    <p><strong>{{patient.displayName}}</strong></p>
                    <strong>Mobile:</strong>&nbsp;{{patient.phone}}<br />
                    <strong>Date of birth:</strong>&nbsp;{{patient.dateOfBirth | date : 'dd/MM/yyyy'}}
                    <app-address [address]="patient.address"></app-address>
                    <p *ngIf="patient.preferences.homePhone"> Home: {{patient.preferences.homePhone}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="well well--white">
        <h4 class="brand-1">Prescribed medication</h4>
        <div *ngIf="order.type === 'BOOKING' || order.type === 'PRESCRIPTION_ONLY'">
            <table class="table">
                <thead>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Strain</th>
                    <th>Dosage</th>
                    <th>Quantity</th>
                </thead>
                <tbody>
                    <tr *ngFor="let treatment of prescription.medication">
                        <td>{{treatment.formulary.productName}}</td>
                        <td>{{treatment.formulary.brandName}}</td>
                        <td>{{treatment.formulary.strain}}</td>
                        <td>{{treatment.dosage}}</td>
                        <td>{{treatment.quantity}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-warning" *ngIf="medicationsContainUnlicensedUse">
                <p>
                    <strong>Notes for Patient</strong>
                </p>
                <p>
                    This prescription contains medication that is termed 'off-label' or 'off-licensed' for the use
                    prescribed by the doctor. This means, although many people find it helpful for this condition, the
                    effectiveness and possible side effects for this purpose have not been specifically studied by the
                    manufacturer. This treatment does not always work for this condition.
                </p>
            </div>
        </div>
    </div>
    <div class="well well--white">
        <div class="row">
            <div class="col col-md-6">
                <div *ngIf="!!order.doctor" class="form-group">
                    <label>Signature:</label>
                    <img src="{{order.doctor.settings.SIGNATURE_URL}}" width="150" class="signature">
                </div>
            </div>
            <div class="col col-md-6" style="margin-bottom: 0;">
                <div class="form-group">
                    <label>Date:</label>
                    <label>{{prescription.creationDate | date: 'dd/MM/yyyy' }} {{prescription.creationDate| date:
                        'shortTime' }}</label>
                </div>
            </div>
        </div>
    </div>
    <p>For any issues regarding this prescription, please call us on 024 7745 0733</p>
    <div>
        <img src="/assets/img/watermark.png" class="watermark_image" />
    </div>
</div>

<div bsModal #prescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="prescriptionModal.hide()"
                    aria-hidden="true">&times;</button>
                <h3 class="modal-title">Uploaded Prescription</h3>
            </div>
            <div class="modal-body" *ngIf="!!prescription">
                <img [src]="prescription.orderItem.files[0].url" alt="" width="100%" height="100%">
            </div>
        </div>
    </div>
</div>
