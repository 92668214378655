import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuestionnaireService } from './questionnaire.service';
import { map, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-questionnaire-single-item',
  templateUrl: './questionnaire-single-item.component.html'
})
export class QuestionnaireSingleItemComponent implements OnInit {

  individualSets: any;
  currentYear: any;

  constructor(private http: HttpClient,
              public questionnaireService: QuestionnaireService) { }

    getAnnualResults(dataCollectionYear) {
      const initialDate = (dataCollectionYear - 1).toString() + '-04-01T00:00:00Z';
      const finalDate = (dataCollectionYear).toString() + '-03-31T00:00:00Z';

      this.http.get<any>('/assets/questionnaire.json').pipe(
        map((res: any) => {
              this.questionnaireService.questionsjson = res;
              return res;
          }),
          flatMap((data) => this.questionnaireService.getResults(initialDate, finalDate)))
          .subscribe(results => {
              this.questionnaireService.results = results.content;
              if (this.questionnaireService.results.length > 0) {
                this.questionnaireService.pharmacy = this.questionnaireService.results[0].pharmacy;
                this.individualSets = this.questionnaireService.getIndividualSets(this.questionnaireService.results);
              }
          });
  }

  ngOnInit() {
    if (!this.questionnaireService.results) {
      this.currentYear = this.questionnaireService.setCurrentYear();
      this.getAnnualResults(this.currentYear);
    } else {
      this.individualSets = this.questionnaireService.getIndividualSets(this.questionnaireService.results);
      this.questionnaireService.pharmacy = this.questionnaireService.results[0].pharmacy;
    }
  }

}
