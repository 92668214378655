<div class="container">
    <div class="well well--white">
        <div *ngIf="grandtotal!=0">
            <button type="button" class="btn btn-success btn-1" (click)="add2Chart(reportbox.default)">{{ reportbox.button }}</button>
            <a class="btn btn-success btn-1" routerLink="/questionnaire-individual">Individual Surveys</a>
            <button type="button" class="btn btn-success btn-1" (click)="questionnaireService.printReport()">Print</button>
        </div>
        <div class="survey-chart" *ngIf="report">
            <h2>Patient Satisfaction Survey Results <br/><br/> {{ user.name }}</h2>

            <p class="title"><strong *ngIf="reportbox.selectedAnswer==3 || reportbox.selectedAnswer==1" class="big-percentage">
                {{ (reportbox.blockleft[0].value + reportbox.blockleft[1].value)/grandtotal |  percent: '1.0-0' }}</strong>
                <strong *ngIf="reportbox.selectedAnswer==2" class="big-percentage">
                    {{ reportbox.blockleft[0].value/grandtotal |  percent: '1.0-0' }}
                </strong> of our customers <br/>rate our service as <strong>{{ reportbox.blockleft[0].answer }}</strong>
                <span *ngIf="reportbox.blockleft[1].answer && (reportbox.selectedAnswer==3 || reportbox.selectedAnswer==1)" > 
                or <strong>{{ reportbox.blockleft[1].answer }}</strong> </span></p>
                <span style="text-align:center; display:block; margin:0 auto;">on the question<br/><strong>{{reportbox.question}}</strong></span>
            <table>
                <tr>
                    <td width="120px">&nbsp;
                    </td>
                    <td>    
                        <google-chart [title]="chart.title" [width]="chart.width" [height]="chart.height" [type]="chart.chartType" [columns]="chart.columns" [data]="chart.data"></google-chart>
                    </td>
                </tr>
            </table>

        <div class="middle-box">
            <div class=block-left>
                <div *ngIf="reportbox.overallrate>1">
                    <h3>Where did we do best?</h3>
                    <p>Our customers rated our pharmacy <br/><strong>{{ reportbox.wellrated[0].answer }}</strong> in:</p>
                    <ul *ngFor="let item of reportbox.wellrated">
                        <li *ngIf="item.question">{{ item.question }}</li>
                    </ul>
                </div>
                <div *ngIf="reportbox.overallrate==1">
                    <h3>We are improving our service</h3>
                    <p>We are committed to bring a better service to our customers!</p>                    
                </div>
            </div>
            <div class=block-right>
                <h3>Where can we improve?</h3>
                    <div *ngIf="reportbox.overallrate<3">
                        <div *ngFor="let item of reportbox.poolyrated">
                            <p *ngIf="item.question">{{ item.question }}</p>
                            <p *ngIf="item.action"><strong>Action:</strong> {{ item.action }}</p>
                        </div>
                    </div>
                    <div *ngIf="reportbox.overallrate==3">
                        <p>We'll keep working hard to keep our level of service</p>
                    </div>
            </div>
        </div>

            <div class="bottom-box">
                <h3>We continue to strive to improve our service to you.</h3>
                <p>Patient Satisfaction Survey undertaken during <strong>April {{ reportbox.year-1 }}</strong> - <strong>March {{ reportbox.year }}</strong> in a sample of <strong>{{ grandtotal }}</strong> patients visiting <strong>{{ user.name }}</strong>.</p>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr>
                            <th>Age (years)</th>
                            <td>16-19</td>
                            <td>20-24</td>
                            <td>25-34</td>
                            <td>35-44</td>
                            <td>45-54</td>
                            <td>55-64</td>
                            <td>&gt; 65</td>
                        </tr>
                        <tr>
                            <th>Percent</th>
                            <td>{{ reportbox.age1619/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age2024/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age2534/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age3544/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age4554/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age5564/grandtotal | percent: '1.1-2' }}</td>
                            <td>{{ reportbox.age65/grandtotal | percent: '1.1-2' }}</td>
                        </tr>
                    </table>
                </div>
                <p>Male respondents {{ reportbox.male/grandtotal | percent: '1.1-2' }}, Female {{ reportbox.female/grandtotal | percent: '1.1-2' }}</p>
                <p *ngIf="reportbox.patient>0">{{ reportbox.patient/grandtotal | percent: '1.0-0' }} of patients accessing the pharmacy for themselves</p>
            </div>
        </div>

        <div *ngIf="!report">
            <p class="print_only summary_head">{{questionnaireService.pharmacy?.displayName}}</p>
            <h2 class="brand-1">Welcome to Pharmacy Surveys</h2>
            <p>This feature provides the surveys data currently collected, below there is a section for each question and the count of every possible answer</p>
            <p>Minimum number of returned surveys : <strong>{{ minSurveys }}</strong></p>
            <p>Surveys completed : <strong>{{ grandtotal }}</strong></p>
            <p>These results are associated to data collection between: 
                <select class="form_control" #dataCollection >
                    <option *ngFor="let period of periods" value="{{period.value}}">{{period.desc}}</option>
                </select>
                <button type="button" (click)="getAnnualResults(dataCollection.value)" class="btn btn-primary btn-1" [disabled]="isResultsLoading">Get Results</button>
            </p>

            <div *ngIf="grandtotal!=0">
                <div *ngFor="let question of questions" class="questionInfo">
                    <h3 class="brand-1" [ngClass]="{'selection-input': question.type=='selection-input'}">{{ question.question }}</h3>
                    <button type="button" *ngIf="question.chart" class="btn btn-link" (click)="add2Chart(question.key)">[+] use this question for the chart</button>
                    <div *ngIf="question.type=='selection'">
                                <div *ngFor="let answer of question.answers"  class="answer-box">
                                    <div *ngIf="answer.desc" class="answerDesc">{{ answer.desc }}</div>
                                    <div *ngIf="answer.desc" class="answerTotal">{{ answer.total }}</div>
                                    <div *ngIf="answer.desc" class="answerPercentage">{{ (answer.total/grandtotal) | percent: '1.1-2' }}</div>
                                    {{answer.key}}
                                </div>
                                <span *ngIf="question.answers.length > 1">
                                    <div class="answerGTotallabel">Grand Total: </div><div class="answerGTotal">{{ grandtotal }}</div>
                                </span>    
                    </div>
                    <div *ngIf="question.type=='customer-input'||question.type=='selection-input'" class="customer-inputs">                
                        <table class="table table-striped">
                            <tr *ngFor="let answer of question.answers">
                                <td *ngIf="answer">{{ answer }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="grandtotal==0">
                <p class="text-danger">Pharmacy Surveys report cannot find any collected data for this period</p>
            </div>

        </div>

    </div>
</div>

