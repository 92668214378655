import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientComponent implements OnInit {
  @Input() patient: any = {};
  patientDetailsExpanded = false;

  protected isLoading: boolean;

  constructor(protected router: Router) {}

  ngOnInit() {}

  get GPAddress() {
    if (this.patient) {
      return JSON.parse(this.patient.preferences?.gpAddress);
    }
    return undefined;
  }
}
