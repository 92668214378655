import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { UserService } from '../account/user.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    @Inject('global_options') private options: any
  ) {}

  public createOrder(patientId: number) {
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    const payload = {
      pharmacy: null,
      deliveryType: 'STANDARD',
      paymentType: 'PAID_TO_PHARMACY',
      name: `Pharmacy Consultation - ${formattedDate}`,
      prescribingPharmacist: {
        id: this.userService.user.id,
      },
      patient: {
        id: patientId,
      },
      orderNotes: [],
      isRepeatOrder: false,
    };

    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/order`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }

  public updateOrderNote(orderId: number, orderBody: any) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/order?id=${orderId}`;
    return this.http
      .put<any>(url, orderBody)
      .pipe(catchError(this.handleError));
  }

  public completeOrder(orderId: number, body) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/complete-order?id=${orderId}`;
    return this.http.put<any>(url, body).pipe(catchError(this.handleError));
  }

  public getAllOrders(page: number) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/order-items?page=${page}&size=10&sort=creationDate,desc`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  public getOrderItem(orderId: number) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/order-item/${orderId}`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  public uploadPatientKYC(patientId: number, data: FormData) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/patient/document-upload?patientId=${patientId}`;
    return this.http.post<any>(url, data).pipe(catchError(this.handleError));
  }

  private handleError(response: any) {
    return throwError(() => response);
  }

  //Util
  public getOrderNotePayload(orderItem: any) {
    const { prescribingPharmacist, patient, orderNotes } = orderItem;

    return {
      prescribingPharmacist: {
        id: prescribingPharmacist.id,
      },
      patient: {
        id: patient.id,
      },
      orderNotes: orderNotes,
    };
  }
}
