import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormularyService } from './formulary.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-formulary',
  templateUrl: './formulary.component.html'
})
export class FormularyComponent implements OnInit {

  @ViewChild('confirmPricesChangesModal', { static: true }) confirmPricesChangesModal: ModalDirective;
  formulary: any;
  errorMessage: any;
  searchQuery = '';
  currentPage = 0;
  lastPage = 0;
  listOfMedicationsToUpdate = [];

  constructor(private formularyService: FormularyService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.getFormulary(this.currentPage);
  }

  getFormulary(page = 0) {
    this.formularyService.getFormulary(this.searchQuery, page, 10).subscribe(
      (response) => {
        this.handlePagination(response);
        this.setupFormulary(response.content);
      },
      () => {
        this.errorMessage = 'Something went wrong';
      });
  }

  handlePagination(response) {
    this.currentPage = response.number;
    this.lastPage = response.totalPages ? response.totalPages - 1 : 0;
  }

  setupFormulary(formulary) {
    formulary.forEach(element => {
      element.originalPrice = element.unitPrice;
      this.listOfMedicationsToUpdate.forEach(med => {
        if (med.id === element.id) {
          element.toBeUpdated = true;
          element.unitPrice = med.unitPrice;
        }
      });
    });
    this.formulary = formulary;
  }

  search() {
    this.getFormulary();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.getFormulary(page);
  }

  changePrice(medication) {
    medication.changePrice = true;
  }

  resetToOriginalPrice(medication) {
    medication.unitPrice = medication.originalPrice;
    medication.changePrice = false;
  }

  confirmPrice(medication) {
    medication.changePrice = false;
    if (medication.originalPrice !== medication.unitPrice) {
      medication.toBeUpdated = true;
      if (this.listOfMedicationsToUpdate.length === 0 || !this.listOfMedicationsToUpdate.some(med => med.id === medication.id)) {
        this.listOfMedicationsToUpdate.push(medication);
      }
    } else if (this.listOfMedicationsToUpdate.length > 0) {
      this.listOfMedicationsToUpdate = this.listOfMedicationsToUpdate.filter(element => element.id !== medication.id);
      medication.toBeUpdated = false;
    }
  }

  saveChanges() {
    this.confirmPricesChangesModal.show();
  }

  confirmChanges() {
    this.formularyService.updatePrices(this.listOfMedicationsToUpdate).subscribe(() => {
      this.listOfMedicationsToUpdate = [];
      this.search();
      this.confirmPricesChangesModal.hide();
      this.toastr.success('Formulary update', 'Formulary updated successfully');
    }, (error) => {
      this.confirmPricesChangesModal.hide();
      this.toastr.error('Error', error);
    });
  }

  searchForThisMedication(medicationId) {
    this.confirmPricesChangesModal.hide();
    this.searchQuery = medicationId;
    this.getFormulary();
  }

  isNumber(event) {
    const iKeyCode = (event.which) ? event.which : event.keyCode;
    if (iKeyCode !== 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57)) {
        return false;
    }
    return true;
  }
  isNumberOrLetter(event) {
    const pattern = /^[a-z0-9]+$/i;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
