<clinic-layout [title]="'Consultation submitted for MDT & Specialist review'">

    <ul>
        <li>
            <h4>Information About Medical Cannabis</h4>
            <a target="_blank"
                href="https://docs.google.com/document/d/1BFOM2lJcWj50qr3uMQKo2HTuRQH8yu-e/edit#heading=h.gjdgxs">
                Medical Cannabis education post Consultation
            </a>
        </li>
        <li>
            <h4>Treat-it FAQs Leaflet for Pharmacist</h4>
            <a target="_blank" href="https://treat-it.clinic/treat-it-faqs/">
                Treat it FAQs Leaflet
            </a>
        </li>
    </ul>

    <div style="display: flex; justify-content: center; margin-top: 5rem;">
        <button class="btn btn-primary" routerLink="/clinic/consultation/{{orderId}}/view">
            View Consultation
        </button>
    </div>
</clinic-layout>