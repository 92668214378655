<header class="site_head" *ngIf="user">
    <nav class="navbar navbar-default">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" (click)="isCollapsed = !isCollapsed" data-target="#bs-example-navbar-collapse-1"
                    aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" routerLink=""><img src="/assets/img/logo.png" alt="The Treat it" /></a>
            </div>

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" [collapse]="isCollapsed">
                <ul class="nav navbar-nav">
                    <li *ngIf="deliveringPharmacy"><a routerLink="orders" routerLinkActive="active">Prescriptions</a></li>
                    <li *ngIf="!thisIsCoop && deliveringPharmacy">
                        <a routerLink="questionnaire" routerLinkActive="active" >
                            Surveys <span *ngIf="surveyCount&&surveyMin" class="surveys">{{surveyCount}} of {{surveyMin}} completed</span>
                        </a>
                    </li>
                    <li *ngIf="!thisIsCoop && deliveringPharmacy"><a routerLink="offers" routerLinkActive="active">Offers</a></li>
                    <li *ngIf="pharmacyDelivers"><a routerLink="formulary" routerLinkActive="active">Formulary</a></li>
                    <li *ngIf="prescribingPharmacy"><a [routerLink]="['/clinic']" [queryParams]="{ page: 1 }" routerLinkActive="active">Consultations</a></li>
                    <!-- <li ><a routerLink="prescriptions" routerLinkActive="active">Prescription Checker</a></li> -->
                </ul>
                <ul class="nav navbar-nav navbar-right">

                    <li class="dropdown" dropdown>
                        <a dropdownToggle>
                            Welcome {{user.name}} <span class="caret"></span>
                        </a>

                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li *ngIf="thisIsCoop"><a routerLink='settings'>Settings</a></li>
                            <li><a routerLink='change-password'>Change password</a></li>
                            <li><a routerLink='change-pin'>Change pin</a></li>
                            <li><a (click)='logout()'>Logout</a></li>
                        </ul>

                    </li>

                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
    </nav>
</header>

<router-outlet></router-outlet>