import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { debounceTime, lastValueFrom } from 'rxjs';
import { CONSENT_FORM } from 'src/assets/patientQuestionnaire';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-patient-info-confirm-form',
  templateUrl: './patient-info-confirm-form.component.html',
  styleUrls: ['./patient-info-confirm-form.component.scss']
})
export class PatientInfoConfirmFormComponent implements OnInit, OnChanges {
  @Input() orderItem:any;
  @Input() orderId:any;

  consentGroup: FormGroup;
  consentData: any;
  consentId: any;

  constructor(
    private clinicService: ClinicService,
    private sanitizer: DomSanitizer
  ) { }

  private async initializeConsentNotes(){
    if (!!this.orderItem) {
      const note = this.orderItem.orderNotes.find(note => note.type === 'PATIENT_DETAIL_CONFIRMATION');
      this.consentData = !!note ? JSON.parse(note.questionAnswer) : {};
    }
  }

  private createConsentNotesForm() {
    const noteControls = {};
    Object.keys(this.consentForm).forEach((key: string) => {
      noteControls[key] = new FormControl(
        this.consentData ? this.consentData[key] : undefined,
        [Validators.required]
      );
    });

    this.consentGroup = new FormGroup(noteControls);
    this.onConsentNotesChange();
  }

  private onConsentNotesChange() {
    this.consentGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      const consentData = this.getConsentData();
      
      const orderPayload = this.clinicService.getOrderNotePayload(this.orderItem);
      
      let noteIndex = undefined;
      const note = this.orderItem.orderNotes.find((note, index) => {
        noteIndex = index;
        return note.type === 'PATIENT_DETAIL_CONFIRMATION';
      })
      if (!!note) {
        orderPayload.orderNotes[noteIndex] = consentData;
      } else {
        orderPayload.orderNotes.push(consentData);
      }

      await lastValueFrom(this.clinicService.updateOrderNote(this.orderId, orderPayload));
    });
  }

  // private updateConsentNotesForm(): void {
  //   if (!!this.consentGroup) { 
  //     this.consentGroup.patchValue(this.consentData);
  //   }
  // }

  ngOnInit(): void {
    this.initializeConsentNotes();
    this.createConsentNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      this.initializeConsentNotes();
      this.createConsentNotesForm();
    }
  }

  getConsentData() {
    let formValues = this.consentGroup.value;
    return {
      type: 'PATIENT_DETAIL_CONFIRMATION',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  getSanitizedHtml(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  get consentForm() {
    return CONSENT_FORM;
  }
}
