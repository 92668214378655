import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'orderBy'})

export class OrderByPipe implements PipeTransform {
    transform(obj: any, orderFields: string[]): any {
        orderFields.forEach(function(currentField) {
            let orderType = 'ASC';

            if (currentField[0] === '-') {
                currentField = currentField.substring(1);
                orderType = 'DESC';
            }

            obj.sort(function(a: any, b: any) {
                if (orderType === 'ASC') {
                    return a[currentField] < b[currentField] ? -1 : a[currentField] > b[currentField] ? +1 : 0;
                } else {
                    return a[currentField] > b[currentField] ? +1 : a[currentField] < b[currentField] ? -1 : 0;
                }
            });

        });
        return obj;
    }
}
