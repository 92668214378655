import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, debounceTime, lastValueFrom } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { PatientService } from 'src/app/patients/patient.service';
import { GPDetailsService } from './gp-details/gp-details.service';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'create-consultation',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.scss'],
})
export class CreatePatient implements OnInit, OnDestroy {
  @ViewChild('registerUserForm') registerUserForm: NgForm;
  @ViewChild('gpSearchElement') gpSearchElement: ElementRef;
  @ViewChild('emailInputField') emailInputField: ElementRef;
  @ViewChild('patientDetailsHeader') patientDetailsHeader: ElementRef;

  public gpSearchString = new Subject<string>();
  public gpAddressSearching: boolean = false;
  public gpSurgeries: any[];
  public userPostcodes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public gpPostcodes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public userAddressSelected: boolean = false;
  public userPostcodeSelected: boolean = false;
  public userPostcodeSearching: boolean = false;
  public gpPostcodeSearching: boolean = false;
  public userAddresses = [];
  public userAddressesFound: boolean = false;
  public showGPAddress = 'false';
  public selectedUserAddress = null;
  public userPostcodeSearchString = new Subject<string>();
  public gpPostcodeSearchString = new Subject<string>();
  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public years = new Array(82)
    .fill(new Date().getFullYear() - 18)
    .map((v, i) => v - i);
  public days = new Array(31).fill(1).map((v, i) => v + i);
  public isFormSubmitting: boolean = false;
  public gpNamePostcodeInput = '';
  public selectedSurgeryId = '';
  public dob21Error = false;
  public invalidDayError = false;
  public searchGPByPostcode = 'false';
  public cannabisCardIds = [];

  // model
  hasGPInUK = '';
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  gender = '';
  dobDay = null;
  dobMonth = null;
  dobYear = null;
  cannabisCardId = '';
  gpPractice = '';
  gpAddress = {
    line1: '',
    line2: '',
    county: '',
    city: '',
    postcode: '',
  };
  userAddress = {
    line1: '',
    line2: '',
    county: '',
    city: '',
    postcode: '',
  };
  identifiedByPrescribingPharmacist = '';
  confirmAccessNHS = '';
  shareConsultationWithGP = '';
  otherValue: string = '';
  otherFieldValue: string = '';
  referredBy: string = '';

  constructor(
    private router: Router,
    private accountService: AccountService,
    private patientService: PatientService,
    private gpDetailsService: GPDetailsService,
    private clinicService: ClinicService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userPostcodeSearchString
      .pipe(debounceTime(500))
      .subscribe((postcode) => {
        if (postcode) {
          this.userPostcodeSearching = true;
          this.accountService.lookupPostcode(postcode).subscribe(
            (v: string[]) => {
              this.userPostcodeSearching = false;
              this.userPostcodes.next(v);
            },
            (error: any) => console.error(error)
          );
        } else {
          this.userPostcodes.next([]);
        }
      });

    this.gpPostcodeSearchString
      .pipe(debounceTime(500))
      .subscribe((postcode) => {
        if (postcode) {
          this.gpPostcodeSearching = true;
          this.accountService.lookupPostcode(postcode).subscribe(
            (v: string[]) => {
              this.gpPostcodeSearching = false;
              this.gpPostcodes.next(v);
            },
            (error: any) => console.error(error)
          );
        } else {
          this.gpPostcodes.next([]);
        }
      });

    this.gpSearchString
      .pipe(debounceTime(this.searchGPByPostcode === 'true' ? 100 : 1000))
      .subscribe((postcode) => {
        if (postcode && postcode.length > 2) {
          this.gpAddressSearching = true;

          this.gpPractice = '';
          this.gpAddress = {
            line1: '',
            line2: '',
            county: '',
            city: '',
            postcode: '',
          };
          this.accountService.getGpsInfo(postcode).subscribe(
            (surgeries: any[]) => {
              this.gpAddressSearching = false;
              setTimeout(() => {
                this.gpSearchElement.nativeElement?.focus();
              });
              if (surgeries.length > 0) {
                this.gpSurgeries = (surgeries || []).map((x) => {
                  let address = {
                    line1: x.Address1,
                    line2: x.Address2,
                    county: x.County,
                    city: x.City,
                    postcode: x.Postcode,
                  };

                  const parsedContacts = JSON.parse(x.Contacts || "[]");
                  const contactInfo =
                    this.gpDetailsService.getGPContactInfo(parsedContacts);

                  return {
                    id: x.OrganisationID,
                    name: x.OrganisationName,
                    typeId: x.OrganisationTypeID,
                    organisationType: x.OrganisationType,
                    distance: 0,
                    displayAddress: this.getDisplayAddress(address),
                    address: address,
                    mobile: contactInfo.phone,
                    email: contactInfo.email,
                    fax: contactInfo.fax,
                    url: contactInfo.url,
                  };
                });
              } else {
                this.toastr.warning('Address not found, please enter manually');
                this.gpSurgeries = [];
              }
            },
            (error: any) => {
              this.gpAddressSearching = false;
              setTimeout(() => {
                this.gpSearchElement.nativeElement?.focus();
              });
              this.gpSurgeries = [];
              this.toastr.warning('Address not found, please enter manually');
              console.error(error);
            }
          );
        }
      });
  }

  onGpInput(postcode: string) {
    this.gpSearchString.next(postcode);
  }

  selectSurgery(surgery) {
    if (surgery) {
      this.selectedSurgeryId = surgery.id;
      this.gpPractice = surgery.name;
      this.gpAddress = surgery.address;
      this.gpSurgeries = [surgery];
      setTimeout(() => {
        this.emailInputField.nativeElement?.focus();
        this.patientDetailsHeader.nativeElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 500);
    } else {
      this.gpSurgeries = [];
      this.gpNamePostcodeInput = '';
      this.selectedSurgeryId = '';
      this.gpPractice = '';
      this.gpAddress = {
        line1: '',
        line2: '',
        county: '',
        city: '',
        postcode: '',
      };
    }
  }

  suggestValues() {
    let input = this.cannabisCardId;

    const suggestions = [];

    const cleanedInput = input.replace(/\D/g, '').slice(0, 9);

    if (cleanedInput.length > 5 && cleanedInput.length < 10) {
      const remainingDigits = 9 - cleanedInput.length;

      for (let i = 0; i < Math.pow(10, remainingDigits); i++) {
        const paddedValue = i.toString().padStart(remainingDigits, '0');
        const value = `${cleanedInput}${paddedValue}`;
        suggestions.push(
          `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 9)}`
        );
      }
    }

    this.cannabisCardIds = suggestions;
  }

  onCannabisCardIdSelect(id) {
    this.cannabisCardId = id;
  }

  getDisplayAddress(address) {
    let displayAddress = address.line1;
    if (address.line2 && /\S/.test(address.line2)) {
      displayAddress += ', ' + address.line2;
    }

    if (address.city && /\S/.test(address.city)) {
      displayAddress += ', ' + address.city;
    }

    if (address.county && /\S/.test(address.county)) {
      displayAddress += ', ' + address.county;
    }
    return displayAddress;
  }

  onUserPostcodeInput(postcode: string) {
    this.selectedUserAddress = null;
    this.userPostcodeSelected = false;
    this.userPostcodeSearchString.next(postcode);
  }

  onGPPostcodeInput(postcode: string) {
    this.gpPostcodeSearchString.next(postcode);
  }

  onUserPostcodeSelect(postcode: string) {
    this.userAddress.postcode = postcode.replace(/ /g, '');
    this.userPostcodeSelected = true;
    this.accountService
      .getAddressesByPostcode(this.userAddress.postcode)
      .then((addresses) => {
        this.userAddressSelected = false;
        this.userAddressesFound = true;
        this.userAddresses = addresses;
        this.selectedUserAddress = null;
      });
  }

  updateSelectedUserAddress(address: any) {
    this.userAddressSelected = true;
    this.userAddress.line1 = address.line1 || '';
    this.userAddress.line2 = address.line2 || '';
    this.userAddress.county = address.county || '';
    this.userAddress.city = address.city || '';
  }

  ngOnDestroy() {}

  onSubmit(): void {
    this.dob21Error = false;

    if (this.referredBy === 'Other') {
      this.referredBy = this.otherFieldValue;
      if(this.otherFieldValue === ''){
        this.toastr.warning('', 'Please fill the Other field is required');
        return;
      }
    }

    if (this.userAddress.city === '' || this.userAddress.county === '' || this.userAddress.line1 === '') {
      this.toastr.warning('', 'Please enter the address');
      return;
    }

    if (this.registerUserForm.invalid) {
      this.toastr.warning('', 'Fill all the empty fields');
      return;
    }
    const dob = new Date(
      `${
        this.registerUserForm.value.dobYear
      }-${this.registerUserForm.value.dobMonth.padStart(
        2,
        '0'
      )}-${this.registerUserForm.value.dobDay.padStart(2, '0')}`
    );

    if (this.youngerThan21(dob)) {
      this.dob21Error = true;
      this.toastr.warning('', 'Patient should be at least 18 years old');
      return;
    }

    if (this.confirmAccessNHS === 'false') {
      this.toastr.warning(
        '',
        'Please consent our pharmacists to access your SCR'
      );
      return;
    }
    this.isFormSubmitting = true;


    const formattedData = {
      firstName: this.registerUserForm.value.firstName,
      lastName: this.registerUserForm.value.lastName,
      gender: this.registerUserForm.value.gender,
      phone: this.registerUserForm.value.phone,
      email: this.registerUserForm.value.email,
      cannabisCardId: this.registerUserForm.value.cannabisCardId,
      address: this.userAddress,
      dateOfBirth: dob,
      title: 'NOTPROVIDED',
      preferences: {
        referredBy: this.referredBy
      }
    };

    this.patientService.registerPatient(formattedData).subscribe(
      (response) => {
        const patientData = JSON.stringify(response);
        localStorage.setItem('patientData', patientData);
        this.updatePatient(response);
      },
      (error) => {
        let errorMessage = '';
        if (error.status === 412) {
          errorMessage = 'Email or phone number already exists';
        } else {
          errorMessage = 'Something went wrong';
        }
        this.toastr.error(errorMessage, "Couldn't Create Patient");
        this.isFormSubmitting = false;
      }
    );
  }

  async updatePatient(patientData) {
    if (this.registerUserForm.value.hasGPInUK === 'true') {
      let formattedData = {
        ...patientData,
        preferences: {
          gpPractice: this.gpPractice,
          gpAddress: JSON.stringify(this.gpAddress),
          accessNHSdetails: this.registerUserForm.value.confirmAccessNHS,
          shareDetailsWithGp:
            this.registerUserForm.value.shareConsultationWithGP,
          referredBy: this.referredBy
        },
        ...(!(patientData?.settings?.EXTERNALLY_IDENTIFIED === 'true')
          ? {
              identifiedByPrescribingPharmacist: JSON.parse(
                this.registerUserForm.value.identifiedByPrescribingPharmacist
              ),
            }
          : {}),
      };

      delete formattedData['displayName'];

      try {
        const patientResponse = await lastValueFrom(
          this.patientService.updatePatient(formattedData)
        );

        if (patientResponse.hasOwnProperty('error')) {
          let errorMessage = '';
          errorMessage = 'Something went wrong';
          this.toastr.error(errorMessage, "Couldn't create patient");
          this.isFormSubmitting = false;
          return;
        }

        this.toastr.success('Patient created successfully', 'Success');
        const updatedPatientData = JSON.stringify(patientResponse);
        localStorage.setItem('patientData', updatedPatientData);

        if (patientResponse.settings.EXTERNALLY_IDENTIFIED === 'false') {
          this.router.navigate([
            `/clinic/upload-verification-document/${patientResponse.id}`,
          ]);
          return;
        }

        const orderResponse = await lastValueFrom(
          this.clinicService.createOrder(patientResponse.id)
        );
        if (orderResponse.hasOwnProperty('error')) {
          let errorMessage = '';
          errorMessage = 'Something went wrong';
          this.toastr.error(errorMessage, "Couldn't create the order");
          this.isFormSubmitting = false;
          return;
        }
        localStorage.setItem(`${orderResponse.message}_completed`, 'false');
        this.router.navigate([
          `/clinic/${orderResponse.message}/questionnaire/step_1`,
        ]);
      } catch (error) {
        this.toastr.error('Something went wrong', 'Please try again');
        this.isFormSubmitting = false;
      }
    } else {
      this.router.navigate(['/clinic']);
    }
  }

  onChangeDob() {
    this.invalidDayError = false;

    if (this.dobMonth && this.dobYear) {
      const daysInMonth = this.daysInMonth(this.dobYear, this.dobMonth);
      this.days = new Array(daysInMonth).fill(1).map((v, i) => v + i);

      if (daysInMonth < this.dobDay) {
        this.dobDay = null;
        this.invalidDayError = true;
      }
    }

    if (this.dobDay && this.dobMonth && this.dobYear) {
      const dob = new Date(
        `${this.dobYear}-${this.dobMonth.padStart(
          2,
          '0'
        )}-${this.dobDay.padStart(2, '0')}`
      );

      if (this.youngerThan21(dob)) {
        this.dob21Error = true;
      } else {
        this.dob21Error = false;
      }
    }
  }

  youngerThan21(value) {
    return dayjs(value).diff(new Date()) > -567648000000;
  }

  daysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  onReferredBySelectChange(selectedValue: string): void {
    const otherField = document.getElementById('otherField');
    if (otherField) {
      if (selectedValue === 'Other') {
        otherField.style.display = 'block';
      } else {
        otherField.style.display = 'none';
        this.otherValue = ''; // Clear the input field value when Other is not selected
      }
    }
  }

}
