<div class="login">
    <div class="central_panel">

        <div class="well well--white">

            <img class="site_logo" src="/assets/img/logo.png" alt="The Treat it" />

            <h1 class="brand-1">Prescription Checker</h1>
            <p>Welcome to the Treat it Prescription Checker. Please provide your details below to view and dispense a prescription</p>
            <p class="info-box" *ngIf="!isLoggedIn"><a routerLink="/login">If you are a pharmacy with a registered GPS login, <strong>Please click here</strong>.</a></p>
            <form #loginForm="ngForm" (ngSubmit)="onLogin()">
                <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>

                <div class="form-group">
                    <label>Prescription Authorisation Code</label>
                    <input name="authCode" type="text" required minLength="12" maxlength="12" [(ngModel)]="model.authCode" class="form-control" />
                    <small class="form-text text-muted">The authorisation code can be found on the email provided to the patient by The Treat it.</small>
                </div>

                <div class="form-group">
                    <label>Patient Surname</label>
                    <input name="patientSurname" type="text" required [(ngModel)]="model.patientSurname" class="form-control" />
                    <small class="form-text text-muted">Please ensure that the patient's surname is spelt correctly, with no spaces.</small>
                </div>

                <div class="form-group">
                    <label>Patient Date of Birth</label>
                    <div class="input-group">
                        <select style="width: calc(30% - 8px); margin-right: 8px;" id="patientDobDay" name="patientDobDay" type="text" required [(ngModel)]="model.patientDobDay" class="form-control">
                            <option disabled [value]="undefined">Day</option>
                            <option *ngFor="let day of days" [value]="day">{{day}}</option>
                        </select>
                        <select style="width: calc(40% - 8px); margin-right: 8px;" id="patientDobMonth" name="patientDobMonth" type="text" required [(ngModel)]="model.patientDobMonth" class="form-control">
                            <option disabled [value]="undefined">Month</option>
                            <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option>
                        </select>
                        <select style="width: 30%;" id="patientDobYear" name="patientDobYear" type="text" required [(ngModel)]="model.patientDobYear" class="form-control">
                            <option disabled [value]="undefined">Year</option>
                            <option *ngFor="let year of years" [value]="year">{{year}}</option>
                        </select>
                    </div>
                    <small class="form-text text-muted">The patient's Date of Birth is required to validate the prescription.</small>
                </div>

                <div class="form-group">
                    <label>Store ID or GPhC</label>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <div class="btn-group" dropdown [isDisabled]="isLoggedIn">
                                <button dropdownToggle type="button" class="btn btn-default dropdown-toggle">
                                    {{authMode == 'ID' ? 'Store ID' : 'Store GPhC'}}<span style="margin-left: 0.5rem;" class="caret"></span>
                                </button>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <li role="menuitem"><a class="dropdown-item" (click)="authMode='ID'; model.storeId = ''; model.storePin=''">Store ID</a></li>
                                    <li role="menuitem"><a class="dropdown-item" (click)="authMode='GPHC'; model.storeId = ''; model.storePin=''">Store GPhC</a></li>
                                </ul>
                            </div>
                        </div>
                        <input [disabled]="isLoggedIn" class="form-control" name="storeId" type="text" autocomplete="username" required [(ngModel)]="model.storeId"/>
                    </div>
                    <small class="form-text text-muted">We provide a Store ID to some of our Pharmacy Partners. If you do not have one, please enter your Store GPhC.</small>
                </div>

                <div class="form-group">
                    <label>{{authMode == 'ID' ? 'Store PIN Code' : 'PIN Code'}}</label>
                    <input class="form-control" name="storePin" type="password" autocomplete="password" required minlength="4" maxlength="6" [(ngModel)]="model.storePin"/>
                    <small class="form-text text-muted">The PIN number would have been provided to the pharmacy to allow prescriptions to be validated / dispensed.</small>
                </div>
                <p>
                    <button class="btn btn-primary" type="submit" [disabled]="!loginForm.form.valid">Verify</button>
                </p>
            </form>

        </div>


    </div>
</div>
