<app-info-slider *ngIf="showSupportText" [supportText]="selectedSupportText" (hide)="hideSlider()"></app-info-slider>

<clinic-layout [title]="'Questionnaire'">
    <ng-container *ngIf="!!stepQuestionnaire">
        <app-progress-bar [orderId]="orderId" [stepPosition]="step.split('_')[1]" [notesGroup]="notesGroup" [orderItem]="orderItem">
            <h3 class="step-title">{{stepQuestionnaire.title}}</h3>
            <p class="step-description" *ngIf="!!stepQuestionnaire.description">{{stepQuestionnaire.description}}</p>

            <div [formGroup]="notesGroup" autocomplete="off" *ngIf="!!notesGroup" novalidate>
                <ng-container *ngFor="let key of stepQuestionnaire.keys">
                    <div *ngIf="renderFormGroup(key) && !!questions[key].questionId" class="question-container"
                        [formGroupName]="step+'|'+questions[key].questionId">
                        <label class="question-label">
                            <button *ngIf="questions[key].hasOwnProperty('supportDescription')" class="btn info-btn"
                                (click)="showSlider(questions[key])">
                                <span>&#8505;</span>
                            </button>
                            &nbsp;
                            <span>{{questions[key].question}} <span class="required">*</span></span>
                        </label>

                        <!-- feedbackType.text -->
                        <div *ngIf="questions[key].responseType === feedbackType.text" class="text-response-container">
                            <textarea class="form-control" placeholder="Patient Response" rows="3"
                                formControlName="patientText" (input)="debouncedInputChange()"></textarea>
                            <textarea class="form-control" placeholder="Pharmacist comments" rows="3" formControlName="pharmacyText" 
                                (input)="debouncedInputChange()"></textarea>
                        </div>

                        <!-- feedbackType.checkboxAndText || feedbackType.checkbox || feedbackType.checkboxQuestion -->
                        <div *ngIf="[feedbackType.checkboxAndText, feedbackType.checkbox, feedbackType.checkboxQuestion].includes(questions[key].responseType)"
                            formArrayName="choices">
                            <div *ngFor="let choice of questions[key].choices; index as i" class="group-container">
                                <div [formGroupName]="i">
                                    <div class="input-group">
                                        <button *ngIf="choice.hasOwnProperty('supportDescription')" class="btn tooltip-btn"
                                            (click)="showSlider(choice)">
                                            <span>&#8505;</span>
                                        </button>
                                        <input type="checkbox" class="form-control" [id]="choice.id" [equal]="true"
                                            formControlName="checked" (change)="handleCheckboxChange($event.target.checked, questions[key], i)">
                                        <div class="label-container">
                                            <label [for]="choice.id">
                                                {{choice.label}}
                                                <span *ngIf="questions[key].responseType === feedbackType.checkboxQuestion" class="required"><b>*</b></span>
                                            </label>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="questions[key].responseType === feedbackType.checkboxAndText">
                                        <div *ngIf="showCheckboxComment(questions[key], choice.id)"
                                            class="text-response-container">
                                            <textarea class="form-control" placeholder="Patient Response" rows="3"
                                                formControlName="patientText" (input)="debouncedInputChange()"></textarea>
                                            <textarea class="form-control" placeholder="Pharmacist comments" rows="3"
                                                formControlName="pharmacyText" (input)="debouncedInputChange()"></textarea>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!-- feedbackType.choiceAndTextOnCondition || feedbackType.choice -->
                        <div
                            *ngIf="[feedbackType.choiceAndTextOnCondition, feedbackType.choice].includes(questions[key].responseType)">
                            <div class="radio-control">
                                <ng-container *ngIf="questions[key].choices === undefined; else hasChoices">
                                    <input type="radio" [value]="true" formControlName="choice" [name]="choice"
                                        [id]="questions[key].questionId+'Yes'" (change)="handleRadioButtonChange(questions[key])"/>
                                    <label [for]="questions[key].questionId+'Yes'">Yes</label>
                                    <input type="radio" [value]="false" formControlName="choice" [name]="choice"
                                        [id]="questions[key].questionId+'No'" (change)="handleRadioButtonChange(questions[key])"/>
                                    <label [for]="questions[key].questionId+'No'">No</label>
                                </ng-container>
                                <ng-template #hasChoices>
                                    <ng-container *ngFor="let choice of questions[key].choices">
                                        <input type="radio" [value]="choice.label" formControlName="choice"
                                            [id]="questions[key].questionId+'_'+choice.id" (change)="handleRadioButtonChange(questions[key])"/>
                                        <label [for]="questions[key].questionId+'_'+choice.id">{{choice.label}}</label>
                                    </ng-container>
                                </ng-template>
                            </div>
                            <ng-container *ngIf="questions[key].responseType === feedbackType.choiceAndTextOnCondition">
                                <div *ngIf="showRadioboxComment(questions[key])" class="text-response-container">
                                    <textarea class="form-control" placeholder="Patient Response" rows="3"
                                        formControlName="patientText" (input)="debouncedInputChange()"></textarea>
                                    <textarea class="form-control" placeholder="Pharmacist comments" rows="3"
                                        formControlName="pharmacyText" (input)="debouncedInputChange()"></textarea>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="questions[key].hasOwnProperty('header') && renderHeader(key)" class="header-container">
                        <h6 *ngIf="!!questions[key].header; else questionLabel">{{questions[key].header}}</h6>
                    </div>

                    <div *ngIf="questions[key].responseType === feedbackType.externalForm && renderModal(key)"
                        class="header-container">
                        <button *ngIf="questions[key].hasOwnProperty('supportDescription') && questions[key].modalDetails.openModal" class="btn info-btn"
                            (click)="showSlider(questions[key])">
                            <span>&#8505;</span>
                        </button>
                        &nbsp;
                        <button *ngIf="questions[key].modalDetails.openModal; else renderConsentForm"
                            class="btn btn-default" style="margin-right: 1rem;"
                            (click)="openModal(questions[key].modalDetails.formType)">
                            {{questions[key].question}}
                        </button>
                        <!-- <span class="required">*</span> -->
                            
                        <ng-template #renderConsentForm>
                            <app-patient-info-confirm-form [orderItem]="orderItem" [orderId]="orderId"></app-patient-info-confirm-form>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </app-progress-bar>
    </ng-container>
</clinic-layout>

<div bsModal #PEGModal="bs-modal" id="PEGModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="PEGModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">PEG Form</h3>
            </div>
            <div class="modal-body">
                <app-peg-form [orderItem]="orderItem" [orderId]="orderId"></app-peg-form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="PEGModal.hide()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div bsModal #GADModal="bs-modal" id="GADModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="GADModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">GAD Form</h3>
            </div>
            <div class="modal-body">
                <app-gad-form [orderItem]="orderItem" [orderId]="orderId"></app-gad-form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="GADModal.hide()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div bsModal #AUDITModal="bs-modal" id="AUDITModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="AUDITModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">AUDIT Form</h3>
            </div>
            <div class="modal-body">
                <app-audit-form [orderItem]="orderItem" [orderId]="orderId"></app-audit-form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="AUDITModal.hide()">Submit</button>
            </div>
        </div>
    </div>
</div>