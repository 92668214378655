import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { UserService } from './user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  passwordForm: UntypedFormGroup;
  isProcessing = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, this.validatePassword]],
      passwordConfirmation: ['', [Validators.required]],
    }, { validators: [currentAndNewPasswordValidator, passwordCrossValidator] });
  }

  validatePassword(field: UntypedFormControl): ValidationErrors | null {
    if (!(/[A-Z]+/.test(field.value))) { // at least one upper-case char
      return { fieldError: 'Password must contain at least 1 upper-case character.' };
    }
    if (!(/[a-z]+/.test(field.value))) { // at least lower-case char
      return { fieldError: 'Password must contain at  least 1 lower-case character.' };
    }
    if (!(/[0-9]+/.test(field.value))) { // at least one number
      return { fieldError: 'Password must contain at least 1 number.' };
    }
    if (!(/[~`!@#$%^&\-*()_+=|:\\;"'[<\]>,.?{}/€£¥•=]+/.test(field.value))) { // at least one special char
      return { fieldError: 'Password must contain at least 1 special character.' };
    }
    if (field.value?.length < 8) {
      return { fieldError: 'Password must be at least 8 characters long.' };
    }
    return null;
  }

  submit(): void {
    const { currentPassword, newPassword } = this.passwordForm.value;
    this.isProcessing = true;
    this.userService.changePassword(currentPassword, newPassword)
      .pipe(finalize(() => {
        this.isProcessing = false;
      }))
      .subscribe(
        (response) => {
          this.toastr.success('Password changed successfully', 'Success');
          this.router.navigateByUrl('/');
        },
        (error) => {
          this.toastr.error(this.getErrorMessage(error), 'Password change failed');
        });
  }

  getErrorMessage(error: any): string {
    return error.error?.message || error.message || 'An unexpected error occurred.';
  }
}

/** Ensure that new password and confirm password fields are equal */
const passwordCrossValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const newPassword = formGroup.get('newPassword').value;
  const passwordConfirmation = formGroup.get('passwordConfirmation').value;
  return newPassword === passwordConfirmation ? null : { error: 'The passwords do not match.' };
};

/** Ensure that old and new passwords are different */
const currentAndNewPasswordValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const newPassword = formGroup.get('newPassword').value;
  const currentPassword = formGroup.get('currentPassword').value;
  return newPassword === currentPassword ? { samePasswordError: 'New password can\'t be the same as the current password.' } : null;
};
