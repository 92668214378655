import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

@Component({
  selector: 'app-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss']
})
export class MaskedInputComponent implements OnInit {
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  displayValue = '';

  constructor() {}

  onInputChange(inputValue: string){
    if (inputValue.length === 0) {
      this.valueChange.emit('');
      this.displayValue= '';
      return;
    }

    if (inputValue.length > this.value.length) {
      const lastChar = inputValue[inputValue.length - 1];
      let passingValue = `${this.value}${lastChar}`;
      this.valueChange.emit(passingValue);
      this.displayValue = this.maskValue(inputValue);
      return;
    }

    if (inputValue.length < this.value.length) {
      let passingValue = this.value.substring(0, this.value.length - 1);
      this.valueChange.emit(passingValue);
      this.displayValue = this.maskValue(inputValue);
    }
  }

  ngOnInit(): void {
  }

  maskValue(value: string): string {
    return value.replace(/./g, '*');
  }

}
