import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'clinic-layout',
  templateUrl: './clinic-layout.component.html',
  styleUrls: ['./clinic-layout.component.scss'],
})
export class ClinicLayout implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() reviewPage = false;
  @Input() titleCenter = false;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
