import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'onlyShowMissingPrice',
})
export class OrderMissingPricePipe implements PipeTransform {

    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => {
                return object.originalPrice === 0;
            });
        }
    }

}
