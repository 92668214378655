import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { PEG_FORM } from 'src/assets/patientQuestionnaire';
import { lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-peg-form',
  templateUrl: './peg-form.component.html',
  styleUrls: ['./peg-form.component.scss']
})
export class PegFormComponent implements OnInit, OnChanges {
  @Input() orderItem:any;
  @Input() orderId:any;

  pegGroup: FormGroup;
  notes: any;
  average: string;

  protected options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    tickStep: 1,
    getLegend: (value: number): string => {
      if (value === 0 || value === 10) {
        return `${value}`;
      }
      return '';
    },
  };

  constructor(private clinicService: ClinicService) { }

  private onPegNotesChange() {
    this.pegGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      const pegData = this.getPegData(val);
      this.average = this.getPegAverage(val).toFixed(2);
      const orderPayload = this.clinicService.getOrderNotePayload(this.orderItem);

      let noteIndex = undefined;
      const note = this.orderItem.orderNotes.find((note, index) => {
        noteIndex = index;
        return note.type === 'PEG_FORM';
      })
      if (!!note) {
        orderPayload.orderNotes[noteIndex] = pegData;
      } else {
        orderPayload.orderNotes.push(pegData);
      }

      await lastValueFrom(this.clinicService.updateOrderNote(this.orderId, orderPayload));
    });
  }

  private async initializePegNotes(){
    if (!!this.orderItem) {
      const note = this.orderItem.orderNotes.find(note => note.type === 'PEG_FORM');
      this.notes = !!note ? JSON.parse(note.questionAnswer) : {};
    }
  }

  private createPegNotesForm() {
    const noteControls = {};
    const pegData = this.notes;

    if (!!pegData && !!pegData.average && typeof pegData.average === 'number') {
      this.average = pegData.average.toFixed(2)
    } else {
      this.average = pegData?.average || 0
    }

    Object.keys(PEG_FORM).forEach((key: string) => {
      noteControls[key] = new FormControl(pegData?.[key] || 0, [
        Validators.required,
        Validators.max(10),
        Validators.min(0),
      ]);
    });

    this.pegGroup = new FormGroup(noteControls);
  }

  private updateConsentNotesForm(): void {
    if (!!this.pegGroup) {
      this.pegGroup.patchValue(this.notes);
    }
  }

  ngOnInit(): void {
    this.initializePegNotes();
    this.createPegNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      this.initializePegNotes();
      this.updateConsentNotesForm();
    }
  }

  ngAfterViewInit(): void {
    this.onPegNotesChange();
  }

  private getPegData(pegValue: any) {
    const average = this.getPegAverage(pegValue);

    const formValues = {
      ...pegValue,
      average,
    };

    return {
      type: 'PEG_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  private getPegAverage(pegValue: any){
    let average = 0;
    Object.keys(pegValue).forEach((key: string) => {
      if (!!pegValue[key]) {
        average += parseFloat(pegValue[key]);
      }
    });

    return average / 3
  }

  get pegForm() {
    return PEG_FORM;
  }
}
