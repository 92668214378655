import { Component, OnInit, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuestionnaireService } from './questionnaire.service';
import { UserService } from '../account/user.service';
import { DataService } from '../common/data.service';
import { map, mergeMap } from 'rxjs/operators';
import { ChartType } from 'angular-google-charts';

interface ChartOptions {
    chartType: ChartType,
    columns: any[],
    data: any[][]
    title: string,
    width: number,
    height: number
}

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html'
})
export class QuestionnaireComponent implements OnInit {
    isResultsLoading: boolean = false;
    periods: any;
    questions: any;
    user: any;
    grandtotal = 0;
    minSurveys: any = null;
    report = false;
    reportbox = {
        default: 'q9_answer',
        question: 'Q9 Finally, taking everything into account - the staff, the shop and the service provided - how would you rate the pharmacy where you received this questionnaire',
        male: 0,
        female: 0,
        patient: 0,
        age1619: 0,
        age2024: 0,
        age2534: 0,
        age3544: 0,
        age4554: 0,
        age5564: 0,
        age65: 0,
        button: 'Show Report',
        blockleft: [
            { answer: '', value: 0 },
            { answer: '', value: 0 }
        ],
        year: 0,
        wellrated: [
            { question: '', answer: '', total: 0, ratevalue: 0 },
            { question: '', answer: '', total: 0, ratevalue: 0 },
            { question: '', answer: '', total: 0, ratevalue: 0 }
        ],
        poolyrated: [
            { question: '', answer: '', action: '', total: 0, ratevalue: 0 },
            { question: '', answer: '', action: '', total: 0, ratevalue: 0 },
            { question: '', answer: '', action: '', total: 0, ratevalue: 0 }
        ],
        overallrate: 0, // 0: unrated, 1:poorly rated only, 2:mixed rated, 3:wellrated only
        selectedAnswer: 0, // 0: unrated, 1:poorly rated only, 2:mixed rated, 3:wellrated only
        display: '',
        clearblockLeft: function () {
            this.blockleft[0].answer = '';
            this.blockleft[1].answer = '';
            this.blockleft[0].value = 0;
            this.blockleft[1].value = 0;
        },
        clearCurrentRates: function () {
            for (let i = 0; i < this.wellrated.length; i++) {
                this.wellrated[i] = { question: '', answer: '', total: 0, ratevalue: 0 };
            }
            for (let i = 0; i < this.poolyrated.length; i++) {
                this.poolyrated[i] = { question: '', answer: '', action: '', total: 0, ratevalue: 0 };
            }
        }
    };

    dataRequester: any;

    rates: { [key: string]: { value: number, desc: string } } = {
        'EXCELLENT': {
            'value': 9, 'desc': 'Excellent'
        },
        'VERY_SATISFIED': {
            'value': 8, 'desc': 'Very satisfied'
        },
        'VERY_USED': {
            'value': 7, 'desc': 'Very used'
        },
        'VERY_WELL': {
            'value': 7, 'desc': 'Very well'
        },
        'VERY_GOOD': {
            'value': 7, 'desc': 'Very good'
        },
        'FAIRLY_WELL': {
            'value': 6, 'desc': 'Fairly well'
        },
        'GOOD': {
            'value': 6, 'desc': 'Good'
        },
        'FAIRLY_GOOD': {
            'value': 5, 'desc': 'Fairly good'
        },
        'FAIRLY_SATISFIED': {
            'value': 5, 'desc': 'Fairly satisfied'
        },
        'NOT_VERY_SATISFIED': {
            'value': 4, 'desc': 'Not very satisfied'
        },
        'FAIR': {
            'value': 3, 'desc': 'Fair'
        },
        'FAIRLY_POOR': {
            'value': 3, 'desc': 'Fairly poor'
        },
        'NOT_VERY_WELL': {
            'value': 2, 'desc': 'Not very well'
        },
        'POOR': {
            'value': 2, 'desc': 'poor'
        },
        'NOT_AT_ALL_SATISFIED': {
            'value': 1, 'desc': 'Not at all satisfied'
        },
        'NOT_AT_ALL_VERY_WELL': {
            'value': 1, 'desc': 'No at all very well'
        },
        'VERY_POOR': {
            'value': 1, 'desc': 'Very poor'
        },
        'NEVER': {
            'value': 1, 'desc': 'Never'
        },
        'DON\'T_KNOW': {
            'value': 0, 'desc': 'Don\'t know'
        },
        'UNRATED': {
            'value': 0, 'desc': 'unrated'
        }
    };
    // rates > 5 : good reviews rates <= 5 : bad reviews

    private columns = ['Selected Answer', 'Count']

    public chart: ChartOptions = {
        title: '',
        width: 750,
        height: 500,
        chartType: ChartType.PieChart,
        columns: this.columns,
        data: []
    };

    constructor(private http: HttpClient,
        private userService: UserService,
        public questionnaireService: QuestionnaireService,
        private dataService: DataService) { }

    getRateValue(rate: string): number {
        const UCRate = rate.toUpperCase().replace(/ /g, '_') || 'UNRATED';
        return this.rates[UCRate].value;
    }

    getAnnualResults(dataCollectionYear) {
        const initialDate = (dataCollectionYear - 1).toString() + '-04-01T00:00:00Z';
        const finalDate = (dataCollectionYear).toString() + '-03-31T23:59:59Z';
        this.isResultsLoading = true;
        this.http.get<any>('/assets/questionnaire.json').pipe(map((res) => {
            this.questionnaireService.questionsjson = res;
            return this.questionnaireService.questionsjson;
        }),
            mergeMap((data) => this.questionnaireService.getResults(initialDate, finalDate))).subscribe(results => {
                this.questionnaireService.results = results.content;
                if (results.totalElements > 0) {
                    this.questionnaireService.pharmacy = results.content[0].pharmacy;
                }
                this.placeValues(this.questionnaireService.results);
                if (this.reportbox.year === dataCollectionYear) {
                    this.dataService.updateData(this.grandtotal); // update info displayed at AppComponent nav bar only for current period
                }
                this.isResultsLoading = false;
            }, error => { this.isResultsLoading = false });
    }

    placeValues(data): void {
        let questionnaire: any;
        this.grandtotal = data.length;
        for (const key of data) {
            // checking result array from API
            questionnaire = key.questionaire;
            for (const answer in questionnaire) {
                // checking answers from the current key of the questionnaire json
                const value = questionnaire[answer];
                // if value is null ignore it
                if (value && answer !== 'q10_answer' && answer !== 'q1_answerdesc') {
                    this.questionnaireService.questionsjson[answer].answers[value].total += 1;
                } else if (questionnaire[answer]) {
                    this.questionnaireService.questionsjson[answer].answers.push(
                        { desc: questionnaire[answer], total: null, value: null }
                    );
                }
            }
        }
        this.questions = this.questionnaireService.Object2Array(this.questionnaireService.questionsjson);
        // Hack for Age question (blerg)
        if (this.questions[30]) {
            const switch1 = this.questions[30].answers[5];
            const switch2 = this.questions[30].answers[6];
            this.questions[30].answers[5] = switch2;
            this.questions[30].answers[6] = switch1;
        }
    }

    showReport() {
        if (!this.report) {
            if (this.chart.data.length === 0) {
                this.add2Chart(this.reportbox.default);
            }
            this.reportbox.patient = this.questionnaireService.questionsjson['q1_answer'].answers[0].total;
            this.reportbox.male = this.questionnaireService.questionsjson['q12_answer'].answers[0].total;
            this.reportbox.female = this.questionnaireService.questionsjson['q12_answer'].answers[1].total;
            this.reportbox.age1619 = this.questionnaireService.questionsjson['q11_answer'].answers[0].total;
            this.reportbox.age2024 = this.questionnaireService.questionsjson['q11_answer'].answers[1].total;
            this.reportbox.age2534 = this.questionnaireService.questionsjson['q11_answer'].answers[2].total;
            this.reportbox.age3544 = this.questionnaireService.questionsjson['q11_answer'].answers[3].total;
            this.reportbox.age4554 = this.questionnaireService.questionsjson['q11_answer'].answers[4].total;
            this.reportbox.age5564 = this.questionnaireService.questionsjson['q11_answer'].answers[5].total;
            this.reportbox.age65 = this.questionnaireService.questionsjson['q11_answer'].answers[6].total;

            // getting best score from selected question
            this.reportbox.clearblockLeft();

            const selectedAnswer = this.chart.data;
            const bestScore = { count: 0, desc: '' };
            for (let i = 0; i < selectedAnswer.length; i++) {
                const [description, count] = [String(selectedAnswer[i][0]), Number(selectedAnswer[i][1])];
                if (bestScore.count <= count && this.getRateValue(bestScore.desc) < this.getRateValue(description)) {
                    bestScore.desc = description;
                    bestScore.count = count;
                    this.reportbox.blockleft[0].answer = description;
                    this.reportbox.blockleft[0].value = count;
                }
            }

            // getting second best from selected question
            bestScore.count = 0;
            bestScore.desc = '';
            for (let j = 0; j < selectedAnswer.length; j++) {
                const [description, count] = [String(selectedAnswer[j][0]), Number(selectedAnswer[j][1])];
                if (bestScore.count <= count
                    && this.getRateValue(bestScore.desc) < this.getRateValue(description)
                    && this.reportbox.blockleft[0].answer !== description
                    && count > 0) {
                    bestScore.desc = description;
                    bestScore.count = count;
                    this.reportbox.blockleft[1].answer = description;
                    this.reportbox.blockleft[1].value = count;
                }
            }

            this.reportbox.selectedAnswer = this.rateSelectedAnswer(this.reportbox.blockleft);

            this.rateQuestion(this.questionnaireService.questionsjson);

            this.reportbox.button = 'Hide Report';
            this.report = true;
        } else {
            this.reportbox.button = 'Show Report';
            this.report = false;
        }
    }

    rateSelectedAnswer(answer): number {
        let wellrated = 0; let poorlyrated = 0;
        for (const item in answer) {
            if (answer[item].answer) {
                if (this.getRateValue(answer[item].answer) > 4) {
                    wellrated++;
                } else {
                    poorlyrated++;
                }
            }
        }

        if (wellrated === answer.length) { // if wellrated answers = to number of answers --> 3:wellrated only
            return 3; // only good reviews
        } else if (poorlyrated === answer.length) {
            return 1; // only poor reviews
        } else {
            return 2; // mixed reviews
        }
    }

    rateQuestion(data): void {
        let bestScore = 0;
        let bestRated = '';
        let answerCount = 0;
        let answers: any;
        let wellrated_index = 0;
        let poorlyrated_index = 0;
        let index = 0;
        this.reportbox.clearCurrentRates();
        for (const key in data) {
            answers = data[key].answers;
            bestScore = 0;
            for (let j = 0; j < answers.length; j++) {
                if (bestScore < answers[j].total && data[key].chart) {
                    bestScore = answers[j].total;
                    answerCount = answers[j].total;
                    data[key].rated = answers[j].desc;
                }
            }
            if (data[key].rated !== null && data[key].rated !== '') {
                bestRated = data[key].rated.toUpperCase().replace(/ /g, '_');
                index = this.addRates2Report(1, bestRated);
                if (index > -1 && index < this.reportbox.wellrated.length) {
                    this.reportbox.wellrated[index].question = data[key].report;
                    this.reportbox.wellrated[index].answer = data[key].rated;
                    this.reportbox.wellrated[index].total = answerCount;
                    this.reportbox.wellrated[index].ratevalue = this.rates[bestRated].value;
                    wellrated_index++;
                }

                index = this.addRates2Report(0, bestRated);
                if (index > -1 && index < this.reportbox.poolyrated.length) {
                    this.reportbox.poolyrated[index].question = data[key].report;
                    this.reportbox.poolyrated[index].action = data[key].action;
                    this.reportbox.poolyrated[index].answer = data[key].rated;
                    this.reportbox.poolyrated[index].total = answerCount;
                    this.reportbox.poolyrated[index].ratevalue = this.rates[bestRated].value;
                    poorlyrated_index++;
                }
            }

            if (wellrated_index > 0 && poorlyrated_index === 0) {
                // only wellrated reviews
                this.reportbox.overallrate = 3;
            } else if (wellrated_index === 0 && poorlyrated_index > 0) {
                // only poorlyrated reviews
                this.reportbox.overallrate = 1;
                // mixed reviews
            } else if (wellrated_index > 0 && poorlyrated_index > 0) {
                this.reportbox.overallrate = 2;
            }
        }
    }

    addRates2Report(type: number, rated: string): number {
        let index = -1;
        if (type === 1 && this.rates[rated]) {// add records to good reviews section, good reviews from 5 to 10
            if (this.rates[rated].value > 4) {
                for (let i = 0; i < this.reportbox.wellrated.length; i++) {
                    if (this.reportbox.wellrated[i].ratevalue < this.rates[rated].value) {
                        index = i;
                    }
                }
            }
        } else {// add records to bad reviews section, bad reviews < 5
            if (this.rates[rated].value < 5) {
                for (let i = 0; i < this.reportbox.poolyrated.length; i++) {
                    if (this.reportbox.poolyrated[i].ratevalue < this.rates[rated].value) {
                        index = i;
                    }
                }
            }
        }
        return index;
    }

    add2Chart(key: string): void {
        // producing final report base on a different question, maybe one with a better review to show
        const answers: any[] = this.questionnaireService.questionsjson[key].answers;
        this.reportbox.display = key;
        this.reportbox.question = this.questionnaireService.questionsjson[key].question;
        this.chart = {
            ...this.chart,
            data: [...answers.map(answer => [answer.desc, answer.total])]
        };
        console.log(this.chart);
        this.showReport();
    }

    ngOnInit() {
        this.periods = this.questionnaireService.setPeriods();
        this.userService.user$.subscribe((user) => {
            this.user = user;
            if (this.user) {
                this.minSurveys = this.questionnaireService.getMinSurveysbyAMSV(+this.user.averageMonthlyScriptVolume || 0);
            }
        });
        this.reportbox.year = this.questionnaireService.setCurrentYear();
        this.getAnnualResults(this.reportbox.year);

    }

}
