import { Injectable } from '@angular/core';

@Injectable()
export class FormService {

    questionsLookup = {
     'general.howLong': 'How long have you been suffering with ED?'
    };

    constructor() { }
}
