
import { throwError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime, catchError } from 'rxjs/operators';

@Injectable()
export class QuestionnaireService {

    data: {};
    yearsAvailable = 5;
    questionsjson: any;
    results: any = null;
    startDate: string;
    endDate: string;
    pharmacy: any;

    constructor(private authHttp: HttpClient, @Inject('global_options') private options: any) { }

    getResults(startDate: string, endDate: string): Observable<any> {
        // startDate = '20XX-01-01T00:00:00Z';
        // endDate = '201XX-12-31T00:00:00Z';
        this.startDate = startDate;
        this.endDate = endDate;
        const url = `${this.options.apiEndPoint}api/pharmacy/surveys?resource=surveys&startDate=${startDate}&endDate=${endDate}&pageNumber=0&pageSize=150`;
        return this.authHttp.get<any>(url).pipe(
                            debounceTime(1000),
                            catchError(this.handleError));
    }

    getSurveysCount(startDate: string, endDate: string): Observable<any> {
        const url = `${this.options.apiEndPoint}api/pharmacy/surveys?resource=count&startDate=${startDate}&endDate=${endDate}`;
        return this.authHttp.get<any>(url)
                            .pipe(catchError(this.handleError));
    }

    printReport(): void {
        window.print();
    }

    getMinSurveysbyAMSV(AMSV: number): number {
        if (AMSV <= 2000) {
            return 50;
        } else if (AMSV > 2001 && AMSV < 4000) {
            return 75;
        } else if (AMSV > 4001 && AMSV < 6000) {
            return 100;
        } else if (AMSV > 6001 && AMSV < 8000) {
            return 125;
        } else {
            return 150;
        }
    }

    setCurrentYear(): number {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        if (currentMonth >= 4 && currentMonth <= 12) {
            return currentDate.getFullYear() + 1;
        } else {
            return currentDate.getFullYear();
        }
    }

    setPeriods(): Array<{}> {
        const periods = new Array;
        const currentYear = this.setCurrentYear();
        for (let i = 0; i < this.yearsAvailable; i++) {
            const endYear = currentYear - i;
            const startYear = endYear - 1;
            const selectDesc = 'April ' + startYear + ' to March ' + endYear;
            periods.push({'value': endYear, 'desc': selectDesc });
        }
        return periods;
    }

    Object2Array(data: Object): Array<{}> {
        this.data = data;
        const keys = [];
        const answersArray = [];
        let greatest: string;
        for (const key in data) {
            if (data[key].type === 'customer-input' || data[key].type === 'selection-input') {
                const customerInputs = new Array;
                for (const description in data[key].answers) {
                    if (data[key].answers[description].desc) {
                        customerInputs.push(data[key].answers[description].desc);
                    }
                }
                keys.push({question: data[key].question, answers: customerInputs, chart: data[key].chart, key: key, type: data[key].type});
            } else {
                // greatest = this.getBestCount(this.data,key);
                keys.push({question: data[key].question, answers: data[key].answers, chart: data[key].chart, key: key, type: data[key].type, greatest: greatest});
            }
        }
        return keys;
    }

    getIndividualSets(results) {
        const questionnarieSet = new Array;
        let questionSet = new Array;

        const questionnaire = new Array;
        for (const key of results) {
            const answers = new Array;
            for (const answer in key.questionaire) {
                const customerAnswer = key.questionaire[answer];
                this.questionsjson[answer].individualSets.push({datatype: 'set', id: key.id, answer: customerAnswer, key: answer});
            }
        }

        for (let i = 0; i < results.length; i++) {
            for (const item in this.questionsjson) {
                switch (this.questionsjson[item].type) {
                    case 'head': questionSet.push({datatype: 'head', key: item});
                                    break;
                    default :   questionSet.push(this.questionsjson[item].individualSets[i]);
                }
            }
            questionnarieSet.push(questionSet);
            questionSet = new Array;
        }

        return questionnarieSet;
    }

    getBestCount(data: Object, key: string): string {
        let bestCount = 0;
        let desc = '';
        const question = data[key].answers;
        for (const item in question) {
            if (bestCount < question[item].total) {
                bestCount = question[item].total;
                desc = question[item].desc;
            }
        }
        return desc;
    }

    private handleError(response: any) {
        return throwError(response);
    }

}
