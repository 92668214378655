import { Injectable, Inject } from '@angular/core';
import { AccountService } from './account.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class KeepAliveService {
    private jwtHelper = new JwtHelperService();
    private refreshTimeout: any;

    constructor(
        private accountService: AccountService,
        private http: HttpClient,
        private idle: Idle,
        @Inject('global_options') private options: any) {
    }

    init() {
        this.accountService.authorised$.subscribe(authorised => {
            if (authorised) {
                this.extend(localStorage.getItem(`${this.options.portalType}.id_token`));
            } else {
                if (this.refreshTimeout) {
                    clearTimeout(this.refreshTimeout);
                }
            }
        });

        this.initIdleLogout();
    }

    refreshToken() {
        const url = `${this.options.apiEndPoint}api/${this.options.portalType}/refresh`;
        return this.http.get<any>(url)
        .pipe(
            map((response: any) => { return response.value; }),
            catchError(this.handleError)
        );
    }

    private initIdleLogout() {

        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onTimeout.subscribe(() => {
            this.accountService.logout();
        });

        this.accountService.authorised$.subscribe(authorised => {
            if (authorised) {
                if (this.options.pharmacyId) {
                    this.idle.setIdle(60);
                    this.idle.setTimeout(20);
                } else {
                    const token = localStorage.getItem(`${this.options.portalType}.id_token`);
                    const duration = this.getTokenDuration(token);
                    this.idle.setIdle(duration);
                    this.idle.setTimeout(0);
                }
                this.idle.watch();
            } else {
                // this.idle.stop(); // error when logout()
            }
        });
    }

    private extend(token) {
        let diff = this.getTokenDuration(token);
        diff *= 0.9;

        this.refreshTimeout = setTimeout(() => {
            this.refreshToken().subscribe(newToken => {
                localStorage.setItem(`${this.options.portalType}.id_token`, newToken);
                this.extend(newToken);
            });
        }, diff);
    }

    private getTokenDuration(token) {
        const now = new Date();
        const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
        return expirationDate.getTime() - now.getTime();
    }

    private handleError(response: any) {
        return throwError(response);
    }
}
