<div class="comment-container">
    <ng-container *ngIf="!!response.patientText && !!response.pharmacyText">
        <p>
            <b>Patient's Comment:</b><br/>
            {{response.patientText}}
        </p>
        <p>
            <b>Pharmacist's Comment:</b><br/>
            {{response.pharmacyText}}
        </p>
    </ng-container>
    
    <ng-container *ngIf="!!response.patientText && !response.pharmacyText">
        <p>
            <b>Patient's Comment:</b><br/>
            {{response.patientText}}
        </p>
    </ng-container>

    <ng-container *ngIf="!response.patientText && !!response.pharmacyText">
        <p>
            <b>Pharmacist's Comment:</b><br/>
            {{response.pharmacyText}}
        </p>
    </ng-container>
</div>