import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleModule } from '@ng-idle/core';
import { AppRoutingModule } from './app-routing.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskModule } from 'ngx-mask';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AuthGuard } from './account/auth.guard';
import { AccountService } from './account/account.service';

import { UserService } from './account/user.service';
import { OrderService } from './orders/order.service';
import { OffersService } from './offers/offers.service';
import { FormularyService } from './formulary/formulary.service';
import { PatientService } from './patients/patient.service';
import { KeepAliveService } from './account/keep-alive.service';

import { RepeatService } from './repeats/repeat.service';
import { FormService } from './orders/form.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './account/login.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order.component';
import { OffersComponent } from './offers/offers.component';
import { OrderActionsComponent } from './orders/order-actions.component';
import { RepeatsComponent } from './repeats/repeats.component';
import { RepeatComponent } from './repeats/repeat.component';
import { MyAccountComponent } from './account/my-account.component';
import { OrderFilterPipe } from './orders/order-filter.pipe';
import { OrderMissingPricePipe } from './orders/order-missing-price.pipe';
import { OrderStatusPipe } from './orders/order-status.pipe';
import { OrderStatePipe } from './orders/order-state.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { AddressComponent } from './address.component';
import { ChangePasswordComponent } from './account/change-password.component';
import { ChangePinComponent } from './account/change-pin.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { ResetPinComponent } from './account/reset-pin.component';
import { environment } from '../environments/environment';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireService } from './questionnaire/questionnaire.service';
import { GoogleChartsModule } from 'angular-google-charts';
import { DataService } from './common/data.service';
import { EqualValidator } from './common/equal-validator.directive';
import { QuestionnaireSingleItemComponent } from './questionnaire/questionnaire-single-item.component';
import { FormularyComponent } from './formulary/formulary.component';
import { SettingsComponent } from './account/settings.component';

import { VerifyPrescriptionComponent } from './prescriptions/verify-prescription.component';
import { DataLoggingService } from './data-logging.service';
import { MaskedInputComponent } from './common/masked-input.component';
import { ClinicLayout } from './clinic/layout/clinic-layout.component';
import { Consultations } from './clinic/consultations/consultations.component';
import { CreatePatient } from './clinic/create-consultation/create-patient.component';
import { ConsultationTriageComponent } from './clinic/consultation-triage/consultation-triage.component';
import { PegFormComponent } from './clinic/consultation-triage/peg-form.component';
import { GadFormComponent } from './clinic/consultation-triage/gad-form.component';
import { AuditFormComponent } from './clinic/consultation-triage/audit-form.component';
import { PatientInfoConfirmFormComponent } from './clinic/consultation-triage/patient-info-confirm-form.component';
import { InfoSliderComponent } from './clinic/consultation-triage/info-slider.component';
import { ProgressBarComponent } from './clinic/consultation-triage/progress-bar.component';
import { ConsultationSuccess } from './clinic/consultation-success/consultation-success.component';
import { ConsultationReviewComponent } from './clinic/consultation/consultation-review.component';
import { ConsultationViewComponent } from './clinic/consultation/consultation-view.component';
import { QuestionnaireBriefComponent } from './clinic/questionnaire-brief/questionnaire-brief.component';
import { CommentLabelComponent } from './clinic/questionnaire-brief/comment-label.component';
import { PatientComponent } from './clinic/consultation/patient-details.component';
import { KycImageUploadComponent } from './clinic/create-consultation/kyc-image-upload.component';
import { ImageUploaderDirective } from './directives/image-uploader.directive';

const globalOptions = {
  apiEndPoint: environment.apiEndPoint,
  openTokApiKey: environment.openTokApiKey,
  portalType: 'pharmacy',
};

export function tokenGetter() {
  return (<any>window).localToken || localStorage.getItem('pharmacy.id_token');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    GoogleChartsModule,
    NgIdleModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          'localhost:8080',
          'api.alt.thegpservice.com',
          'api.demo.thegpservice.com',
          'api.training.thegpservice.co.uk',
          'api.thegpservice.co.uk',
          'api.dev.treatit.thegpservice.com',
          'api.treat-it.clinic',
        ],
        disallowedRoutes: ['/api/public'],
      },
    }),
    TypeaheadModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    OrderComponent,
    OrdersComponent,
    OffersComponent,
    OrderActionsComponent,
    RepeatsComponent,
    RepeatComponent,
    OrderFilterPipe,
    OrderMissingPricePipe,
    OrderStatePipe,
    OrderStatusPipe,
    OrderByPipe,
    AddressComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    ChangePinComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPinComponent,
    QuestionnaireComponent,
    QuestionnaireSingleItemComponent,
    EqualValidator,
    FormularyComponent,
    SettingsComponent,
    VerifyPrescriptionComponent,
    MaskedInputComponent,
    ClinicLayout,
    Consultations,
    CreatePatient,
    ConsultationTriageComponent,
    PegFormComponent,
    GadFormComponent,
    AuditFormComponent,
    PatientInfoConfirmFormComponent,
    InfoSliderComponent,
    ProgressBarComponent,
    ConsultationSuccess,
    ConsultationReviewComponent,
    ConsultationViewComponent,
    QuestionnaireBriefComponent,
    CommentLabelComponent,
    PatientComponent,
    KycImageUploadComponent,
    ImageUploaderDirective,
  ],
  providers: [
    KeepAliveService,
    RepeatService,
    AccountService,
    UserService,
    OrderService,
    OffersService,
    FormularyService,
    FormService,
    PatientService,
    AuthGuard,
    QuestionnaireService,
    DataService,
    DataLoggingService,
    { provide: 'global_options', useValue: globalOptions },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
