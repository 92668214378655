
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from './order.service';
import { UserService } from '../account/user.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {
    @ViewChild('prescriptionModal', { static: true }) prescriptionModal: ModalDirective;
    order: any;
    doctor: any;
    prescribingPharmacy: any;
    isPharmacyConsultation: boolean;
    patient: any;
    form: [string, string][];
    id: number;
    pharmacy: any;
    prescribedTreatmentsArrayFormatted: any[];
    prescription: any;
    showWatermark: boolean = true;
    medicationsContainUnlicensedUse: boolean = false;
    logoUrl: string = '/assets/img/logo.png';

    private sub: Subscription;
    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private userService: UserService) { }

    async ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.reload();
        });
        this.pharmacy = await this.userService.getUser().toPromise();
    }

    reload(): void {
        this.orderService.getOrderItem(this.id).subscribe(order => {
            this.orderService.getPrescription(this.id).subscribe(prescription => {
                this.order = order;
                if (this.order.company) {
                    this.logoUrl = this.order.company.logoUrl;
                }
                this.isPharmacyConsultation = order.consultationDuration === "IN_PERSON_MINUTE_00"
                this.doctor = order.orderNotes.find(item => item.type === 'SPECIALIST_DECISION').doctor || undefined
                this.prescribingPharmacy = order.prescribingPharmacist
                this.patient = this.order.patient;
                this.prescription = prescription;
                if (['LEGAL_FOR_PHARMACY', 'DISPENSED_BY_PHARMACY'].includes(this.prescription.status)) {
                    this.showWatermark = false;
                }
                if (this.prescription && this.prescription.medication) {
                    this.prescription.medication.forEach((med: any) => {
                        if (med.unlicensedUse) {
                            this.medicationsContainUnlicensedUse = true;
                        }
                    });
                }
            });
        });
    }

    print() {
        window.print();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        (<any>window).localToken = null;
        this.orderService.isVerification = null;
    }

}
