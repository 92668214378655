<section class="container">
  <h1>Offers</h1>
  <p>The offers below are free to use, just pass the Discount Code to the customer and it can be used during the Video appointment booking process</p>
  <p><em>Last updated = {{lastUpdated | date: 'yMMMd'}}</em></p>
  <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>
  <ul class="list-unstyled">
    <li *ngFor="let offer of offers" class="coupon" [ngClass]="{disabled: offer.used}">
      <div class="discount--holder {{offer.discountClass}}">
        <div class="col-md-4 text-center">
          <h2><span>{{offer.percentage | percent}}</span> Discount</h2>
        </div>
      </div>
      <div class="col-md-8">
        <h3>Code: {{offer.id}}</h3>
        <h4>Status: <span *ngIf="!offer.used">Available</span><span *ngIf="offer.used">Taken</span></h4>
        <p><small><strong>Expires:</strong> {{offer.expirationDate | date : 'dd/MM/yyyy'}}</small></p>
      </div>
    </li>
  </ul>
</section>
