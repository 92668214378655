import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  feedbackType,
  questionnaire,
  questionType,
  modalType
} from 'src/assets/clinic-questionnaire';
import { AUDIT_FORM, CONSENT_FORM, CONSENT_VIEW_FORM, GAD_FORM, PEG_FORM } from 'src/assets/patientQuestionnaire';

@Component({
  selector: 'app-questionnaire-brief',
  templateUrl: './questionnaire-brief.component.html',
  styleUrls: ['./questionnaire-brief.component.scss'],
})
export class QuestionnaireBriefComponent implements OnInit, OnChanges {
  @Input() viewOnly: boolean = false;
  @Input() orderId: number;
  @Input() notes: any = {};
  @Input() orderItem;

  protected showSupportText = false;
  protected selectedSupportText: any;
  protected pegNote:any;
  protected gadNote:any;
  protected auditNote:any;
  protected consentNote:any;

  isIncomplete = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      for (let i = 0; i < this.orderItem.orderNotes.length; i++) {
        const note = this.orderItem.orderNotes[i];
        if (note.type === 'PEG_FORM') {
          this.pegNote = JSON.parse(note.questionAnswer || '{}');
          continue;
        }
  
        if (note.type === 'GAD_FORM') {
          this.gadNote = JSON.parse(note.questionAnswer || '{}');
          continue;
        }
  
        if (note.type === 'AUDIT_FORM') {
          this.auditNote = JSON.parse(note.questionAnswer || '{}');
          continue;
        }
  
        if (note.type === 'PATIENT_DETAIL_CONFIRMATION') {
          this.consentNote = JSON.parse(note.questionAnswer || '{}');
          continue;
        }
      }
    }
  }

  hideSlider(){
    this.showSupportText = false;
    this.selectedSupportText = undefined;
  }

  getRowStatus(step: string, key: string){
    const question = this.getQuestion(step, key);
    const noteValue = this.notes[`${step}|${key}`];
    let className = "success-bg";

    if (question.responseType === feedbackType.text) {
      className = !!noteValue.patientText || !!noteValue.pharmacyText ? 'success-bg' : 'danger-bg';
    }

    if ([feedbackType.choice, feedbackType.choiceAndTextOnCondition].includes(question.responseType)) {
      className = noteValue.choice != undefined && noteValue.choice != null ? 'success-bg' : 'danger-bg';
    }

    if ([feedbackType.checkbox, feedbackType.checkboxAndText].includes(question.responseType)) {
      const checkedItem = noteValue.choices.find(item => item.checked)
      className = !!checkedItem ? 'success-bg' : 'danger-bg';
    }

    if (question.responseType === feedbackType.checkboxQuestion) {
      const checkedItem = noteValue.choices.find(item => !item.checked)
      className = !!checkedItem ? 'danger-bg' : 'success-bg';
    }

    if (question.responseType === feedbackType.externalForm) {
      switch (question.modalDetails.formType) {
        case modalType.PEG:
          className = !this.pegNote?.average ? 'danger-bg' : 'success-bg';
          break;

        case modalType.GAD:
          if (!!this.gadNote && Object.keys(this.gadNote).length) {
            const hasEmptyValues = Object.keys(GAD_FORM).some(key => [null, undefined].includes(this.gadNote[key]))
            if (hasEmptyValues) {
              className = 'warning-bg';
            }
          } else {
            className = 'danger-bg';
          }
          break;

        case modalType.AUDIT:
          if (!!this.auditNote && Object.keys(this.auditNote).length) {
            const hasEmptyValues = Object.keys(AUDIT_FORM).some(key => [null, undefined].includes(this.auditNote[key]))
            if (hasEmptyValues) {
              className = 'warning-bg';
            }
          } else {
            className = 'danger-bg';
          }
          break;

        case modalType.PERSONAL:
          if (!!this.consentNote && Object.keys(this.consentNote).length) {
            const hasEmptyValues = Object.keys(CONSENT_FORM).some(key => [null, undefined, false].includes(this.consentNote[key]))
            if (hasEmptyValues) {
              className = 'warning-bg';
            }
          } else {
            className = 'danger-bg';
          }
          break;
      
        default:
          break;
      }
    }

    if (['danger-bg', 'warning-bg'].includes(className)) {
      this.isIncomplete = true;
    }
    return className
  }

  getQuestion(step: string, key: string) {
    return questionnaire.data[step].data[key];
  }

  getResponse(step: string, key: string) {
    return this.notes[`${step}|${key}`];
  }

  getCheckedChoices(step: string, key: string){
    return this.notes[`${step}|${key}`].choices.filter(choice => choice.checked)
  }

  renderQuestion(step: string, key: string) {
    const question = this.getQuestion(step, key);
    return (
      !question.hasOwnProperty('header') &&
      // question.responseType !== feedbackType.externalForm &&
      // this.notes.hasOwnProperty(`${step}|${key}`) &&
      this.validateRenderCondition(question)
    );
  }

  validateRenderCondition(question: questionType) {
    if (!question.hasOwnProperty('renderCondition')) {
      return true;
    }
    const { stepId, questionId } = question.renderCondition;
    const masterQuestion = questionnaire.data[stepId].data[questionId];
    const masterAnswer = this.notes[`${stepId}|${questionId}`];

    if (
      [feedbackType.choice, feedbackType.choiceAndTextOnCondition].includes(
        masterQuestion.responseType
      )
    ) {
      return masterAnswer.choice;
    }

    return true;
  }

  routeToQuestionnaire(step: string) {
    this.router.navigateByUrl(`clinic/${this.orderId}/questionnaire/${step}`);
  }

  get questionnaire() {
    return questionnaire;
  }

  get feedbackType() {
    return feedbackType;
  }

  get modalType(){
    return modalType;
  }

  get pegForm(){
    return PEG_FORM;
  }

  get gadForm(){
    return GAD_FORM;
  }

  get auditForm(){
    return AUDIT_FORM;
  }

  get consentForm(){
    return CONSENT_VIEW_FORM;
  }
}
