<div *ngIf="isSubmitting"
    style="width: 100%; height: 100%; position: fixed; background: #dddddd66; z-index: 200; top: 0;">
    <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <i class="fa fa-spinner fa-2x fa-spin"></i>
        Loading...</span>
</div>

<div class="stepper-container">
    <div class="stepper">
        <span>Step {{stepPosition}}</span>
        <div class="stepper-bar-container">
            <div class="background" [style.width]="stepPercentage+'%'"></div>
            <div class="viewer"></div>
        </div>
        <div class="nav-btn-container">
            <button *ngIf="stepPosition > 1" class="btn btn-default" (click)="toPreviousPage()">Previous</button>
            <button *ngIf="stepPosition < 7" class="btn btn-primary" (click)="toNextPage()" [disabled]="isSubmitDisabled">Save & Continue</button>
            <button *ngIf="stepPosition == 7" class="btn btn-primary" (click)="toReview()" [disabled]="isSubmitDisabled">Save & Continue</button>
            <button *ngIf="(questionnaireCompleted === 'true' && stepPosition < 8)" class="btn btn-primary" (click)="fastForwardToReview()" [disabled]="isSubmitDisabled">Go to Review Page</button>
            <button *ngIf="stepPosition == 8" class="btn btn-primary" (click)="openPinModal()">Submit Consultation</button>
        </div>
    </div>
    <div #formContainer class="step-content">
        <ng-content></ng-content>
    </div>
    <div class="nav-btn-container">
        <button *ngIf="stepPosition > 1" class="btn btn-default" (click)="toPreviousPage()">Previous</button>
        <button *ngIf="stepPosition < 7" class="btn btn-primary" (click)="toNextPage()" [disabled]="isSubmitDisabled">Save & Continue</button>
        <button *ngIf="stepPosition == 7" class="btn btn-primary" (click)="toReview()" [disabled]="isSubmitDisabled">Save & Continue</button>
        <button *ngIf="(questionnaireCompleted === 'true' && stepPosition < 8)" class="btn btn-primary" (click)="fastForwardToReview()" [disabled]="isSubmitDisabled">Go to Review Page</button>
        <button *ngIf="stepPosition == 8" class="btn btn-primary" (click)="openPinModal()">Submit Consultation</button>
    </div>
</div>

<div bsModal #pinModal="bs-modal" id="pinModal" class="modal fade">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="pinModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">Enter Pin</h3>
            </div>
            <div class="modal-body">
                <form #registerUserForm="ngForm" novalidate autocomplete="off">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 form-group form-group-lg">
                            <label style="font-size: 1.7rem;" for="pin">Pin <span style="color: red;">*</span></label>
                            <input id="pin" name="pin" type="password" required [(ngModel)]="pin" class="form-control" />
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="handleSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>