<div class="login">
    <div class="central_panel">

        <div class="well well--white">

            <img class="site_logo" src="/assets/img/logo.png" alt="The Treat it" />

            <h1 class="brand-1">Pharmacy Portal</h1>
            <p>Welcome to the Treat it Pharmacy Portal. Please provide your details below in order to login to view your orders</p>
            <form #loginForm="ngForm" (ngSubmit)="onLogin()">
                <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>

                <div class="form-group">
                    <label for="username">User name</label>
                    <input id="username" name="username" type="text" required [(ngModel)]="model.username" class="form-control" />
                </div>

                <div class="form-group">
                    <label for="password">Password</label>
                    <input id="password" name="password" type="password" autocomplete="new-password" required [(ngModel)]="model.password" class="form-control"
                    />
                </div>
                <p>
                    <a routerLink="/forgot-password">Forgot your Password?</a>
                </p>
                <p>
                    <button class="btn btn-primary" type="submit" [disabled]="!loginForm.form.valid">Submit</button>
                </p>
            </form>

        </div>


    </div>
</div>
