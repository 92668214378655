
import { throwError,  BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { AccountService } from './account.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserService {

    get user$() {
        return this._user$.asObservable();
    }
    user: any;

    private _user$ = new BehaviorSubject(null);

    constructor(
        private accountService: AccountService,
        private http: HttpClient,
        @Inject('global_options') private options: any) {

        accountService.authorised$.subscribe((authorised) => {
            if (authorised) {
                this.getUser().subscribe(u => { this._user$.next(u); });
            } else {
                this._user$.next(null);
            }
        });
        this.user$.subscribe(u => {
            this.user = u;
        });
    }

    refreshToken() {
        const url = `${this.options.apiEndPoint}api/${this.options.portalType}/refresh`;
        return this.http.get<any>(url)
            .pipe(catchError(this.handleError))
            .subscribe(x => {
                this.accountService.authenticate(x);
            });
    }

    getUser() {
        const url = `${this.options.apiEndPoint}api/${this.options.portalType}/me`;
        return this.http.get<any>(url)
            .pipe(catchError(this.handleError));
    }

    changePassword(oldPassword: string, newPassword: string, type = 'PASSWORD') {
        return this.http.put<any>(`${this.options.apiEndPoint}api/${this.options.portalType}/password?type=${type}`, {
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmNewPassword: newPassword
            })
            .pipe(catchError(this.handleError));
    }

    changePin(oldPin: string, newPin: string) {
        return this.changePassword(oldPin, newPin, 'PIN');
    }

    updateCoopProperties(minimumCharge: number, professionalFee: number, markupFactor: number) {
        return this.http.put<any>(`${this.options.apiEndPoint}api/${this.options.portalType}/coop-properties/update`, {
                minimumCharge: minimumCharge,
                professionalFee: professionalFee,
                markupFactor: markupFactor
            })
            .pipe(catchError(this.handleError));
    }

    getCoopProperties() {
        const url = `${this.options.apiEndPoint}api/${this.options.portalType}/coop-properties`;
        return this.http.get<any>(url)
            .pipe(catchError(this.handleError));
    }

    private handleError(response: any) {
        return throwError(response);
    }
}
