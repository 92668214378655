export const PEG_FORM = {
  painInPastWeek:
    'What number best describes your pain on average in the past week?',
  painInterferenceWithEnjoyment:
    'What number best describes how, during the past week, pain has interfered with your enjoyment of life?',
  painInterferenceWithGeneralActivity:
    'What number best describes how, during the past week, pain has interfered with your general activities?',
};

export const GAD_FORM = {
  feeling: {
    label: 'Feeling nervous, anxious or on edge?',
    values: {
      0: 'Not at all',
      1: 'Several days',
      2: 'More than half the days',
      3: 'Nearly every day',
    },
  },
  worrying: {
    label: 'Not being able to stop or control worrying?',
    values: {
      0: 'Not at all',
      1: 'Several days',
      2: 'More than half the days',
      3: 'Nearly every day',
    },
  },
};

export const AUDIT_FORM = {
  question1: {
    label: 'How often do you have a drink containing alcohol?',
    values: {
      0: 'Never',
      1: 'Monthly or less',
      2: '2 to 4 times per month',
      3: '2 to 3 times per week',
      4: '4 times or more per week',
    },
  },
  question2: {
    label:
      'How many units of alcohol do you drink on a typical day when you are drinking?',
    values: {
      0: '0 to 2',
      1: '3 to 4',
      2: '5 to 6',
      3: '7 to 9',
      4: '10 or more',
    },
  },
  question3: {
    label:
      'How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question4: {
    label:
      'How often during the last year have you found that you were not able to stop drinking once you had started?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question5: {
    label:
      'How often during the last year have you failed to do what was normally expected from you because of your drinking?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question6: {
    label:
      'How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question7: {
    label:
      'How often during the last year have you had a feeling of guilt or remorse after drinking?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question8: {
    label:
      'How often during the last year have you been unable to remember what happened last night before because you had been drinking?',
    values: {
      0: 'Never',
      1: 'Less than monthly',
      2: 'Monthly',
      3: 'Weekly',
      4: 'Daily or almost daily',
    },
  },
  question9: {
    label:
      'Have you or somebody else been injured as a result of your drinking?',
    values: {
      0: 'No',
      1: 'Yes, but not in the last year',
      2: 'Yes, during the last year',
    },
  },
  question10: {
    label:
      'Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?',
    values: {
      0: 'No',
      1: 'Yes, but not in the last year',
      2: 'Yes, during the last year',
    },
  },
};

export const CONSENT_FORM = {
  ageOver25: 'They are over 18 or over and a UK resident. <span style="color: red;">*</span>',
  answeredAssessment:
    'They have answered the online medical assessment truthfully with the knowledge that giving false information to obtain medicines could lead to a medicine being prescribed that is harmful to my health. <span style="color: red;">*</span>',
  soleUseAcceptance: 'Any medicine prescribed is for their sole use. <span style="color: red;">*</span>',
  agreeToReadInfo:
    'They have agreed to read the patient information leaflet that will accompany the medicine. <span style="color: red;">*</span>',
  understandTnc: "They have read and understood the <a style='font-size:1.7rem' href='https://app.treat-it.clinic/terms-condition' target='_blank'>terms and conditions</a>. <span style='color: red;'>*</span>",
};

export const CONSENT_VIEW_FORM = {
  ageOver25: 'They are over 18 or over and a UK resident.',
  answeredAssessment:
    'They have answered the online medical assessment truthfully with the knowledge that giving false information to obtain medicines could lead to a medicine being prescribed that is harmful to my health.',
  soleUseAcceptance: 'Any medicine prescribed is for their sole use.',
  agreeToReadInfo:
    'They have agreed to read the patient information leaflet that will accompany the medicine.',
  understandTnc: "They have read and understood the terms and conditions.",
};