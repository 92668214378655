<div class="container">
    <div class="central_panel">

        <h2>Change settings</h2>

        <div class="alert alert--error" *ngIf="!response?.success && response?.message">
            {{response.message}}
        </div>

        <div *ngIf="response?.success">
            <div class="alert alert--success">
                Settings changed successfully
            </div>
            <a class="btn btn--o_3" routerLink="/my-account/orders">
                <i class="fa fa-arrow-left"></i>Back to my account
            </a>
        </div>

        <br/>

        <form #form="ngForm" autocomplete="off" novalidate [ngClass]="{submitted: form.submitted}" (ngSubmit)="form.valid && submit()" *ngIf="!response?.success">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <label for="minimumCharge">Minimum Charge</label>
                    <div class="input-group">
                        <span class="input-group-addon">£</span>
                        <input id="minimumCharge" name="minimumCharge" type="number" class="form-control" [(ngModel)]="minimumCharge" (keypress)="isNumber($event)" min="0" step="0.01" required />
                    </div>
                </div>
                <div class="col-sm-4 form-group">
                    <label for="professionalFee">Professional Fee</label>
                    <div class="input-group">
                        <span class="input-group-addon">£</span>
                        <input id="professionalFee" name="professionalFee" type="number" class="form-control" [(ngModel)]="professionalFee" (keypress)="isNumber($event)" min="0" step="0.01" required/>
                    </div>
                </div>
                <div class="col-sm-4 form-group">
                    <label for="markupFactor">Markup Factor</label>
                    <div class="input-group">
                        <input id="markupFactor" name="markupFactor" type="number" class="form-control" [(ngModel)]="markupFactor" (keypress)="isNumber($event)" min="0" step="0.01" required/>
                        <span class="input-group-addon">%</span>
                    </div>
                </div>
            </div>

            <div class="alert alert-info">
                <strong>Please note:</strong>
                <ul>
                    <li>Updating these settings will not impact existing prescriptions.</li>
                </ul>
            </div>
            <div class="pull-right">
                <button class="btn btn-success" type="submit">Update</button>
            </div>
        </form>
    </div>
</div>