<div class="notes-container">
    <div class="note-row header">
        <div class="question text-center"><b>Question</b></div>
        <div class="description"><b>Comments</b></div>
    </div>
    <section *ngFor="let step of questionnaire.urlParam" class="notes-section">
        <div class="header-container">
            <h3><b>{{questionnaire.data[step].title}}</b></h3>
            <button *ngIf="!viewOnly" class="btn btn-default" (click)="routeToQuestionnaire(step)">
                <span>&#9998;</span>Edit this Section
            </button>
        </div>
        <ng-container *ngFor="let key of questionnaire.data[step].keys">
            <div *ngIf="renderQuestion(step, key)" class="note-row" [class]="getRowStatus(step, key)">

                <!-- feedbackType.text -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.text">
                    <div class="question">{{getQuestion(step, key).question}}</div>
                    <div class="description">
                        <app-comment-label *ngIf="getRowStatus(step, key) === 'success-bg'; else errorPlaceholder" 
                            [response]="getResponse(step, key)"></app-comment-label>
                        <ng-template #errorPlaceholder>
                            <b><i>Patient's Response & Pharmacist Comment is required.</i></b>
                        </ng-template>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.externalForm">
                    <div class="question">
                        <ng-container [ngSwitch]="getQuestion(step, key).modalDetails.formType">
                            <span *ngSwitchCase="modalType.PEG">2.1.1 PEG Form</span>
                            <span *ngSwitchCase="modalType.GAD">5.1.1 GAD Form</span>
                            <span *ngSwitchCase="modalType.AUDIT">5.2.3 AUDIT Form</span>
                            <span *ngSwitchCase="modalType.PERSONAL">6.4.1 Consent Form</span>
                        </ng-container>
                    </div>
                    <div class="description" style="display: flex; column-gap: 1rem;">
                        <ng-container [ngSwitch]="getQuestion(step, key).modalDetails.formType">
                            <ng-container *ngSwitchCase="modalType.PEG">
                                <span *ngIf="getRowStatus(step, key) === 'danger-bg'; else hasForm">
                                    <b><i>The PEG form is incomplete.</i></b>
                                </span>
                                <ng-template #hasForm>
                                    <button class="btn btn-default" (click)="PEGModal.show()">View filled PEG Form</button>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="modalType.GAD">
                                <span *ngIf="getRowStatus(step, key) === 'danger-bg'; else hasForm">
                                    <b><i>The GAD form is incomplete..</i></b>
                                </span>
                                <ng-template #hasForm>
                                    <button class="btn btn-default" (click)="GADModal.show()">View filled GAD Form</button>
                                    <span *ngIf="getRowStatus(step, key) === 'warning-bg'">
                                        <b><i>The GAD form has been completed only partially</i></b>
                                    </span>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="modalType.AUDIT">
                                <span *ngIf="getRowStatus(step, key) === 'danger-bg'; else hasForm">
                                    <b><i>AUDIT form is required.</i></b>
                                </span>
                                <ng-template #hasForm>
                                    <button class="btn btn-default" (click)="AUDITModal.show()">View filled AUDIT Form</button>
                                    <span *ngIf="getRowStatus(step, key) === 'warning-bg'">
                                        <b><i>The AUDIT form is incomplete.</i></b>
                                    </span>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="modalType.PERSONAL">
                                <span *ngIf="getRowStatus(step, key) === 'danger-bg'; else hasForm">
                                    <b><i>Consent form is required.</i></b>
                                </span>
                                <ng-template #hasForm>
                                    <button class="btn btn-default" (click)="consentModal.show()">View filled Consent Form</button>
                                    <span *ngIf="getRowStatus(step, key) === 'warning-bg'">
                                        <b><i>The consent form is incomplete.</i></b>
                                    </span>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <!-- feedbackType.choice || feedbackType.choiceAndTextOnCondition -->
                <ng-container
                    *ngIf="[feedbackType.choice,feedbackType.choiceAndTextOnCondition].includes(getQuestion(step, key).responseType)">
                    <div class="question">{{getQuestion(step, key).question}}</div>
                    <div *ngIf="getRowStatus(step, key) === 'success-bg'; else errorPlaceholder" class="description">
                        <div>
                            <ng-container [ngSwitch]="getResponse(step, key).choice">
                                <span *ngSwitchCase="true" class="label label-primary">Yes</span>
                                <span *ngSwitchCase="false" class="label label-warning">No</span>
                                <span *ngSwitchDefault>{{getResponse(step, key).choice}}</span>
                            </ng-container>
                        </div>
                        <div>
                            <app-comment-label
                            *ngIf="getQuestion(step, key).responseType === feedbackType.choiceAndTextOnCondition"
                            [response]="getResponse(step, key)"></app-comment-label>
                        </div>
                    </div>
                    <ng-template #errorPlaceholder>
                        <div class="description">
                            <b><i>Input is required.</i></b>
                        </div>
                    </ng-template>
                </ng-container>

                <!-- feedbackType.checkbox -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkbox">
                    <div class="question">{{getQuestion(step, key).question}}</div>
                    <div *ngIf="getRowStatus(step, key) === 'success-bg'; else errorPlaceholder" class="description" >
                        <ng-container *ngFor="let choice of getResponse(step, key).choices">
                            <div *ngIf="choice.checked">&#9679; {{choice.label}}</div>
                        </ng-container>
                    </div>
                    <ng-template #errorPlaceholder>
                        <div class="description">
                            <b><i>Select one or more items or mark none of the above.</i></b>
                        </div>
                    </ng-template>
                </ng-container>

                <!-- feedbackType.checkboxQuestion -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkboxQuestion">
                    <ng-container *ngIf="getRowStatus(step, key) === 'success-bg'; else errorPlaceholder">
                        <div class="question">{{getQuestion(step, key).question}}</div>
                        <div class="description">&nbsp;</div>
                        <ng-container *ngFor="let choice of getResponse(step, key).choices; index as i">
                            <ng-container *ngIf="choice.checked">
                                <div class="question">{{choice.label}}</div>
                                <div class="description">Advised</div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #errorPlaceholder>
                        <div class="question">{{getQuestion(step, key).question}}</div>
                        <div class="description"><b><i>Selecting all of the given choices is mandatory.</i></b></div>
                    </ng-template>
                </ng-container>
                
                <!-- feedbackType.checkboxAndText -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkboxAndText">
                    <div class="question">{{getQuestion(step, key).question}}</div>
                    <div *ngIf="getRowStatus(step, key) === 'success-bg'; else errorPlaceholder" class="description">
                        <ng-container *ngFor="let choice of getCheckedChoices(step, key); index as i">
                            <div>&#9679; {{choice.label}}</div>
                            <div>
                                <app-comment-label [response]="choice"></app-comment-label>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #errorPlaceholder>
                        <div class="description">
                            <b><i>Selecting at least one of the given choices is mandatory.</i></b>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </ng-container>
    </section>
</div>

<div bsModal #PEGModal="bs-modal" id="PEGModal" class="modal fade">
    <div class="modal-dialog patient-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="PEGModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">PEG Form</h3>
            </div>
            <div *ngIf="!!pegNote" class="modal-body">
                <section class="patient-content">
                    <div class="note-score">Average: {{pegNote.average}}</div>
                    <div *ngFor="let item of pegForm | keyvalue" class="patient-response">
                        <h4>{{item.value}}</h4>
                        <span>{{pegNote[item.key]}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #GADModal="bs-modal" id="GADModal" class="modal fade">
    <div class="modal-dialog patient-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="GADModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">GAD Form</h3>
            </div>
            <div *ngIf="!!gadNote && gadNote !== {}" class="modal-body">
                <section class="patient-content">
                    <div class="note-score">Score: {{gadNote.score}}</div>
                    <div *ngFor="let item of gadForm | keyvalue" class="patient-response">
                        <h4>{{item.value.label}}</h4>
                        <span>{{item.value.values[gadNote[item.key]] || 'N/A'}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #AUDITModal="bs-modal" id="AUDITModal" class="modal fade">
    <div class="modal-dialog patient-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="AUDITModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">AUDIT Form</h3>
            </div>
            <div *ngIf="!!auditNote" class="modal-body">
                <section class="patient-content">
                    <div class="note-score">Score: {{auditNote.score}}</div>
                    <div *ngFor="let item of auditForm | keyvalue" class="patient-response">
                        <h4>{{item.value.label}}</h4>
                        <span>{{item.value.values[auditNote[item.key]] || 'N/A'}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #consentModal="bs-modal" id="consentModal" class="modal fade">
    <div class="modal-dialog patient-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="consentModal.hide()" aria-hidden="true">&times;</button>
                <h3 class="modal-title">Consent Form</h3>
            </div>
            <div *ngIf="!!consentNote" class="modal-body">
                <section class="patient-content">
                    <h3>The patient has confirmed that:</h3>
                    <ng-container *ngFor="let item of consentForm | keyvalue">
                        <div *ngIf="consentNote[item.key]" class="patient-response">
                            <div class="patient-statement">
                                <img src="/assets/vectors/success.svg" alt="">
                                <h4>{{item.value}}</h4>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </div>
        </div>
    </div>
</div>