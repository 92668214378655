import { Injectable, Input } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PrescribeRestrictionGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  async canActivate() {
    const user = await this.userService.user$
      .pipe(
        filter((u) => u != null),
        take(1)
      )
      .toPromise();

    if (user?.prescribingPharmacy) {
      return true;
    }

    return this.router.navigateByUrl('/orders');
  }
}
