<div class="container">

    <h1>NHS Repeat Prescriptions</h1>

    <p>Please find the prescriptions for your pharmacy below</p>

<ul class="nav nav-tabs">
    <li class="active"><a href="javascript:void(0)">Prescriptions</a></li>
    <li><a href="javascript:void(0)">Subscribers</a></li>
</ul>


    <table class="table table-striped">
        <thead>
            <tr>
                <th>ID</th>
                <th>Customer</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items">
                <td>{{item.id}}</td>
                <td>{{item.patientName}}</td>
                <td>
                    <button class="btn btn-primary" routerLink="/repeats/{{item.id}}">View</button>
                </td>
            </tr>
        </tbody>
    </table>
    
    <div *ngIf="items.length === 0" class="alert alert-info" role="alert">No prescriptions found</div>
    
</div>