import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from './order.service';
import { Observable } from 'rxjs';
import { AccountService } from '../account/account.service';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit, OnDestroy {

    orderItems: any[] = [];
    orderType = 'pending';
    lastUpdated: {};
    updatedInterval: any;
    searchQuery = '';
    currentPage = 0;
    lastPage = 0;
    deliveryOrder: boolean;
    virtualPharmacy: boolean;

    constructor(
        private orderService: OrderService,
        private accountService: AccountService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.loadOrders();
        this.updatedInterval = setInterval(() => {
          this.loadOrders();
        }, 600000);
    }

    ngOnDestroy() {
      if (this.updatedInterval) {
        clearInterval(this.updatedInterval);
      }
    }

    reload(order) {
        this.loadOrders();
    }

    loadOrdersOnTabChange(type = this.orderType){
      let func: Observable<any>;
      this.orderType = type;
      this.currentPage = 0;
      switch (this.orderType) {
          case 'pending':
              this.currentPage = 0;
              func = this.orderService.getAllPendingOrderItems(this.searchQuery, this.currentPage, 10);
              break;
          case 'completed':
              this.currentPage = 0;
              func = this.orderService.getAllCompletedOrderItems(this.searchQuery, this.currentPage, 10);
              break;
          case 'all':
              this.currentPage = 0;
              func = this.orderService.getAllOrderItems(this.searchQuery, this.currentPage, 10);
              break;
      }

      func.subscribe((orders) => {
          this.lastUpdated = new Date();
          this.orderItems = orders.content;
          this.lastPage = orders.totalPages ? orders.totalPages - 1 : 0;
          this.accountService.info().subscribe((options) => {
              this.deliveryOrder = this.orderItems.every(i => i.pharmacy.delivers == true);
              this.virtualPharmacy = this.orderItems.every(i => i.pharmacy.virtual == true);
          });
      });
    }

    loadOrders(type = this.orderType) {
        let func: Observable<any>;
        this.orderType = type;
        switch (this.orderType) {
            case 'pending':
                func = this.orderService.getAllPendingOrderItems(this.searchQuery, this.currentPage, 10);
                break;
            case 'completed':
                func = this.orderService.getAllCompletedOrderItems(this.searchQuery, this.currentPage, 10);
                break;
            case 'all':
                func = this.orderService.getAllOrderItems(this.searchQuery, this.currentPage, 10);
                break;
        }

        func.subscribe((orders) => {
            this.lastUpdated = new Date();
            this.orderItems = orders.content;
            this.lastPage = orders.totalPages ? orders.totalPages - 1 : 0;
            this.accountService.info().subscribe((options) => {
                this.deliveryOrder = this.orderItems.every(i => i.pharmacy.delivers == true);
                this.virtualPharmacy = this.orderItems.every(i => i.pharmacy.virtual == true);
            });
        });
    }

    search() {
        this.loadOrders();
    }

    changePage(page: number) {
        this.currentPage = page;
        this.loadOrders();
    }
    isNumberOrLetter(event) {
        const pattern = /^[a-z0-9]+$/i;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}
