import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

    email: string;
    response: any;
    errorMessage: any;

    constructor(private accountService: AccountService) { }

    ngOnInit() { }

    submit() {
        this.accountService.forgotPassword(this.email).subscribe(
          (response) => {
            this.response = true;
            this.errorMessage = false;
          },
          (error) => {
            if (error.status === 401) {
              this.errorMessage = 'Unable to process';
            } else {
              this.errorMessage = 'Something went wrong';
            }
          });
    }
}
