<div class="container">
    <div class="summary">
        <div class="well well--white">
            <a class="btn btn-success btn-1" routerLink="/questionnaire">Surveys</a>
            <button type="button" class="btn btn-success btn-1" (click)="questionnaireService.printReport()">Print</button>
            <h2 class="brand-1">Individual Surveys</h2>
            <p>This feature provides the data collected for each individual questionnaire from <strong>{{questionnaireService.startDate | date}}</strong> to <strong>{{questionnaireService.endDate | date}}</strong> 
            at <strong>{{questionnaireService.pharmacy?.displayName}}</strong></p>
        </div>
        <div *ngFor="let questionnaire of individualSets" class="well well--white questionInfo">
            <div *ngFor="let item of questionnaire">
                <h3 class="brand-1" *ngIf="item.key==='q1_answer'">Questionnaire ID: {{item.id}}</h3>

                <h4 class="brand-1" *ngIf="item.key !== 'q1_answerdesc'" [ngClass]="{'selection-input': questionnaireService.questionsjson[item.key].type=='selection-input', 'selection-input-subgroup': questionnaireService.questionsjson[item.key]?.group }">
                    {{ questionnaireService.questionsjson[item.key].question }}
                </h4>

                <div *ngIf="questionnaireService.questionsjson[item.key].type==='selection'">
                    <div class="answer-box" *ngFor="let answer of questionnaireService.questionsjson[item.key].answers">
                        <div [ngClass]="{'individual-set-checked':answer.value==item.answer, 'individual-set-unchecked':answer.value!=item.answer}">
                            {{ answer.desc }}
                        </div>
                    </div>
                </div>

                <div *ngIf="item.key === 'q1_answerdesc' && item.answer">
                    <h4 class="brand-1 selection-input">
                        {{ questionnaireService.questionsjson[item.key].question }}
                    </h4>
                    <div class="customer-inputs">
                        <p>{{ item.answer }}</p>
                    </div>
                </div>

                <div *ngIf="item.key === 'q10_answer'" class="customer-inputs">
                    <p>{{ item.answer ? item.answer : 'None given' }}</p>
                </div>
            </div>
        </div>

    </div>
</div>
