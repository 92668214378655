import { IEnvironmentType } from "src/types";

export const environment:IEnvironmentType = {
  production: true,
  apiEndPoint: 'https://api.treat-it.clinic/',
  openTokApiKey: '46057882',
  dataDog: {
    applicationId: '3ae5a3b6-dda8-4832-b6af-2716d569f1a4',
    clientToken: 'pub8c3c693ea2af9705132f5d41072a289a',
    service: 'treat-it-pharmacy-app',
    site: 'datadoghq.eu',
  },
  name: 'prod'
};
