import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-consultation-view',
  templateUrl: './consultation-view.component.html',
})
export class ConsultationViewComponent implements OnInit {
  patient: any = {};
  orderId: number;
  orderItem: any;
  gpNote: any = {};

  constructor(
    private route: ActivatedRoute,
    private clinicService: ClinicService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.orderId = params['orderId'];
    });

    this.getOrderItem();
  }

  private async getOrderItem() {
    this.orderItem = await lastValueFrom(
      this.clinicService.getOrderItem(this.orderId)
    );

    this.patient = this.orderItem.patient;
    const note = this.orderItem.orderNotes.find(
      (note) => note.type === 'GP_CONSULTATION_NOTES'
    );
    this.gpNote = !!note ? JSON.parse(note.questionAnswer) : {};
  }
}
