<div class="container login">

  <div class="central_panel">

    <div class="well well--white">

      <img class="site_logo" src="/assets/img/logo.png" alt="The Treat it" />
      <h1 class="brand-1">Reset Password</h1>

      <div class="alert alert--error" *ngIf="errorMessage">
        {{errorMessage}}
      </div>

      <div *ngIf="response">
        <div class="alert alert-success">{{response}}</div>
        <a class="btn btn--o_3" routerLink="/"><i class="fa fa-arrow-left"></i> Go back</a>
      </div>

      <form [formGroup]="passwordForm" autocomplete="off" novalidate [ngClass]="{submitted: isProcessing}"
            (ngSubmit)="submit()" *ngIf="!response">
        <div class="form_group">
          <label for="newPassword">New Password</label>
          <input id="newPassword" name="newPassword"
                 formControlName="password"
                 type="password"
                 class="form-control"
                 required
          />
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.password?.touched && passwordForm.controls?.password?.errors?.fieldError">
            {{passwordForm.controls?.password?.errors?.fieldError}}
          </div>
        </div>

        <div class="form_group" style="margin-top: 15px;">
          <label for="cPassword">Confirm New Password</label>
          <input id="cPassword" name="cPassword"
                 formControlName="passwordConfirmation"
                 type="password"
                 class="form-control"
          />
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.passwordConfirmation?.touched && passwordForm.errors?.passwordMismatch">
            {{passwordForm.errors?.passwordMismatch}}
          </div>
        </div>

        <div class="alert alert-info" style="margin-top: 20px;">
          <strong>Your password should meet the following rules:</strong>
          <ul>
            <li>It should contain at least 1 upper-case letter.</li>
            <li>It should contain at least 1 lower-case letter.</li>
            <li>It should contain at least 1 number.</li>
            <li>It should contain at least 1 special character.</li>
            <li>It should be at least 8 characters length.</li>
          </ul>
        </div>

        <div style="margin-top: 20px;">
          <button class="btn btn-default" routerLink="/">Back</button>
          <button class="btn btn-primary" style="margin-left: 15px;" type="submit"
                  [disabled]="!passwordForm.valid || isProcessing"
                  [style.opacity]="passwordForm.valid && !isProcessing ? 1 : 0.5">Reset password</button>
        </div>
      </form>
    </div>
  </div>
</div>
