import { Component, OnInit, Inject } from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

    public errorMessage: string;
    public returnUrl: string;
    public model: any = {};

    constructor(private accountService: AccountService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject('global_options') private options: any
    ) {
        activatedRoute.params.subscribe((queryParams) => {
            this.returnUrl = queryParams['returnUrl'];
        });

    }

    ngOnInit() {
      if (this.accountService.loggedIn()) {
        this.router.navigateByUrl(this.returnUrl || '/orders');
      } else {
        this.accountService.loginType = 'LOGIN';
      }
    }

    onLogin() {

        this.errorMessage = '';
        this.accountService.login(this.model.username, this.model.password)
            .subscribe(
              (result) => {
                this.accountService.authenticate(result.value);
                this.router.navigateByUrl(this.returnUrl || '/orders');
              },
              (error) => {
                if (error.status === 401) {
                  this.errorMessage = 'Invalid Credentials';
                }

                if (error.status === 403) {
                  this.errorMessage = 'Please confirm your account by entering the 6 or 8 digit code you received on your mobile phone. If you\'re having troubles please contact the Treat it';
                }

                if (error.status === 423) {
                  this.errorMessage = 'You\'ve reached the maximum number of login attempts allowed. You can reset your password following the link below: \'Forgot your Password\'';
                }
                document.body.scrollTop = 0;
              });
    }

}
