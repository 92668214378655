import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, lastValueFrom } from 'rxjs';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-kyc-image-upload',
  templateUrl: './kyc-image-upload.component.html',
  styleUrls: ['./kyc-image-upload.component.scss']
})
export class KycImageUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  protected category = undefined;
  protected canUpload = false;
  protected selectedType = undefined;
  protected isLoading = false;
  protected categoryImages = {
    drivingLicense: [
      {
        category: 'Driving license',
        type: 'Front',
        file: undefined,
        url: undefined
      },
      {
        category: 'Driving license',
        type: 'Back',
        file: undefined,
        url: undefined
      },
    ],
    residence: [
      {
        category: 'Identification of residence',
        type: 'Front',
        file: undefined,
        url: undefined
      },
      {
        category: 'Identification of residence',
        type: 'Back',
        file: undefined,
        url: undefined
      },
    ],
    passport: [
      {
        category: 'Passport',
        type: '',
        file: undefined,
        url: undefined
      },
    ]
  }

  private patientId: number;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.patientId = params['patientId'];
    })
  }

  openFileUploader(index: number) {
    this.selectedType = index;
    this.fileInput.nativeElement.click();
  }

  updateImages($event) {
    const fileList = $event.currentTarget.files;
    const file = fileList[0];
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    this.category = this.category.map((item, i) => (i === this.selectedType ? { ...item, file, url } : item));
    this.canUpload = this.category.every(item => !!item.file);
  }

  onCategoryChange(event){
    this.canUpload = false;
    this.category = this.categoryImages[event.target.value]
  }

  onDropFiles(files: any, index: number): void {
    this.category = this.category.map((item, i) => (i === index ? { ...item, file: files.file, url: files.url } : item));
    this.canUpload = this.category.every(item => !!item.file);
  }

  removeImag(i: number){
    this.category[i].file = undefined;
    this.category[i].url = undefined;
    this.canUpload = false;
  }

  async uploadImages() {
    this.isLoading = true;
    const requestList = [];
    this.category.forEach(item => {
      const formData = new FormData();
      formData.append('files', item.file);
      formData.append('action', "PATIENT_KYC_DOCUMENTS");
      formData.append('category', `${item.category} ${!!item.type ? `- ${item.type}` : "" }`);
      requestList.push(this.clinicService.uploadPatientKYC(this.patientId, formData));
    })
    
    await lastValueFrom(forkJoin(requestList));
    
    this.crateOrder();
  }

  async crateOrder() {
    this.isLoading = true;
    const orderResponse = await lastValueFrom(this.clinicService.createOrder(this.patientId));
    localStorage.setItem(`${orderResponse.message}_completed`, 'false');
    this.isLoading = false;
    this.router.navigate([`/clinic/${orderResponse.message}/questionnaire/step_1`,], { replaceUrl: true });
  }
}
