<ng-container>
    <p>
        Next, we have the GAD questionnaire that is aimed at understanding your anxiety levels in the recent times. For each statement, please indicate the option that best describes your experiences over the last two weeks:
    </p>
    <form [formGroup]="gadGroup" autocomplete="off">
        <div *ngFor="let item of gadForm | keyvalue" class="modal-question">
            <span><b>{{item.value.label}}</b>&nbsp;<span style="color: red;">*</span></span>
            <div class="modal-radios">
                <ng-container *ngFor="let radioItem of item.value.values | keyvalue">
                    <input type="radio" [value]="radioItem.key" [id]="radioItem.key + item.key"
                        [formControlName]="item.key" />
                    <label [for]="radioItem.key + item.key">{{radioItem.value}}</label>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>