import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-info-slider',
  templateUrl: './info-slider.component.html',
  styleUrls: ['./info-slider.component.scss']
})
export class InfoSliderComponent implements OnInit {
  @Input() supportText: any;
  @Output() hide = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  hideSlider(){
    this.hide.emit();
  }

  getSanitizedHtml(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

}
