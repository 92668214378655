<div *ngIf="isLoading"
    style="width: 100%; height: 100%; position: fixed; background: #dddddd66; z-index: 200; top: 0;">
    <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <i class="fa fa-spinner fa-2x fa-spin"></i>
        Loading...</span>
</div>

<clinic-layout [title]="'Upload ID documents'" [reviewPage]="true" [titleCenter]="true">
    <div class="page-container">
        <h2>We were unable to verify your identity using the information provided. Please upload a valid government
            approved ID document such as your passport, identification of residence or driving license</h2>

        <div class="category-select">
            <h4>Select a document type: </h4>
            <div class="radio-control selection-container">
                <input type="radio" [value]="'drivingLicense'" name="category" id="drivingLicense" (change)="onCategoryChange($event)" [disabled]="isLoading"/>
                <label for="drivingLicense">Driving license</label>
                <input type="radio" [value]="'passport'" name="category" id="passport" (change)="onCategoryChange($event)" [disabled]="isLoading"/>
                <label for="passport">Passport</label>
                <input type="radio" [value]="'residence'" name="category" id="residence" (change)="onCategoryChange($event)" [disabled]="isLoading"/>
                <label for="residence">Identification of residence</label>
            </div>
        </div>

        <input #fileInput type="file" accept="image/png, image/jpeg" name="imageUploader" id="imageUploader"
            (change)="updateImages($event)">
        
        <div *ngIf="!!category" class="drop-box-container">
            <div *ngFor="let item of category; index as i">
                <button *ngIf="item.file === undefined; else hasImage;" class="drop-box" appImageUploader (dropFiles)="onDropFiles($event, i)" 
                    (click)="openFileUploader(i)">
                    <span class="message">Upload <br/> ID document</span>
                </button>
                <ng-template #hasImage>
                    <div class="img-container">
                        <div class="close-icon" (click)="removeImag(i)">&#10005;</div>
                        <img [src]="item.url" class="selected-img" />
                    </div>
                </ng-template>
                <label>
                    {{item.category}} 
                    <ng-container *ngIf="!!item.type">
                        <br/> <span>({{item.type}})</span>
                    </ng-container>
                </label>
            </div>
        </div>

        <div class="btn-container">
            <button *ngIf="canUpload" class="btn btn-primary" (click)="uploadImages()"
                [disabled]="isLoading">
                Upload image
                <img src="/assets/img/upload.png" alt="">
            </button>

            <button class="btn btn-primary btn-skip" (click)="crateOrder()" [disabled]="isLoading">Skip to upload
                later <img src="/assets/img/arrow-right.png" alt=""></button>
        </div>
    </div>
</clinic-layout>