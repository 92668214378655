import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClinicService } from '../clinic.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.scss'],
})
export class Consultations implements OnInit, OnDestroy {
  protected consultations: any[] = [];
  protected page: number;
  protected currentPage: number;
  protected lastPage: number;
  public consultationsLoading = false;

  constructor(
    private clinicService: ClinicService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  loadConsultations() {
    this.consultationsLoading = true;
    this.clinicService
      .getAllOrders(this.currentPage)
      .subscribe((consultations) => {
        this.consultations = consultations.content.sort((a, b) => b.id - a.id);
        this.lastPage = consultations.totalPages
          ? consultations.totalPages - 1
          : 0;

        this.consultationsLoading = false;
      });
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const pageNumber = +params.get('page') || 1;
      this.page = pageNumber;
      this.currentPage = pageNumber - 1;
      this.loadConsultations();
    });
  }

  ngOnDestroy() {}

  changePage(page: number) {
    this.currentPage = page;
    this.loadConsultations();
  }

  goToConsultation(orderId) {
    localStorage.setItem(`${orderId}_completed`, 'false');
    this.router.navigateByUrl(`/clinic/${orderId}/questionnaire/step_1`);
  }
}
