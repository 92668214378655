import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ClinicService } from '../clinic/clinic.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderRestrictionGuard implements CanActivate {
  constructor(private clinicService: ClinicService, private router: Router) {}

  async canActivate(activatedRoute: ActivatedRouteSnapshot) {
    let orderId = activatedRoute.params['orderId'];

    try {
      const order = await lastValueFrom(
        this.clinicService.getOrderItem(orderId)
      );

      if (order.status !== 'BOOKED') {
        return this.router.navigateByUrl(
          `/clinic/consultation/${orderId}/view`
        );
      }

      return true;
    } catch (error) {
      return this.router.navigateByUrl(`/clinic/consultation/${orderId}/view`);
    }
  }
}
