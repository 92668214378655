<div class="container">
  <div class="central_panel">
    <h2>Change Pin</h2>

    <div *ngIf="success">
      <div class="alert alert--success">
        Pin changed successfully.
      </div>
      <a class="btn btn--o_3" routerLink="/"><i class="fa fa-arrow-left"></i> Back</a>
    </div>

    <form *ngIf="!success"
          [formGroup]="pinForm"
          autocomplete="off"
          novalidate
          [ngClass]="{ submitted: isProcessing }"
          (ngSubmit)="submit()">
      <div class="form-group">
        <label for="currentPin">Current Pin</label>
        <input id="currentPin" formControlName="currentPin" type="password" class="form-control"/>
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="pinForm.controls?.currentPin?.touched
                      && pinForm.controls?.currentPin?.errors?.required">
          Current pin is required.
        </div>
      </div>
      <div class="form-group">
        <label for="newPin">New Pin</label>
        <input id="newPin" formControlName="newPin" type="password" class="form-control"/>
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="pinForm.controls?.newPin?.touched
                      && pinForm.controls?.newPin?.errors?.fieldError">
          {{pinForm.controls?.newPin?.errors?.fieldError}}
        </div>
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="pinForm.controls?.newPin?.touched
                      && pinForm.controls?.newPin?.value?.length > 0
                      && pinForm.errors?.samePinError">
          {{pinForm.errors?.samePinError}}
        </div>
      </div>
      <div class="form-group">
        <label for="pinConfirmation">Confirm New Pin</label>
        <input id="pinConfirmation" formControlName="pinConfirmation" type="password" class="form-control"/>
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="pinForm.controls?.pinConfirmation?.touched && pinForm.errors?.error">
          {{pinForm.errors?.error}}
        </div>
      </div>

      <div class="alert alert-info" style="margin-top: 15px; margin-bottom: 15px;">
        <strong>Your pin should meet the following rules:</strong>
        <ul>
          <li>It should contain only numbers.</li>
          <li>It should be exactly 4 characters length.</li>
        </ul>
      </div>

      <button type="button" class="btn btn-default" routerLink="/">Back</button>
      <button class="btn btn-default" type="submit" style="margin-left: 15px;"
              [disabled]="!pinForm.valid || isProcessing"
              [style.opacity]="pinForm.valid && !isProcessing ? 1 : 0.5">
        Change Pin
      </button>

    </form>
  </div>
</div>
