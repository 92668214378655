import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { feedbackType, modalType, questionnaire, questionType } from 'src/assets/clinic-questionnaire';
import { debounce } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-consultation-triage',
  templateUrl: './consultation-triage.component.html',
  styleUrls: ['./consultation-triage.component.scss']
})
export class ConsultationTriageComponent implements OnInit {
  @ViewChild('PEGModal', { static: true }) pegModal: ModalDirective;
  @ViewChild('GADModal', { static: true }) gadModal: ModalDirective;
  @ViewChild('AUDITModal', { static: true }) auditModal: ModalDirective;
  
  private gpNote: any;
  
  protected notesGroup: FormGroup;
  protected step: string;
  protected orderId: number;
  protected orderItem: any;
  protected showSupportText = false;
  protected selectedSupportText: any;

  debouncedInputChange = debounce(this.handleTextareaChange, 800);

  constructor (
    private clinicService: ClinicService,
    private route: ActivatedRoute,
  ) {}

  private createNotesForm = () => {
    const noteControls = {};
    const consultationNotes = {...this.gpNote};

    const isChecked = (choice: {label: string, id: string}, noteChoices: any[] = []) => {
      const selectedChoice = noteChoices.find(item => item.id === choice.id)
      return {
        checked: new FormControl(!!selectedChoice ? selectedChoice.checked : undefined),
        patientText: new FormControl(!!selectedChoice ? selectedChoice.patientText : undefined),
        pharmacyText: new FormControl(!!selectedChoice ? selectedChoice.pharmacyText : undefined)
      }
    }

    const getRadioValue = (stepId: string, key: string) => {
      if (consultationNotes.hasOwnProperty(`${stepId}|${key}`)){
        return consultationNotes[`${stepId}|${key}`].choice;
      }

      return undefined;
    }

    questionnaire.urlParam.forEach(stepId => {
      const step = questionnaire.data[stepId];
      for (let i = 0; i < step.keys.length; i++) {
        const questionDetails = step.data[step.keys[i]];

        const {active, responseType, question, questionId, choices} = questionDetails;
        
        if (!active || questionDetails.hasOwnProperty('header') || responseType === feedbackType.externalForm) {
          continue;
        }
        
        if (responseType === feedbackType.text) {
          noteControls[`${stepId}|${questionId}`] = new FormGroup({
            question: new FormControl(question),
            patientText: new FormControl(consultationNotes?.[`${stepId}|${questionId}`]?.patientText || undefined, Validators.required),
            pharmacyText: new FormControl(consultationNotes?.[`${stepId}|${questionId}`]?.pharmacyText || undefined)
          });
          continue;
        }

        if ([feedbackType.checkbox, feedbackType.checkboxAndText, feedbackType.checkboxQuestion].includes(responseType)) {
          const checkboxArray = choices.map(choice => (
            new FormGroup({
              label: new FormControl(choice.label),
              ...isChecked(choice, consultationNotes[`${stepId}|${questionId}`]?.choices),
              id: new FormControl(choice.id)
            })
          ));

          noteControls[`${stepId}|${questionId}`] = new FormGroup({
            question: new FormControl(question),
            choices: new FormArray(checkboxArray)
          });

          continue;
        }

        if ([feedbackType.choice, feedbackType.choiceAndTextOnCondition].includes(responseType)) {
          noteControls[`${stepId}|${questionId}`] = new FormGroup({
            question: new FormControl(question),
            patientText: new FormControl(consultationNotes?.[`${stepId}|${questionId}`]?.patientText || undefined),
            pharmacyText: new FormControl(consultationNotes?.[`${stepId}|${questionId}`]?.pharmacyText || undefined),
            choice: new FormControl(getRadioValue(stepId, questionId), Validators.required)
          });
        }
      }
    });

    this.notesGroup = new FormGroup(noteControls);
  }

  handleTextareaChange(){
    this.saveNote();
  }
  
  handleRadioButtonChange(question: questionType){
    const { questionId, responseType } = question;
    const noteValue = this.notesGroup.value[`${this.step}|${questionId}`];
    if (responseType === feedbackType.choiceAndTextOnCondition) {
      if (!question.hasOwnProperty('inputRenderCondition') && noteValue.choice === false) {
        this.notesGroup.patchValue({
          [`${this.step}|${questionId}`]: {
            ...noteValue,
            patientText: null,
            pharmacyText: null
          }
        })
      }

      if (question.hasOwnProperty('inputRenderCondition') && !question.inputRenderCondition.hasOwnProperty(noteValue.choice)) {
        this.notesGroup.patchValue({
          [`${this.step}|${questionId}`]: {
            ...noteValue,
            patientText: null,
            pharmacyText: null
          }
        })
      }
    }
    this.saveNote();
  }

  handleCheckboxChange(checked: boolean, question: questionType, index: number){
    const { questionId, responseType } = question;
    const noteValue = this.notesGroup.value[`${this.step}|${questionId}`];
    const choiceList = noteValue.choices;

    if (!checked) {
      const choicePayload = {
        ...this.notesGroup.value[`${this.step}|${questionId}`].choices[index],
        patientText: null,
        pharmacyText: null
      }
      choiceList[index] = choicePayload;
      this.notesGroup.patchValue({
        [`${this.step}|${questionId}`]: {
          ...noteValue,
          choices: choiceList
        }
      })
    }

    this.saveNote();
  }

  async saveNote(){
    let noteSheet = this.notesGroup.value;
    let noteIndex = undefined;
    const note = this.orderItem.orderNotes.find((note, index) => {
      noteIndex = index;
      return note.type === 'GP_CONSULTATION_NOTES';
    })

    const orderPayload = this.clinicService.getOrderNotePayload(this.orderItem);
    let consultationNotes;
    if (!!note) {
      consultationNotes = {
        ...note,
        questionAnswer: JSON.stringify(noteSheet),
      };
      orderPayload.orderNotes[noteIndex] = consultationNotes;
    } else {
      consultationNotes = {
        type: 'GP_CONSULTATION_NOTES',
        questionAnswer: JSON.stringify(noteSheet),
      }

      orderPayload.orderNotes.push(consultationNotes);
    }

    await lastValueFrom(this.clinicService.updateOrderNote(this.orderId, orderPayload));
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.step = params['step'];
      this.orderId = params['orderId'];
    })
    
    this.getOrderItem();
  }

  private async getOrderItem() {
    this.orderItem = await lastValueFrom(this.clinicService.getOrderItem(this.orderId));
    
    const note = this.orderItem.orderNotes.find(note => note.type === 'GP_CONSULTATION_NOTES');
    this.gpNote = !!note ? JSON.parse(note.questionAnswer) : {};
    this.createNotesForm();
  }

  protected showSlider(question: questionType){
    this.showSupportText = true;
    this.selectedSupportText = question.supportDescription;
  }

  protected hideSlider(){
    this.showSupportText = false;
    this.selectedSupportText = undefined;
  }
  
  openModal(type: modalType){
    switch (type) {
      case modalType.PEG:
        this.pegModal.show();
        break;
      case modalType.GAD:
        this.gadModal.show();
        break;
      case modalType.AUDIT:
        this.auditModal.show();
        break;
      default:
        break;
    }
  }

  protected renderHeader(key: string){
    const question = questionnaire.data[this.step].data[key];
    if (!question.hasOwnProperty('renderCondition')) {
      return true;
    }

    const {questionId, stepId, value} = question.renderCondition;
    return this.notesGroup.value[`${stepId}|${questionId}`].choice === value;
  }

  protected renderFormGroup(key: string){
    const question = questionnaire.data[this.step].data[key];
    if (!question.hasOwnProperty('renderCondition')) {
      return question.active && !question.hasOwnProperty('header') && question.responseType !== feedbackType.externalForm;
    }

    const {questionId, stepId, value} = question.renderCondition;
    return this.notesGroup.value[`${stepId}|${questionId}`].choice === value && question.responseType !== feedbackType.externalForm;
  }

  protected renderModal(key: string){
    const question = questionnaire.data[this.step].data[key];
    if (!question.hasOwnProperty('renderCondition')) {
      return true
    }

    const {questionId, stepId, value} = question.renderCondition;
    return this.notesGroup.value[`${stepId}|${questionId}`].choice === value;
  }

  protected showCheckboxComment(question: questionType, choiceId: string){
    const choice = this.notesGroup.value[`${this.step}|${question.questionId}`].choices.find(choice => choice.id === choiceId);
    return choice.checked && choice.id !== 'none'
  }

  protected showRadioboxComment(question: questionType){
    const choice = this.notesGroup.value[`${this.step}|${question.questionId}`].choice;
    
    if (typeof choice === 'boolean' && question.hasOwnProperty('inputRenderCondition')) {
      return choice === question.inputRenderCondition;
    }
    
    if (typeof choice === 'boolean') {
      return choice === true;
    }

    if (typeof choice === 'string') {
      const hasProperty  = question.inputRenderCondition.hasOwnProperty(choice);
      return !!hasProperty;
    }

    return false;
  }

  protected get stepQuestionnaire(){
    if (!this.step) {
      return null;
    }
    return questionnaire.data[this.step];
  }

  protected get questions(){
    if (!this.step) {
      return null;
    }
    return questionnaire.data[this.step].data;
  }

  protected get feedbackType(){
    return feedbackType
  }
}
