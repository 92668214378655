<ng-container>
    <p>
        Finally, we will go through the AUDIT questionnaire to understand your alcohol consumption habits. This will be filled by the doctor based on your responses. Please respond to the following:
    </p>
    <form [formGroup]="auditGroup" autocomplete="off">
        <div *ngFor="let item of auditForm | keyvalue" class="modal-question">
            <span><b>{{item.value.label}}</b>&nbsp;<span style="color: red;">*</span></span>
            <div class="modal-radios">
                <ng-container *ngFor="let radioItem of item.value.values | keyvalue">
                    <input type="radio" [value]="radioItem.key" [id]="radioItem.key + item.key"
                        [formControlName]="item.key" />
                    <label [for]="radioItem.key + item.key">{{radioItem.value}}</label>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>