<div class="container" *ngIf="model">

    <form #form="ngForm" (ngSubmit)="accept()">
        <h2>Viewing Repeat Prescription</h2>
<div class="row">
        <div class="col col-md-6">
            <div class="form-group">
                <label for="product">Product</label>
                <input type="text" class="form-control" id="product" name="product" required [(ngModel)]="model.product">
            </div>
            <div class="form-group">
                <label for="interval">Interval in Days</label>
                <input type="number" class="form-control" id="interval" name="product" min="1" [(ngModel)]="model.intervalInDays">
            </div>
            <div class="form-group">
                <label>Preferred Contact Method</label>
                <div>
                    <label class="radio-inline">
                    <input type="radio" name="preferredContactMethod" id="preferredContactMethodPhone" [(ngModel)]="model.preferredContactMethod" value="PHONE"> Phone
                </label>
                    <label class="radio-inline">
                    <input type="radio" name="preferredContactMethod" id="preferredContactMethodEmail" [(ngModel)]="model.preferredContactMethod" value="EMAIL"> Email
                </label>
                </div>
            </div>
            <div class="form-group">
                <label for="extraDetails">Extra Details</label>
                <textarea class="form-control" id="extraDetails" name="extraDetails" [(ngModel)]="model.extraDetails"></textarea>
            </div>
            <a routerLink="/repeats" class="btn btn-default">Cancel</a>
            <button type="submit" (click)="accept()" class="btn btn-primary" [disabled]="submitted || !form.form.valid">Accept</button>
        </div>
        </div>
    </form>

</div>