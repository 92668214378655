<div class="container">
    <h1>Welcome</h1>

    <!-- <h2>My Details</h2>
    <p>TODO:</p>
    <div>{{user | json}}</div>

    <h2>Billing</h2>
    <p>TODO: </p> -->

    <app-change-pin></app-change-pin>

    <app-change-password></app-change-password>

    <app-forgot-password></app-forgot-password>

    <app-reset-password></app-reset-password>
</div>