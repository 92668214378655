<div class="container">
  <div class="header-container">
    <h1>Consultations</h1>
    <div class="btn-container">
      <button class="btn btn-primary" type="button" routerLink="/clinic/create-patient">
        Create consultation
      </button>
    </div>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Patient</th>
        <th>Date</th>
        <th>Status</th>
        <th width="20%" class="actions"></th>
      </tr>
    </thead>
    <tbody *ngIf="consultationsLoading">
      <tr style="width: 100%;">
        <td colspan="6">
          <span style="display: flex; justify-content: center; align-items: center;">
            <i class="fa fa-spinner fa-2x fa-spin"></i>
            Loading...</span>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!consultationsLoading">
      <tr *ngFor="let item of consultations">
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.patient.displayName }}</td>
        <td>{{ item.appointment.datetime | date: 'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ item.status | orderStatus }}</td>
        <td>
          <button *ngIf="item.status !== 'BOOKED'" class="btn btn-success"
            routerLink="/clinic/consultation/{{item.id}}/view">
            View notes
          </button>
          <button *ngIf="item.status === 'BOOKED'" class="btn btn-primary" (click)="goToConsultation(item.id)">
            Continue consultation
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="text-center mr-0 ml-0 pl-15">
    <ul class="pagination mt-0">
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage === 0 }" [routerLink]="['/clinic']"
          [queryParams]="{ page: 1 }">First</a>
      </li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage - 1 < 0 }" [routerLink]="['/clinic']"
          [queryParams]="{ page: page - 1 }">Previous</a>
      </li>
      <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link text-primary disabled">...</a></li>
      <li *ngIf="currentPage !== 0" class="page-item hidden-xs">
        <a class="page-link text-primary" [routerLink]="['/clinic']" [queryParams]="{ page: page - 1 }">{{ currentPage
          }}</a>
      </li>
      <li class="page-item">
        <a class="page-link text-primary pagination-active-background"><strong>{{ page }}</strong></a>
      </li>
      <li *ngIf="currentPage < lastPage" class="page-item hidden-xs">
        <a class="page-link text-primary" [routerLink]="['/clinic']" [queryParams]="{ page: page + 1 }">{{ page + 1
          }}</a>
      </li>
      <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link text-primary disabled">...</a></li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage >= lastPage }" [routerLink]="['/clinic']"
          [queryParams]="{ page: page + 1 }">Next</a>
      </li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage === lastPage }" [routerLink]="['/clinic']"
          [queryParams]="{ page: lastPage + 1 }">Last</a>
      </li>
    </ul>
  </div>
</div>