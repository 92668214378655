
import { throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable()
export class OffersService {
  offers: any;

  constructor(private authHttp: HttpClient,
              @Inject('global_options') private options: any) {}

  public getOffers() {
    return this.authHttp.get<any>(`${this.options.apiEndPoint}api/${this.options.portalType}/get-daily-coupons`)
    .pipe(catchError(this.handleError));
  }

  private handleError(response: any) {
    return throwError(response);
  }
}
