<section class="container">
	<h1 class="ml-1em mb-0-5">Formulary</h1>
	<div class="well well--white">
		<div class="row mr-0 ml-0 mb-minus-2em">
			<div class="col-md-7 col-xs-12">
				<ul class="pagination mt-0">
					<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage === 0}" (click)="changePage(0)">First</a></li>
					<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage - 1 < 0}" (click)="changePage(currentPage - 1)">Previous</a></li>
					<li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link text-primary disabled">...</a></li>
					<li *ngIf="currentPage !== 0" class="page-item hidden-xs"><a class="page-link text-primary" (click)="changePage(currentPage - 1)">{{currentPage}}</a></li>
					<li class="page-item"><a class="page-link text-primary pagination-active-background"><strong>{{currentPage + 1}}</strong></a></li>
					<li *ngIf="currentPage + 1 <= lastPage" class="page-item hidden-xs"><a class="page-link text-primary" (click)="changePage(currentPage + 1)">{{currentPage + 2}}</a></li>
					<li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link text-primary disabled">...</a></li>
					<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage >= lastPage}" (click)="changePage(currentPage + 1)">Next</a></li>
					<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage === lastPage}" (click)="changePage(lastPage)">Last</a></li>
				</ul>
			</div>
			<div class="col-md-5 col-xs-12">
				<div class="input-group">
					<input type="text" class="form-control" placeholder="ID, name, formulation or strength" [(ngModel)]="searchQuery" (keypress)="isNumberOrLetter($event)" (keyup.enter)="search()">
					<span class="input-group-btn">
						<button type="button" [disabled]="searchQuery==''" class="btn btn-default" (click)="searchQuery=''; search()">
							<span class="glyphicon glyphicon-remove-circle"></span>
						</button>
						<button type="button" class="btn btn-default" (click)="search()">
							<span class="glyphicon glyphicon-search"></span>
						</button>
					</span>
				</div>
			</div>
		</div>
		<br/>
		<div class="col-md-12 table-responsive">
			<table class="table table-striped">
				<thead>
					<tr>
						<th class="col-md-1">ID</th>
						<th class="col-md-3">Name</th>
						<th class="col-md-3">Formulation</th>
						<th class="col-md-1">Strength</th>
						<th class="col-md-2 text-center">Unit Price</th>
						<th class="col-md-2 text-center">Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let medication of formulary" [ngClass]="{ 'table-warning': medication.toBeUpdated }">
						<td class="col-md-1 middle-aligned">{{medication.id}}</td>
						<td class="col-md-4 middle-aligned">{{medication.name}}</td>
						<td class="col-md-3 middle-aligned">{{medication.formulation}}</td>
						<td class="col-md-1 middle-aligned">{{medication.strength}}</td>
						<td class="col-md-2 middle-aligned" *ngIf="medication.changePrice">
							<div class="input-group">
								<input type="number" pattern="[0-9]+" class="text-center" min="0" step=".01" class="form-control" placeholder="0.00" [(ngModel)]="medication.unitPrice" (keypress)="isNumber($event)" (keyup.enter)="confirmPrice(medication)">
								<span class="input-group-btn">
									<button type="button" class="btn btn-default" (click)="resetToOriginalPrice(medication)">
										<span class="glyphicon glyphicon-remove-circle"></span>
									</button>
								</span>
							</div>
						</td>
						<td class="col-md-1 middle-aligned text-center" *ngIf="!medication.changePrice">
							£{{medication.unitPrice}}
						</td>
						<td class="col-md-2 middle-aligned">
							<button *ngIf="!medication.changePrice" type="button" class="btn btn-default btn-same-size text-center" (click)="changePrice(medication)">
								Update price &nbsp;
							</button>
							<button *ngIf="medication.changePrice" type="button" class="btn btn-warning" (click)="confirmPrice(medication)">
								Confirm price
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="row" class="mr-0 ml-0 pl-15">
			<ul class="col-md-7 pagination mt-0">
				<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage === 0}" (click)="changePage(0)">First</a></li>
				<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage - 1 < 0}" (click)="changePage(currentPage - 1)">Previous</a></li>
				<li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link text-primary disabled">...</a></li>
				<li *ngIf="currentPage !== 0" class="page-item hidden-xs"><a class="page-link text-primary" (click)="changePage(currentPage - 1)">{{currentPage}}</a></li>
				<li class="page-item"><a class="page-link text-primary pagination-active-background"><strong>{{currentPage + 1}}</strong></a></li>
				<li *ngIf="currentPage + 1 <= lastPage" class="page-item hidden-xs"><a class="page-link text-primary" (click)="changePage(currentPage + 1)">{{currentPage + 2}}</a></li>
				<li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link text-primary disabled">...</a></li>
				<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage >= lastPage}" (click)="changePage(currentPage + 1)">Next</a></li>
				<li class="page-item"><a class="page-link text-primary" [ngClass]="{'disabled': currentPage === lastPage}" (click)="changePage(lastPage)">Last</a></li>
			</ul>
			<div class="col-md-md-5 text-right mr-1-7">
				<button class="btn btn-success" (click)="saveChanges()">Confirm prices</button>
			</div>
		</div>

		<div bsModal #confirmPricesChangesModal="bs-modal" id="confirmPricesChangesModal" class="modal fade">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">

					<div class="modal-header">
						<button type="button" class="close" (click)="confirmPricesChangesModal.hide()" aria-hidden="true">&times;</button>
						<h3 class="modal-title">Price change confirmation</h3>
					</div>

					<div class="modal-body">

						<div *ngIf="listOfMedicationsToUpdate.length > 0"> 
							<div class="table-responsive">
								<table class="table table-striped table-hover">
									<thead>
										<tr>
											<th>ID</th>
											<th>Name</th>
											<th>Formulation</th>
											<th>Strength</th>
											<th class="text-center">Original Price</th>
											<th class="text-center">New Price</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let medication of listOfMedicationsToUpdate" (click)="searchForThisMedication(medication.id)">
											<td>{{medication.id}}</td>
											<td>{{medication.name}}</td>
											<td>{{medication.formulation}}</td>
											<td>{{medication.strength}}</td>
											<td class="text-center">£{{medication.originalPrice}}</td>
											<td class="text-primary text-center"><strong>£{{medication.unitPrice}}</strong></td>
										</tr>
									</tbody>
								</table>
							</div>
							<p class="text-center">
								<span class="red-text">Please note:</span> this price change will not be reflected for prescriptions that are already in the system.
							</p>
						</div>

						<div class="text-center" *ngIf="listOfMedicationsToUpdate.length === 0">
							<br/><p>You don't have any medications to update.</p><br/>
						</div>

						<div class="modal-footer">
							<button type="button" class="btn btn-default" (click)="confirmPricesChangesModal.hide()">Close</button>
							<button type="button" class="btn btn-primary" [disabled]="listOfMedicationsToUpdate.length === 0" (click)="confirmChanges()">Confirm</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>