import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GPDetailsService {
  constructor() {}

  getGPContactInfo(contacts: any[]) {
    const contactInfo = {
      phone: '',
      email: '',
      url: '',
      fax: '',
    };

    contacts.forEach((item) => {
      switch (item.OrganisationContactMethodType) {
        case 'Telephone':
          contactInfo.phone = item.OrganisationContactValue;
          break;
        case 'Email':
          contactInfo.email = item.OrganisationContactValue;
          break;
        case 'Website':
          contactInfo.url = item.OrganisationContactValue;
          break;
        case 'Fax':
          contactInfo.fax = item.OrganisationContactValue;
          break;

        default:
          break;
      }
    });

    return contactInfo;
  }
}
