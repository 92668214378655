import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { AccountService } from './account.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  model: {
    token?: string,
    email?: string,
  };
  response: any;
  errorMessage: any;
  passwordForm: UntypedFormGroup;
  isProcessing = false;

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (param: any) => {
        this.model = {
          token: param?.token,
          email: param?.username,
        };
      }
    );

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, this.validatePassword]],
      passwordConfirmation: ['', [Validators.required]],
    }, { validators: passwordCrossValidator });
  }

  validatePassword(field: UntypedFormControl): ValidationErrors | null {
    if (!(/[A-Z]+/.test(field.value))) { // at least one upper-case char
      return { fieldError: 'Password must contain at least 1 upper-case character.' };
    }
    if (!(/[a-z]+/.test(field.value))) { // at least lower-case char
      return { fieldError: 'Password must contain at  least 1 lower-case character.' };
    }
    if (!(/[0-9]+/.test(field.value))) { // at least one number
      return { fieldError: 'Password must contain at least 1 number.' };
    }
    if (!(/[~`!@#$%^&\-*()_+=|:\\;"'[<\]>,.?{}/€£¥•=]+/.test(field.value))) { // at least one special char
      return { fieldError: 'Password must contain at least 1 special character.' };
    }
    if (field.value?.length < 8) {
      return { fieldError: 'Password must be at least 8 characters long.' };
    }
    return null;
  }

  submit(): void {
    if (!this.passwordForm.valid) {
      return;
    }
    const newPassword = this.passwordForm.get('password').value;
    this.isProcessing = true;
    this.accountService
      .resetPassword(this.model.email, this.model.token, newPassword)
      .pipe(finalize(() => {
        this.isProcessing = false;
      }))
      .subscribe(
        (result: any) => {
          this.response = 'Your password has been reset. You can now login with your new password';
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.errorMessage = 'The token you have provided is not valid or may have expired already.';
          } else {
            this.errorMessage = 'Something went wrong';
          }
        });
  }
}

const passwordCrossValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const password = formGroup.get('password').value;
  const passwordConfirmation = formGroup.get('passwordConfirmation').value;
  return password === passwordConfirmation ? null : { passwordMismatch: 'The passwords do not match.' };
};
