import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepeatService } from './repeat.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PatientService } from '../patients/patient.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-repeat',
    templateUrl: './repeat.component.html'
})
export class RepeatComponent implements OnInit, OnDestroy {
    item: any;
    patient: any;
    model: any;
    submitted = false;
    private sub: Subscription;

    constructor(
        private repeatService: RepeatService,
        private route: ActivatedRoute,
        private router: Router,
        private patientService: PatientService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            const id = +params['id'];
            this.model = { id: id, preferredContactMethod: 'PHONE' };

            this.repeatService.list().subscribe(items => {
                this.item = items.filter(i => i.id === id)[0];
                if (this.item) {
                    this.patientService.getPatient(this.item.patientId).subscribe(patient => {
                        this.patient = patient;
                    });
                }
            });
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    accept() {
        this.submitted = true;
        this.repeatService.accept(this.model).subscribe((result) => {
            if (result.success) {
                this.toastr.success('Prescription update', 'Prescription accepted');
                this.router.navigateByUrl('/repeats');
            } else {
                this.toastr.error('Update failed', 'Accept failed: ' + result.message);
                this.submitted = false;
            }
        });

    }
}
