import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ClinicService } from '../clinic.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { feedbackType, modalType, questionnaire } from 'src/assets/clinic-questionnaire';
import { CONSENT_FORM, GAD_FORM } from 'src/assets/patientQuestionnaire';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @ViewChild('formContainer') private formContainer: ElementRef;
  @ViewChild('pinModal', { static: true }) pinModal: ModalDirective;
  @Input() stepPosition: number;
  @Input() orderId: number;
  @Input() orderItem: any;
  @Input() patientEmail: number;
  @Input() notesGroup: FormGroup;
  @Input() isFormIncomplete: boolean;

  protected stepPercentage = 0;
  protected pin = "";
  protected isSubmitDisabled = false;
  protected isSubmitting = false;
  
  private externalForms = []; 

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private clinicService: ClinicService
  ) {}

  scrollToTop(): void {
    try {
      this.formContainer.nativeElement.scrollTop = 0;
    } catch (err) {}
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.stepPosition) {
      this.setProgressWidth();
      this.externalForms = [];
      this.isSubmitDisabled = false;
      this.isSubmitting = false;
    }
  }

  validateExtraForms(orderItem: any, validateAll: boolean){
    let isValid = true;
    const pegForm = orderItem.orderNotes.find(note => note.type === 'PEG_FORM');
    const gadForm = orderItem.orderNotes.find(note => note.type === 'GAD_FORM');
    const consentForm = orderItem.orderNotes.find(note => note.type === 'PATIENT_DETAIL_CONFIRMATION');

    
    if (validateAll || this.externalForms.includes(modalType.PEG)) {
      const pegResponse = JSON.parse(pegForm.questionAnswer || "{}");
      if (!pegResponse?.average) {
        this.toastr.warning('Please complete the PEG form');
        isValid = false;
        return;
      }
    }

    if (validateAll || this.externalForms.includes(modalType.GAD)) {
      if (!gadForm) {
        this.toastr.warning('Please complete the GAD form');
        isValid = false;
        return;
      }
      const gadResponse = JSON.parse(gadForm.questionAnswer || "{}");
      for (let i = 0; i < Object.keys(GAD_FORM).length; i++) {
        const key = Object.keys(GAD_FORM)[i];
        if ([null, undefined].includes(gadResponse[key])) {
          this.toastr.warning('Please complete the GAD form fully!');
          isValid = false;
          break;
        }
      }
    }

    if (validateAll || this.externalForms.includes(modalType.PERSONAL)) {
      if (!consentForm) {
        this.toastr.warning('Please complete the Consent form');
        isValid = false;
        return;
      }
      const consentResponse = JSON.parse(consentForm.questionAnswer || "{}");
      for (let i = 0; i < Object.keys(CONSENT_FORM).length; i++) {
        const key = Object.keys(CONSENT_FORM)[i];
        if ([null, undefined].includes(consentResponse[key])) {
          this.toastr.warning('Please complete the Consent form fully!');
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  validatePage(){
    const errors = [];
    const pageQuestions = questionnaire.data[`step_${this.stepPosition}`];
    for (let index = 0; index < pageQuestions.keys.length; index++) {
      const key = pageQuestions.keys[index];
      const question = pageQuestions.data[key];
      const { active, responseType } = question;
      
      if (!active || question.hasOwnProperty('header')) {
        continue;
      }
      
      if (question.hasOwnProperty('modalDetails') ) {
        this.externalForms.push(question.modalDetails.formType);
        continue;
      }

      if (question.hasOwnProperty('renderCondition')) {
        const {stepId, questionId, value, valueField} = question.renderCondition;
        const masterValue = this.notesGroup.value[`${stepId}|${questionId}`][valueField];
        if (masterValue !== value) {
          continue;
        }
      }

      const groupValue = this.notesGroup.value[`step_${this.stepPosition}|${key}`]
      
      if (responseType === feedbackType.text) {
        if (!groupValue.patientText) {
          errors.push({title: question.question, message: "Patient's comment cannot be empty!"})
        }
        continue;
      }

      if ([feedbackType.checkbox, feedbackType.checkboxAndText].includes(responseType)) {
        const hasCheckedOption = groupValue.choices.some(choice => !!choice.checked);
        if (!hasCheckedOption) {
          errors.push({title: question.question, message: "Please select at least one of the given choices!"})
        }
        continue;
      }

      if (responseType === feedbackType.checkboxQuestion) {
        const hasCheckedOption = groupValue.choices.every(choice => !!choice.checked);
        if (!hasCheckedOption) {
          errors.push({title: question.question, message: "Please select all of the given choices!"})
        }
        continue;
      }

      if ([feedbackType.choice, feedbackType.choiceAndTextOnCondition].includes(responseType)) {
        if ([null, undefined].includes(groupValue.choice)) {
          errors.push({title: question.question, message: "Please select a given choice!"})
        }
        continue;
      }
    }

    return errors;
  }

  async toNextPage() {
    // const errors = this.validatePage();
    // if (errors.length > 0) {
    //   errors.reverse().forEach(item => {
    //     this.toastr.warning(item.message, item.title);
    //   });

    //   return;
    // }
    this.isSubmitDisabled = true;
    // const orderItem = await lastValueFrom(this.clinicService.getOrderItem(this.orderId));
    // const isValid = this.validateExtraForms(orderItem, false);
    // if (!isValid) {
    //   this.isSubmitDisabled = false;
    //   return;
    // }

    const position = Number(this.stepPosition) + 1;
    this.router.navigateByUrl(
      `clinic/${this.orderId}/questionnaire/step_${position}`
    );
    this.scrollToTop();
  }

  toPreviousPage() {
    const position = Number(this.stepPosition) - 1;
    this.router.navigateByUrl(
      `clinic/${this.orderId}/questionnaire/step_${position}`
    );
    this.scrollToTop();
  }

  toReview() {
    // const errors = this.validatePage();
    // if (errors.length > 0) {
    //   errors.reverse().forEach(item => {
    //     this.toastr.warning(item.message, item.title);
    //   });

    //   return;
    // }
    localStorage.setItem(`${this.orderId}_completed`, 'true');
    this.router.navigateByUrl(`clinic/consultation/${this.orderId}/review`);
  }

  async fastForwardToReview(){
    // const errors = this.validatePage();
    // if (errors.length > 0) {
    //   errors.reverse().forEach(item => {
    //     this.toastr.warning(item.message, item.title);
    //   });

    //   return;
    // }
    this.isSubmitDisabled = true;
    // const orderItem = await lastValueFrom(this.clinicService.getOrderItem(this.orderId));
    // const isValid = this.validateExtraForms(orderItem, false);
    // if (!isValid) {
    //   this.isSubmitDisabled = false;
    //   return;
    // }

    this.router.navigateByUrl(`clinic/consultation/${this.orderId}/review`);
  }

  async openPinModal(){
    this.isSubmitDisabled = true;

    if (this.isFormIncomplete) {
      this.toastr.warning("Complete missing mandatory inputs to proceed", "Consultation submission failed", {
        closeButton: true,
        disableTimeOut: true,
      })
      this.isSubmitDisabled = false;
      return;
    }

    const orderItem = await lastValueFrom(
      this.clinicService.getOrderItem(this.orderId)
    );

    const isExtraFormsValid = this.validateExtraForms(orderItem, true);
    if (!isExtraFormsValid) {
      return;
    }

    this.pinModal.show();
  }

  async handleSubmit() {
    if (!this.pin) {
      this.toastr.warning("Pin is required!");
      return;
    }

    this.isSubmitting = true;

    this.pinModal.hide();

    const orderItem = await lastValueFrom(
      this.clinicService.getOrderItem(this.orderId)
    );

    const noteExist = orderItem.orderNotes.find(note => note.type === 'PRESCRIBING_PHARMACIST_APPROVAL_DECISION');

    if (!noteExist) {
      const payload = this.clinicService.getOrderNotePayload(orderItem);
  
      const orderNote = {
        type: 'PRESCRIBING_PHARMACIST_APPROVAL_DECISION',
        questionAnswer: 'approved',
        approved: true,
      };
  
      payload.orderNotes.push(orderNote);
  
      await lastValueFrom(
        this.clinicService.updateOrderNote(this.orderId, payload)
      );
    }

    const data = {
      patientEmail: this.patientEmail,
      pin: this.pin,
      orderItemId: this.orderId
    };

    this.clinicService.completeOrder(this.orderId, data).subscribe(
      (response) => {
        this.toastr.success('Consultation completed', 'Success');
        localStorage.removeItem('patientData'); 
        localStorage.removeItem(`${this.orderId}_completed`);
        this.isSubmitting = false;

        this.router.navigateByUrl(
          `clinic/consultation/${this.orderId}/success`,
          { replaceUrl: true }
        );
      },
      (error) => {
        let errorMessage = 'Something went wrong';
        if (error?.status === 401) {
          errorMessage = "Please check your pin number!"
        }
        this.isSubmitting = false;
        this.toastr.error("Couldn't complete consultation", errorMessage);
        this.pin = "";
      }
    );
  }

  setProgressWidth() {
    const accuratePercentage = 100 * (Number(this.stepPosition) / 8);
    const firstStepPercentage = 100 * (1 / 8);
    this.stepPercentage = accuratePercentage - firstStepPercentage;
  }

  get questionnaireCompleted(){
    return localStorage.getItem(`${this.orderId}_completed`)
  }
}
