import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, lastValueFrom } from 'rxjs';
import { GAD_FORM } from 'src/assets/patientQuestionnaire';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-gad-form',
  templateUrl: './gad-form.component.html',
  styleUrls: ['./gad-form.component.scss']
})
export class GadFormComponent implements OnInit, OnChanges {
  @Input() orderItem:any;
  @Input() orderId:any;

  gadGroup: FormGroup;
  gadNotes: any;

  constructor(private clinicService: ClinicService) { }

  private async initializeGadNotes(){
    if (!!this.orderItem) {
      const note = this.orderItem.orderNotes.find(note => note.type === 'GAD_FORM');
      this.gadNotes = !!note ? JSON.parse(note.questionAnswer) : {};
    }
  }

  private createGadNotesForm() {
    const noteControls = {};
    const gadNotes = this.gadNotes;
    Object.keys(this.gadForm).forEach((key: string) => {
      noteControls[key] = new FormControl(gadNotes?.[key] || undefined, [
        Validators.required,
      ]);
    });

    this.gadGroup = new FormGroup(noteControls);
    this.onGadNotesChange();
  }

  private onGadNotesChange() {
    this.gadGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      const gadData = this.getGadData(val);
      const orderPayload = this.clinicService.getOrderNotePayload(this.orderItem);
      
      let noteIndex = undefined;
      const note = this.orderItem.orderNotes.find((note, index) => {
        noteIndex = index;
        return note.type === 'GAD_FORM';
      })
      if (!!note) {
        orderPayload.orderNotes[noteIndex] = gadData;
      } else {
        orderPayload.orderNotes.push(gadData);
      }

      await lastValueFrom(this.clinicService.updateOrderNote(this.orderId, orderPayload));
    });
  }

  ngOnInit(): void {
    this.initializeGadNotes();
    this.createGadNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      this.initializeGadNotes();
      this.createGadNotesForm();
    }
  }

  getGadData(gadValue: any) {
    let totalScore = 0;
    Object.keys(gadValue).forEach((key: string) => {
      if (!!gadValue[key]) {
        totalScore += parseInt(gadValue[key]);
      }
    });

    const formValues = {
      ...gadValue,
      score: totalScore,
    };

    return {
      type: 'GAD_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  get gadForm() {
    return GAD_FORM;
  }
}
