import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from './order.service';
import { PatientService } from '../patients/patient.service';
import { AccountService } from '../account/account.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserService } from '../account/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-actions',
  templateUrl: './order-actions.component.html',
  styleUrls: ['./order-actions.component.scss'],
})
export class OrderActionsComponent implements OnInit {
  @Input() list = false;
  @Input() order: any;
  @Input() prescription: any;
  @Input() patientId: any;
  @Output() orderChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('approveModal', { static: true }) approveModal: ModalDirective;
  @ViewChild('dispatchModal', { static: true }) dispatchModal: ModalDirective;
  patient: any;
  pharmacy: any;
  rejectReason = '';
  rejectReasonDetails = '';
  price: number;
  submitting = false;
  pin: string = '';
  trackingNumber: string;
  deliveryType: string;
  name: string;
  eventDate: string;
  gphc: string;
  errorMessage = 'Something went wrong';
  deliveryOrder = false;
  virtualPharmacy = false;
  totalPriceToCharge = 0;
  confirmedPrices = true;
  treatmentsMissingCharge = false;
  treatmentsMissingChargeError = false;
  isLoggedIn: boolean;
  isVerification: boolean;
  selectedDeliveryMethod = "";
  deliveryMethods = [];

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private orderService: OrderService,
    private router: Router,
    private patientService: PatientService,
    private toastr: ToastrService
  ) { }

  goBack() {
    (<any>window).localToken = null;
    this.router.navigateByUrl(this.isLoggedIn ? '/' : '/prescriptions');
  }

  ngOnInit() {
    this.pharmacy = this.userService.user;
    this.isLoggedIn = this.accountService.loggedIn();
    this.isVerification = this.orderService.isVerification;
    this.virtualPharmacy = this.order.pharmacy.virtual;
    this.accountService.info().subscribe((options) => {
      if (this.order.pharmacy.delivers == true) {
        this.deliveryOrder = true;
        if (this.prescription === undefined) {
          this.orderService
            .getPrescription(this.order.id)
            .subscribe((prescription) => {
              this.prescription = prescription;
              this.verifyTreatments();
            });
        } else {
          this.verifyTreatments();
        }
      }
    });

    if (this.order.deliveryType == 'STANDARD') {
      this.deliveryType = 'Standard Delivery';
    } else if (this.order.deliveryType == 'EXPRESS') {
      this.deliveryType = 'Express Delivery - Next Day Delivery';
    } else {
      this.deliveryType = 'Not available';
    }
    //     this.patientService.getPatient(this.patientId).subscribe((result) => {
    //       this.patient = result;
    //     }, (error) => {
    //       this.errorMessage = error.message;
    //     }
    //   );

    this.getDeliveryMethods();
  }

  verifyTreatments() {
    for (const treatment of this.prescription.medication) {
      if (treatment.charge !== 0) {
        treatment.originalCharge = treatment.charge;
      } else {
        treatment.charge = 0;
        treatment.originalCharge = 0;
        this.confirmedPrices = false;
        this.treatmentsMissingCharge = true;
      }
    }
    this.getTotalPrice();
  }

  getTotalPrice() {
    this.totalPriceToCharge = 0;
    for (const treatment of this.prescription.medication) {
      if (treatment.charge > 0) {
        this.totalPriceToCharge += treatment.charge;
      }
    }
    this.totalPriceToCharge =
      Math.round(this.totalPriceToCharge * 100) / 100 +
      parseFloat(this.pharmacy.preferences.deliveryCharge);
  }

  confirmPrices() {
    if (
      this.prescription.medication.some((treatment) => treatment.charge === 0)
    ) {
      this.treatmentsMissingChargeError = true;
      this.confirmedPrices = false;
    } else {
      this.treatmentsMissingChargeError = false;
      this.confirmedPrices = true;
    }
  }

  changePrices() {
    this.confirmedPrices = false;
  }

  reject() {
    this.submitting = true;
    const rejectReasonDetails =
      this.rejectReason === 'COMPLETELY_OUT_OF_STOCK'
        ? 'Medication out of stock'
        : this.rejectReasonDetails || '';

    this.orderService
      .getPrescription(this.order.id)
      .subscribe((prescription) => {
        this.orderService
          .rejectOrderItem(
            this.order.id,
            this.pin,
            this.name,
            this.rejectReason,
            rejectReasonDetails,
            JSON.stringify(prescription)
          )
          .subscribe(
            (result) => {
              this.completeRequest();
              this.toastr.success('Order update', 'Order rejected');
              this.orderChanged.emit(this.order);
            },
            (error) => {
              this.completeRequest();
              if (error.message) {
                this.errorMessage = error.message;
              }
              this.toastr.error('Reject order failed', this.errorMessage);
            }
          );
      }),
      (error) => {
        this.completeRequest();
        if (error.message) {
          this.errorMessage = error.message;
        }
        this.toastr.error('Reject order failed', this.errorMessage);
      };
  }

  completeRequest() {
    this.pin = '';
    this.name = '';
    this.eventDate = '';
    this.submitting = false;
    this.orderService.isVerification = null;
  }

  dispense() {
    this.submitting = true;
    this.approveModal.hide();
    this.orderService
      .dispenseOrderItem(this.order.id, this.pin, this.name)
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success('Dispense order', 'Order dispensed successfully');
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Dispense order failed', this.errorMessage);
        }
      );
  }

  approve() {
    const medicationCharges = [];
    // if (this.deliveryOrder) {
    //     if (!this.confirmedPrices) {
    //         this.treatmentsMissingChargeError = true;
    //         return;
    //     }
    //     for (const treatment of this.prescription.medication) {
    //         if (treatment.charge === 0) {
    //             this.treatmentsMissingChargeError = true;
    //             return;
    //         }
    //         medicationCharges.push({ medicationId: treatment.formulary.id, charge: treatment.charge });
    //     }
    // }

    this.submitting = true;
    this.approveModal.hide();
    this.orderService
      .approveOrderItem(this.order.id, this.pin, this.name, medicationCharges)
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success('Approve order', 'Order approved successfully');
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Approve order failed', this.errorMessage);
        }
      );
  }

  dispatch() {
    this.submitting = true;
    this.orderService
      .dispatchOrderItem(
        this.order.id,
        this.pin,
        this.name,
        this.trackingNumber,
        this.selectedDeliveryMethod
      )
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success(
            'Order dispatched',
            'Order dispatched successfully'
          );
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Dispatch order failed', this.errorMessage);
        }
      );
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getTodayDate(): string {
    return this.formatDate(new Date());
  }

  getOrderCreatedDate(): string {
    const createdDate = new Date(this.order.creationDate);
    return this.formatDate(createdDate);
  }

  complete() {
    this.submitting = true;
    this.orderService
      .deliverOrderItem(this.order.id, this.eventDate, this.pin, this.name, this.trackingNumber)
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success('Complete order', 'Order completed successfully');
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Complete order failed', this.errorMessage);
        }
      );
  }

  cancelOrder() {
    this.submitting = true;
    this.orderService
      .cancelOrderItem(this.order.id, this.pin, this.name)
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success('Cancel order', 'Cancel Order successfully');
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Cancel Order failed', this.errorMessage);
        }
      );
  }

  propose() {
    this.submitting = true;
    this.orderService
      .proposeOrderItem(this.order.id, this.pin, this.name, this.price)
      .subscribe(
        (result) => {
          this.completeRequest();
          this.toastr.success('Propose order', 'Order price set successfully');
          this.orderChanged.emit(this.order);
        },
        (error) => {
          this.completeRequest();
          if (error.message) {
            this.errorMessage = error.message;
          }
          this.toastr.error('Propose order failed', this.errorMessage);
        }
      );
  }

  printPrescription() {
    window.print();
  }

  download() {
    this.submitting = true;
    this.orderService.downloadPrescription(this.order.id).subscribe(
      (data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.setAttribute('href', url);
        anchor.setAttribute('download', 'prescription.pdf');
        anchor.click();
        window.URL.revokeObjectURL(url);
      },
      (error) =>
        this.toastr.error(
          'Error downloading the file',
          'Download failed: ' + error
        ),
      () => {
        this.submitting = false;
      }
    );
  }

  getDeliveryMethods() {
    let tempDeliveryMethod = localStorage.getItem("deliveryMethod");
    this.orderService.getDeliveryMethods().subscribe(data => {
      this.deliveryMethods = data;
      if (data?.includes(tempDeliveryMethod)) {
        this.selectedDeliveryMethod = tempDeliveryMethod;
      }
      else {
        this.selectedDeliveryMethod = "";
      }
    })
  }

  formatString(value: string): string {
    const exceptions = ['DPD', 'DX', 'DHL', 'UPS'];
    if (exceptions.includes(value)) {
      return value;
    }

    if(value == "APC_OVERNIGHT"){
      return "APC Overnight";
    }

    return value?.toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b\w/g, char => char.toUpperCase());
  }

  onSelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDeliveryMethod = selectElement.value;
    localStorage.setItem("deliveryMethod", this.selectedDeliveryMethod);
  }

  openDispatchModal(): void {
    this.dispatchModal.show();
    let tempDeliveryMethod = localStorage.getItem("deliveryMethod");
    if (this.deliveryMethods?.includes(tempDeliveryMethod)) {
      this.selectedDeliveryMethod = tempDeliveryMethod;
    }
    else {
      this.selectedDeliveryMethod = "";
    }
  }
}
