import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

    minimumCharge: number;
    professionalFee: number;
    markupFactor: number;
    response: any;

    constructor(private userService: UserService,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.userService.getCoopProperties().subscribe(result => {
            this.initializeCoopProperties(result);
        });
    }

    initializeCoopProperties(result) {
        this.minimumCharge = result.minimumCharge;
        this.professionalFee = result.professionalFee;
        this.markupFactor = result.markupFactor * 100;
    }

    isNumber(event) {
        const iKeyCode = (event.which) ? event.which : event.keyCode;
        if (iKeyCode !== 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57)) { return false; }
        return true;
    }

    submit() {
        this.userService.updateCoopProperties(this.minimumCharge, this.professionalFee, this.markupFactor / 100).subscribe(
          (response) => {
            this.toastr.success('Settings', 'Settings updated successfully');
          },
          (error) => {
            this.toastr.error('Settings update failed', error.message);
          });
    }
}
