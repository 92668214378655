import { throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientService {
  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: any
  ) {}

  getPatient(id: number) {
    const url = `${this.options.apiEndPoint}api/pharmacy/patient/${id}`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  registerPatient(body: any) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/patient`;
    return this.http
      .post<any>(url, {
        ...body,
      })
      .pipe(catchError(this.handleError));
  }

  updatePatient(body: any) {
    const url = `${this.options.apiEndPoint}api/${this.options.portalType}/prescribing-pharmacist/patient`;
    return this.http
      .put<any>(url, {
        ...body,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(response: any) {
    return throwError(response);
  }
}
