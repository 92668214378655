import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login.component';
import { AuthGuard } from './account/auth.guard';
import { PrescribeRestrictionGuard } from './account/prescribe-restriction.guard';
import { DeliveryRestrictionGuard } from './account/delivery-restriction.guard';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order.component';
import { OffersComponent } from './offers/offers.component';
import { RepeatsComponent } from './repeats/repeats.component';
import { RepeatComponent } from './repeats/repeat.component';
import { MyAccountComponent } from './account/my-account.component';
import { ChangePasswordComponent } from './account/change-password.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { ResetPinComponent } from './account/reset-pin.component';
import { ChangePinComponent } from './account/change-pin.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireSingleItemComponent } from './questionnaire/questionnaire-single-item.component';
import { FormularyComponent } from './formulary/formulary.component';
import { SettingsComponent } from './account/settings.component';
import { VerifyPrescriptionComponent } from './prescriptions/verify-prescription.component';
import { Consultations } from './clinic/consultations/consultations.component';
import { CreatePatient } from './clinic/create-consultation/create-patient.component';
import { ConsultationTriageComponent } from './clinic/consultation-triage/consultation-triage.component';
import { ConsultationSuccess } from './clinic/consultation-success/consultation-success.component';
import { ConsultationReviewComponent } from './clinic/consultation/consultation-review.component';
import { ConsultationViewComponent } from './clinic/consultation/consultation-view.component';
import { OrderRestrictionGuard } from './account/order-restriction.guard';
import { KycImageUploadComponent } from './clinic/create-consultation/kyc-image-upload.component';

const routes: Routes = [
  {
    path: 'orders',
    component: OrdersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'orders/:id',
    component: OrderComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'offers',
    component: OffersComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'repeats',
    component: RepeatsComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'repeats/:id',
    component: RepeatComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: OrdersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-pin',
    component: ChangePinComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'questionnaire',
    component: QuestionnaireComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'questionnaire-individual',
    component: QuestionnaireSingleItemComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'formulary',
    component: FormularyComponent,
    canActivate: [AuthGuard, DeliveryRestrictionGuard],
  },
  {
    path: 'clinic',
    component: Consultations,
    canActivate: [AuthGuard, PrescribeRestrictionGuard],
  },
  {
    path: 'clinic/upload-verification-document/:patientId',
    component: KycImageUploadComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, PrescribeRestrictionGuard],
  },
  {
    path: 'clinic/create-patient',
    component: CreatePatient,
    pathMatch: 'full',
    canActivate: [AuthGuard, PrescribeRestrictionGuard],
  },
  {
    path: 'clinic/:orderId',
    canActivate: [AuthGuard, PrescribeRestrictionGuard],
    children: [
      {
        path: 'questionnaire/:step',
        component: ConsultationTriageComponent,
        pathMatch: 'full',
        canActivate: [OrderRestrictionGuard],
      },
    ],
  },
  {
    path: 'clinic/consultation/:orderId',
    canActivate: [AuthGuard, PrescribeRestrictionGuard],
    children: [
      {
        path: 'review',
        component: ConsultationReviewComponent,
        pathMatch: 'full',
        canActivate: [OrderRestrictionGuard],
      },
      {
        path: 'success',
        component: ConsultationSuccess,
        pathMatch: 'full',
      },
      {
        path: 'view',
        component: ConsultationViewComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'prescriptions',
    component: VerifyPrescriptionComponent,
    canActivate: [DeliveryRestrictionGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-pin',
    component: ResetPinComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
