import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

enum DropColor {
  Default = '#e7bfff', // Default color
  Over = '#c3c3c3', // Color to be used once the file is "over" the drop box
}

@Directive({
  selector: '[appImageUploader]'
})
export class ImageUploaderDirective {
  @Output() dropFiles = new EventEmitter();
  @HostBinding('style.background') backgroundColor;

  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    ) { }

  @HostListener('dragover', ['$event']) public dragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = DropColor.Over;
  }

  @HostListener('dragleave', ['$event']) public dragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = DropColor.Default;
  }

  @HostListener('drop', ['$event']) public drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = DropColor.Default;

    let fileList = event.dataTransfer.files;
    let files = undefined;
    if (fileList.length > 1) {
      this.toastr.warning("", "Please select only one image!");
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files = { file, url };
    }
    if (!!files) {
      this.dropFiles.emit(files);
    }
  }

}
