import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './account/user.service';
import { AccountService } from './account/account.service';
import { KeepAliveService } from './account/keep-alive.service';
import { QuestionnaireService } from './questionnaire/questionnaire.service';
import { DataService } from './common/data.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DataLoggingService } from './data-logging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['../app/app.component.css']
})

export class AppComponent implements OnInit {

    public isCollapsed = true;
    user: any;
    notificationOptions = {
        timeOut: 5000,
        lastOnBottom: true,
        clickToClose: true,
        maxLength: 0,
        maxStack: 7,
        showProgressBar: true,
        pauseOnHover: true,
        preventDuplicates: false,
        preventLastDuplicates: 'visible',
        rtl: false,
        animate: 'scale',
        position: ['right', 'bottom']
    };
    thisIsCoop = false;
    headerPrint = true;
    surveyCount = 0;
    surveyMin: any = null;
    pharmacyDelivers = false;
    deliveringPharmacy = false;
    prescribingPharmacy = false;
    private subscription: Subscription;
    public response: any;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private userService: UserService,
        private accountService: AccountService,
        private router: Router,
        private keepAliveService: KeepAliveService,
        public questionnaireService: QuestionnaireService,
        private logger: DataLoggingService,
        private dataService: DataService) {
    }

    getSurveysCount() {
        const initialDate = (this.questionnaireService.setCurrentYear() - 1).toString() + '-04-01T00:00:00Z';
        const finalDate = (this.questionnaireService.setCurrentYear()).toString() + '-03-31T00:00:00Z';
        this.questionnaireService.getSurveysCount(initialDate, finalDate)
            .subscribe(results => {
                this.surveyCount = results.content.length;
        });
    }

    ngOnInit() {

        this.router.events.subscribe((url: any) => {
            switch (url.url) {
                case '/questionnaire' : this.headerPrint = false; break;
                case '/login' : this.user = ''; break;
            }
        });

        this.subscription = this.userService.user$.subscribe((user) => {
            if (user) {
                this.logger.setDataDogUser({
                    name: user.displayName,
                    email: user.email,
                    id: user.id,
                })
                this.user = user;
                this.prescribingPharmacy = user.prescribingPharmacy;
                this.deliveringPharmacy = user.deliveringPharmacy;
                this.accountService.info().subscribe((options) => {
                    if (user.id === options.coopPharmacy || user.platform === "SUPERDRUG") {
                        this.thisIsCoop = true;
                    }
                    if(user.delivers === true){
                        this.pharmacyDelivers = true;
                    }
                });

                const scriptVolume = this.user.settings && this.user.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME ? this.user.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME : 0;
                this.surveyMin = this.questionnaireService.getMinSurveysbyAMSV(+scriptVolume);
                this.getSurveysCount();

                this.dataService.getData().subscribe(data => {
                    const count: any = data;
                    if (count > this.surveyCount) {
                        this.surveyCount = +count;
                    }
                });
            }
        });

        this.keepAliveService.init();
    }

    logout() {
        this.accountService.blacklistToken()
            .subscribe(response => {
                this.response = response;
                this.user = '';
                this.accountService.logout()
            }, (error: HttpErrorResponse) => {
                console.error(error);
            }
        );
    }

}
