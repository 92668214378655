<ng-container>
    <p>We are going to start with the PEG questionnaire which will help us understand how pain is affecting your daily life and overall well-being. Please rate the following questions using whole numbers, on a scale of 0-10, where 0 is no pain/interference and 10 is the worst pain/interference imaginable.</p>
    <form [formGroup]="pegGroup" autocomplete="off">
        <div *ngFor="let item of pegForm | keyvalue" class="modal-question">
            <span><b>{{item.value}}</b>&nbsp;<span style="color: red;">*</span></span>
            <div class="custom-prg-slider">
                <ngx-slider [options]="options" [formControlName]="item.key"></ngx-slider>
            </div>
        </div>
    </form>
    <div class="score-container">
        <span>Final score </span>
        <div class="score-label">
            {{average}}
        </div>
    </div>
</ng-container>