import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, lastValueFrom } from 'rxjs';
import { AUDIT_FORM } from 'src/assets/patientQuestionnaire';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.scss']
})
export class AuditFormComponent implements OnInit, OnChanges {
  @Input() orderItem:any;
  @Input() orderId:any;

  auditGroup: FormGroup;
  auditData: any;

  constructor(private clinicService: ClinicService) { }

  private async initializeAuditNotes(){
    if (!!this.orderItem) {
      const note = this.orderItem.orderNotes.find(note => note.type === 'AUDIT_FORM');
      this.auditData = !!note ? JSON.parse(note.questionAnswer) : {};
    }
  }

  private createAuditNotesForm() {
    const noteControls = {};
    const auditNotes = this.auditData;

    Object.keys(this.auditForm).forEach((key: string) => {
      noteControls[key] = new FormControl(
        auditNotes?.[key] || undefined,
        Validators.required
      );
    });

    this.auditGroup = new FormGroup(noteControls);
    this.onAuditNotesChange();
  }

  private onAuditNotesChange() {
    this.auditGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      const auditData = this.getAuditData();

      const orderPayload = this.clinicService.getOrderNotePayload(this.orderItem);
      
      let noteIndex = undefined;
      const note = this.orderItem.orderNotes.find((note, index) => {
        noteIndex = index;
        return note.type === 'GAD_FORM';
      })
      if (!!note) {
        orderPayload.orderNotes[noteIndex] = auditData;
      } else {
        orderPayload.orderNotes.push(auditData);
      }

      await lastValueFrom(this.clinicService.updateOrderNote(this.orderId, orderPayload));
    });
  }

  ngOnInit(): void {
    this.initializeAuditNotes();
    this.createAuditNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      this.initializeAuditNotes();
      this.createAuditNotesForm();
    }
  }

  getAuditData() {
    let formValues = this.auditGroup.value;
    let totalScore = 0;
    Object.keys(formValues).forEach((key: string) => {
      if (!!formValues[key]) {
        totalScore += parseInt(formValues[key]);
      }
    });

    formValues = {
      ...formValues,
      score: totalScore,
    };

    return {
      type: 'AUDIT_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  get auditForm() {
    return AUDIT_FORM;
  }

}
