import { Component, OnInit, OnDestroy } from '@angular/core';
import { OffersService } from './offers.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html'
})
export class OffersComponent implements OnInit, OnDestroy {

  offers: any;
  lastUpdated: any;
  updatedInterval: any;
  errorMessage: any;

  constructor(private offersService: OffersService) {}

  ngOnInit() {
    this.loadOffers();
    this.updatedInterval = setInterval(() => {
      this.loadOffers();
    }, 600000);
  }

  ngOnDestroy() {
    if (this.updatedInterval) {
      clearInterval(this.updatedInterval);
    }
  }

  loadOffers() {
    this.offersService.getOffers().subscribe(
      (response) => {
        this.offers = response;
        this.lastUpdated = new Date();
        this.offers.forEach((offer) => {
          offer.discountClass = this.getDiscountClass(offer.percentage);
        });
      },
      (error) => {
        this.errorMessage = 'Something went wrong';
      });
  }

  getDiscountClass(discount) {
    if (discount === 1) {
      return 'platinum-tier';
    } else if (discount > 0.7 && discount <= 0.9) {
      return 'gold-tier';
    } else if (discount > 0.4 && discount <= 0.7) {
      return 'silver-tier';
    } else if (discount > 0 && discount <= 0.4) {
      return 'bronze-tier';
    }
  }
}
