<div class="container">
  <h1>Prescriptions</h1>
  <p>
    Please find the orders for your pharmacy below
    <small class="date_updated" *ngIf="lastUpdated">Last updated = {{ lastUpdated | date: 'y MMM d' }} at {{ lastUpdated.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }}</small>
  </p>

  <ul class="nav nav-tabs">
    <li role="presentation" [ngClass]="{ active: orderType === 'pending' }" (click)="loadOrdersOnTabChange('pending')"><a>Pending</a></li>
    <li role="presentation" [ngClass]="{ active: orderType === 'completed' }" (click)="loadOrdersOnTabChange('completed')"><a>Completed</a></li>
    <li role="presentation" [ngClass]="{ active: orderType === 'all' }" (click)="loadOrdersOnTabChange('all')"><a>History</a></li>

    <li class="pull-right col-md-3">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Order ID, name, address"
          [(ngModel)]="searchQuery"
          (keypress)="isNumberOrLetter($event)"
          (keyup.enter)="search()"
        />
        <span class="input-group-btn">
          <button type="button" class="btn btn-default" (click)="search()"><i class="glyphicon glyphicon-search"></i></button>
        </span>
      </div>
    </li>
  </ul>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Patient</th>
        <th>Treatment</th>
        <th>Delivery Type</th>
        <th>Status <span class="caret"></span></th>
        <th width="20%" class="actions"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of orderItems">
        <td>{{ item.id }}</td>
        <td>{{ item.creationDate | date: 'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ item.patient.displayName }}</td>
        <td>{{ item.doctorProposal || item.treatment || item.name }}</td>
        <td>{{item.deliveryType}}</td>
        <td class="alert alert-{{item.status | orderState}}">{{ item.status | orderStatus }}</td>
        <td><app-order-actions [order]="item" [patientId]="item.patient.id" (orderChanged)="reload($event)" list="true"></app-order-actions></td>
      </tr>
    </tbody>
  </table>

  <div class="text-center mr-0 ml-0 pl-15">
    <ul class="pagination mt-0">
      <li class="page-item"><a class="page-link text-primary" [ngClass]="{ disabled: currentPage === 0 }" (click)="changePage(0)">First</a></li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage - 1 < 0 }" (click)="changePage(currentPage - 1)">Previous</a>
      </li>
      <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link text-primary disabled">...</a></li>
      <li *ngIf="currentPage !== 0" class="page-item hidden-xs">
        <a class="page-link text-primary" (click)="changePage(currentPage - 1)">{{ currentPage }}</a>
      </li>
      <li class="page-item">
        <a class="page-link text-primary pagination-active-background"
          ><strong>{{ currentPage + 1 }}</strong></a
        >
      </li>
      <li *ngIf="currentPage < lastPage" class="page-item hidden-xs">
        <a class="page-link text-primary" (click)="changePage(currentPage + 1)">{{ currentPage + 2 }}</a>
      </li>
      <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link text-primary disabled">...</a></li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage >= lastPage }" (click)="changePage(currentPage + 1)">Next</a>
      </li>
      <li class="page-item">
        <a class="page-link text-primary" [ngClass]="{ disabled: currentPage === lastPage }" (click)="changePage(lastPage)">Last</a>
      </li>
    </ul>
  </div>

  <div *ngIf="(orderItems | orderFilter: searchQuery).length === 0" class="alert alert-info" role="alert">No orders found</div>
</div>
