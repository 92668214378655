import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'consultation-success',
  templateUrl: './consultation-success.component.html',
})
export class ConsultationSuccess implements OnInit, OnDestroy {
  orderId: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orderId = params['orderId'];
    });
  }

  ngOnDestroy() {}
}
