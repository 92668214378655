import { Component, OnInit, Inject } from '@angular/core';
import { AccountService } from '../account/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OrderService } from '../orders/order.service';

@Component({
    selector: 'app-verify-prescription',
    templateUrl: './verify-prescription.component.html',
    styleUrls: ['./verify-prescription.component.scss']
})
export class VerifyPrescriptionComponent implements OnInit {

    public errorMessage: string;
    public returnUrl: string;
    public model: any = {};
    public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    public years = new Array(82).fill(new Date().getFullYear() - 18).map((v, i) => v - i)
    public days = new Array(31).fill(1).map((v, i) => v + i)
    public isLoggedIn;
    public authMode: "ID" | "GPHC" = "ID"

    constructor(
        private accountService: AccountService,
        private orderService: OrderService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject('global_options') private options: any
    ) {
        activatedRoute.params.subscribe((queryParams) => {
            this.returnUrl = queryParams['returnUrl'];
        });
    }

    ngOnInit() {
      this.isLoggedIn = this.accountService.loggedIn();
      this.accountService.loginType = 'PRESCRIPTION';
      this.orderService.isVerification = null;
      if (this.isLoggedIn) {
        const decodedToken = new JwtHelperService().decodeToken(localStorage.getItem('pharmacy.id_token'));
        this.model.storeId = decodedToken.gpsPayload.wrapper.pharmacyId;
      }
    }

    onLogin() {
        this.errorMessage = '';
        this.accountService.prescriptionLogin(
          this.model.authCode,
          this.model.patientSurname,
          `${this.model.patientDobYear}-${this.model.patientDobMonth.padStart(2, '0')}-${this.model.patientDobDay.padStart(2, '0')}`,
          this.model.storeId,
          this.model.storePin)
            .subscribe(
              (result) => {
                // retrieve token, but do not authenticate
                const token = result.token;
                (<any>window).localToken = token;
                this.orderService.isVerification = true;
                // const orderItemId = result.prescription?.orderItem?.id;
                const orderItemId = result.orderItemId;
                this.router.navigateByUrl(`/orders/${orderItemId}`);
              },
              (error) => {
                // if (error.status === 401) {
                //   this.errorMessage = 'Invalid Store ID or PIN';
                // }
                // else if (error.status === 403) {
                //   this.errorMessage = 'You do not have access to this prescription';
                // }
                // else {
                //   this.errorMessage = error.error.message || 'An error occurred';
                // }
                this.errorMessage = error.error?.message || 'An error occurred';
                document.body.scrollTop = 0;
              });
    }

}
